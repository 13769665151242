import {Injectable} from '@angular/core';

import {
    APPLICATION_SET,
    GET_COMPETITION_PHOTOS_FROM_MEMBER
} from './constants';

import {ApplicationService} from './service';

@Injectable()
export class ApplicationActions {
    constructor(private application: ApplicationService) {
    }

    set (data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: APPLICATION_SET
            });
        };
    }

    getCompetitionsPhotosFromMember(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_COMPETITION_PHOTOS_FROM_MEMBER,
                isAsync: true,
                promise: this.application.getCompetitionsPhotosFromMember(data)
            });
        };
    }
}
