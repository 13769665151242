import {Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {PhotosGalleryInterface} from '../../store/interfaces/photos.interface';

import {NgRedux, select} from '@angular-redux/store';
import {ApplicationActions} from '../../store/application';
import {PhotosActions, photos, viewType} from '../../store/photos';

import {Router} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {DEFAULT_IMAGE} from '../../config/constants';

@Component({
    selector: 'thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss'],
    // encapsulation: ViewEncapsulation.Native
})
export class ThumbnailComponent implements OnInit, OnDestroy {
    @select(photos) photos: Observable<any>;
    // @select(viewType) viewTypes: Observable<any>;

    @Input() photo: PhotosGalleryInterface;
    viewType = 'thumbnails';
    subscription: any;
    defaultImage = DEFAULT_IMAGE;
    @Input() ForceViewType = false;

    constructor(public redux: NgRedux<any>,
                public photosActions: PhotosActions,
                private applicationActions: ApplicationActions,
                private router: Router,
                public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (!this.ForceViewType) {
            this.subscription = this.photos.subscribe(item => {
                if (!item.error && item.photos) {
                    this.viewType = item.viewType;
                    this.cd.markForCheck();
                }
            });
        } else {
            this.viewType = '900';
        }
    }

    typeChecker(thumbnails: string) {
        if (this.viewType === 'list') {
            return thumbnails.replace('thumbnails', '900');
        }
        this.cd.markForCheck();
        return thumbnails;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    makeImage(viewType, thumbnail) {
        return 'https://www.kujaja.com/photos/' + this.viewType + '/' + thumbnail + '.jpg';
    }

    goToImage(photo) {
        this.redux.dispatch(<any>this.applicationActions.set({shownPhoto: photo}));
        if (photo.image_story && photo.image_story === 1 || photo.image_story === 2) {
            const url = ((photo.hasOwnProperty('story_link') && photo.story_link  !== '')  ?
                '/' + photo.story_link :
                (photo.hasOwnProperty('isn_url') ?
                    '/' + photo.isn_url : ''));
            this.router.navigate(['/en/profile/' + photo.user_login + '/serie/' + url ]);
        } else {
            this.router.navigate(['/en/photo/', photo.image_id]);
        }
    }

    noGo() {
        return false;
    }
}
