import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user} from '../../../store/user';
import {PhotosActions} from '../../../store/photos';

import {application, ApplicationActions} from '../../../store/application';
import {photos} from '../../../store/photos';
import {interactions, InterActions} from '../../../store/inter';

@Component({
    selector: 'nominateButton',
    templateUrl: './nominate.component.html',
    styleUrls: ['./nominate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominateButtonComponent implements OnInit, OnDestroy, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() imageId: number;
    @Input() overLay: false;

    hasNominated = false;

    subscription: any;
    subscriptionVotes: any;
    viewType: string;

    constructor(public redux: NgRedux<any>,
                public photoActions: PhotosActions,
                public interActions: InterActions,
                public app: ApplicationActions) {
    }

    ngOnInit() {
        this.checkVotes();
    }

    ngOnChanges() {
        this.checkVotes();
    }

    checkVotes() {
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            this.subscriptionVotes = this.interactions.subscribe(item => {
                this.viewType = item.viewType;
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionVotes) {
            this.subscriptionVotes.unsubscribe();
        }
    }

    nominate($event) {
        this.subscription = this.user.subscribe(use => {
            // console.log(use.profile);
            if (use.profile !== '') {
                this.redux.dispatch(<any>this.app.set({overlay: false}));
                this.redux.dispatch(<any>this.interActions.nominate({image_id: this.imageId}));
                // this.hasNominated = true;
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }
}
