
import {application} from '../initials';

import {
    APPLICATION_SET,
    GET_COMPETITION_PHOTOS_FROM_MEMBER,
    GET_COMPETITION_PHOTOS_FROM_MEMBER_ERROR,
    GET_COMPETITION_PHOTOS_FROM_MEMBER_SUCCESS
} from './constants';

export default (state = application, action: any) => {
    switch (action.type) {
        case APPLICATION_SET:
            return Object.assign({}, state, {
                ...action.data
            });

        case GET_COMPETITION_PHOTOS_FROM_MEMBER:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                competitionphotos: []
            });

        case GET_COMPETITION_PHOTOS_FROM_MEMBER_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                competitionphotos: []
            });

        case GET_COMPETITION_PHOTOS_FROM_MEMBER_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        default:
            return state;
    }


};
