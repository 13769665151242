import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';

import {
    URL_COMPETITION,
    URLGETCOMPETITIONDATA,
    URL_GET_PHOTOBOOKS,
    URL_GET_COMPETITION_DISCUSSION,
    URL_COMPETITION_PHOTOGRAPHERS
} from '../../config/constants';


@Injectable()
export class CompetitionsService {
    constructor(private base: BaseService) {
    }

    getCompetition(data) {
        return this.base.getHttp(URL_COMPETITION, (data ? '/' + data : ''), data);
    }

    getPhotographers(data) {
        return this.base.getHttp(URL_COMPETITION_PHOTOGRAPHERS, (data ? data : ''), data);
    }

    getCompetitions(data) {
        return this.base.getHttp(URLGETCOMPETITIONDATA, '', data);
    }

    getDiscussion(data) {
        return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION, data, data);
    }

    getMoreDiscussion(data) {
        return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION, data, data);
    }

    getAllPhotobooks() {
        return this.base.getHttp(URL_GET_PHOTOBOOKS, '', '');
    }
}
