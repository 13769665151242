import {interviews} from '../initials';

import {
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEW_QUESTIONS_ERROR,
    GET_INTERVIEW_QUESTIONS_SUCCESS,
    SAVE_INTERVIEW_QUESTIONS,
    SAVE_INTERVIEW_QUESTIONS_ERROR,
    SAVE_INTERVIEW_QUESTIONS_SUCCESS,
    GET_INTERVIEWS,
    GET_INTERVIEWS_ERROR,
    GET_INTERVIEWS_SUCCESS,
    GET_ONE_PHOTOGRAPHER,
    GET_ONE_PHOTOGRAPHER_SUCCESS,
    GET_ONE_PHOTOGRAPHER_ERROR,
    GET_INTERVIEW,
    GET_INTERVIEW_SUCCESS,
    GET_INTERVIEW_ERROR,
} from './constants';


export default (state = interviews, action: any) => {

    switch (action.type) {
        case GET_ONE_PHOTOGRAPHER:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photographer: [],
                photos: []
            });

        case GET_ONE_PHOTOGRAPHER_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case GET_ONE_PHOTOGRAPHER_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                photographer: [],
                photos: []
            });

        case GET_INTERVIEWS:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photographers: []
            });

        case GET_INTERVIEWS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case GET_INTERVIEWS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case GET_INTERVIEW_QUESTIONS:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                questions: null,
                photos: null
            });

        case GET_INTERVIEW_QUESTIONS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                questions: null,
                photos: null
            });

        case GET_INTERVIEW_QUESTIONS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case SAVE_INTERVIEW_QUESTIONS:
            return Object.assign({}, state, {
                notification: '',
                loading: true
            });

        case SAVE_INTERVIEW_QUESTIONS_ERROR:
            return Object.assign({}, state, {
                notification: 'error',
                error: true,
                loading: false
            });

        case SAVE_INTERVIEW_QUESTIONS_SUCCESS:
            return Object.assign({}, state, {
                notification: 'success',
                error: false,
                loading: false,
                ...action.payload
            });

        case GET_INTERVIEW:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                questions: null,
                photos: null
            });

        case GET_INTERVIEW_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                questions: null,
                photos: null
            });

        case GET_INTERVIEW_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });
        default:
            return state;
    }
};
