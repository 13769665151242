import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  Resolve,
  Router
} from '@angular/router';

import { NgRedux } from '@angular-redux/store';
import { ensureState } from 'redux-optimistic-ui';

import { ApplicationActions } from '../../store/application';
import { CompetitionsActions } from '../../store/competitions';
import { PhotographersActions } from '../../store/photographers';
import { PhotosActions } from '../../store/photos';
import { UserActions} from '../../store/user';

@Injectable()
export class GenericResolve implements Resolve<any> {
  constructor(
    private redux: NgRedux<any>,
    private app: ApplicationActions,
    private competitions: CompetitionsActions,
    private photographers: PhotographersActions,
    private photo: PhotosActions,
    private user: UserActions,
    private router: Router
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot) {
    const { data, params } = snapshot;
    const { action, container, keyPath, validKey, options, param } = data;

    return new Promise((done, fail) => {
      this.redux.dispatch(<any>this.app.set({ loading: true }));
      this.redux.dispatch(
        <any>this[container][action](options || params[param])
      );

      const source =
        this.redux.select(s => ensureState(s)[container]);

      const subscription = source.subscribe(s => {
        if (subscription) {
          this.redux.dispatch(<any>this.app.set({ loading: false }));
          subscription.unsubscribe();
          if (typeof validKey === 'undefined' || s.get(validKey)) {
            done(s);
          } else {
            this.router.navigate(['/404']);
            fail(null);
          }
        }
      });
    })
    .then(result => result)
    .catch(error => error);
  }
}
