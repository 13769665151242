import {
    ChangeDetectionStrategy,
    ChangeDetectorRef, Component, OnInit, ViewChild,
    Input, OnChanges
} from '@angular/core';


@Component({
    selector: 'profile-teaser',
    templateUrl: './profile.teaser.component.html',
    styleUrls: ['./profile.teaser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTeaserComponent implements OnInit, OnChanges {
    @ViewChild('headerWrapper') headerWrapper;
    @ViewChild('backgroundImage') backgroundImage;
    @Input() teaserPhoto: string;
    @Input() profileTitle: string;
    @Input() userLogin: string;
    @Input() profileSubheader: string;


    startPreLoading = false;

    constructor(public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.getTeaserImage();
    }

    ngOnChanges() {
        this.getTeaserImage();
    }

    getTeaserImage() {
        if (this.teaserPhoto &&
            this.teaserPhoto !== 'dfb2e2306764588c3ed92879aa3aed07/43d72e201c02b493bb7d8812ccc4e740' &&
            this.teaserPhoto !== 'undefined' &&
            this.teaserPhoto !== undefined) {
            const imageName = '/photos/full/' + this.teaserPhoto + '.jpg';
            this.backgroundImage.nativeElement.src = imageName;
        } else {
            this.backgroundImage.nativeElement.src = '';
        }
        setTimeout(() => {
            this.cd.markForCheck();
        }, 30);
    }

    noGo() {
        return false;
    }

    onResize(event) {
        this.setSize(event.target.innerHeight);
    }

    initiateSets() {
        this.startPreLoading = true;
        if (this.backgroundImage.nativeElement.height > this.backgroundImage.nativeElement.width) {
            this.backgroundImage.nativeElement.style.width = 'auto';
            this.backgroundImage.nativeElement.style.height = 'auto';
        } else {
            this.backgroundImage.nativeElement.style.width = '100%';
        }
    }

    setSize(height) {
        if (parseInt(this.headerWrapper.nativeElement.style.offSetHeight, 10) < 500) {
            this.headerWrapper.nativeElement.style.visibility = 'hidden';
        } else {
            this.headerWrapper.nativeElement.style.visibility = 'visible';
        }
        setTimeout(() => {
            this.cd.markForCheck();
        }, 30);
    }
}
