import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnDestroy,
    ViewChild,
    HostListener,
    NgModule, OnChanges
} from '@angular/core';
import {LocationStrategy} from '@angular/common';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';

import {LocationService} from '../../services/location.service';
import {application, ApplicationActions} from '../../store/application';
import {user} from '../../store/user';
import {Subscription} from "rxjs/Rx";
import {PhotosActions} from '../../store/photos';

@Component({
    selector: 'overlay',
    styleUrls: ['./overlay.scss'],
    templateUrl: 'overlay.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent implements OnInit, OnChanges, OnDestroy {
    @select(application) application: Observable<any>;
    @ViewChild('top') top;
    @ViewChild('overlay') overlay;
    @ViewChild('shownImage') shownImage;

    @Input() photoVisible: number;
    @Input() photos: any;
    @Input() showOverlay = false;

    @Output() visibilityOverlay = new EventEmitter<any>();
    @Output() photoAction = new EventEmitter<any>();

    @select(user) user: Observable<any>;

    applicationSubscribtion: Subscription;
    description = '';
    photoUrl = 'https://www.kujaja.com/';
    photoAway = false;
    photosLoaded: any = [];
    swipeCoord?: [number, number];
    swipeTime?: number;
    photoTitle: string;
    photographer: string;
    imageId: number;
    photoBackgroundWhite: boolean;
    profileUrl: string;
    mouseIsMoving: boolean;
    mouseIsMovingInterval: any;
    loading = true;
    body = window.document.getElementsByTagName('html');
    isLoggedIn = true;
    userLogin: any;
    total = 0;
    allowVoting: boolean = false;

    constructor(public titleService: Title,
                private meta: Meta,
                public locationService: LocationService,
                public applicationActions: ApplicationActions,
                public redux: NgRedux<any>,
                private photosActions: PhotosActions,
                public cd: ChangeDetectorRef,
                public route: ActivatedRoute,
                public router: Router,
                location: LocationStrategy) {

        location.onPopState(() => {
            // alert(window.location);
        });
    }

    ngOnInit() {
        const innerWidth = window.innerWidth;
        this.mouseIsMoving = innerWidth < 1025;
        this.mouseIsMovingInterval = true;

    }

    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        this.mouseIsMoving = true;
    }

    noGo() {
        return false;
    }

    resetImage(): void {
        this.loading = true;
        this.shownImage.nativeElement.src = 'assets/img/empty.png';
        this.shownImage.nativeElement.style.width = '90vw';
        this.shownImage.nativeElement.style.height = '80vh';
    }

    photoActionEmit($event): void {
        this.photoAction.emit($event);
    }

    close(): void {
        this.visibilityOverlay.emit(false);

        setTimeout(() => {
            this.body[0].style.overflowY = 'visible';
            this.resetImage();
            this.router.navigate([this.locationService.getPreviuosPage()]);
        }, 0);

        this.cd.markForCheck();
    }

    ngOnChanges() {
        this.scrollToTop();
        this.resetImage();
        if (this.photos && this.photos.length && this.showOverlay) {
            this.body[0].style.overflowY = 'visible';

            const photos = this.photos;
            const allPhotos = photos.length;

            this.total = this.photos.length;

            if (allPhotos > 0) {
                this.showPhoto();
            }
        }
    }

    photoLoaded() {
        this.loading = false;
        this.shownImage.nativeElement.style.width = 'auto';
        this.shownImage.nativeElement.style.height = 'auto';
        this.photoAway = false;
        this.cd.markForCheck();
    }

    next() {
        this.scrollToTop();
        if (this.photoVisible >= (this.photos.length - 1)) {
            this.photoVisible = 0;
        } else {
            this.photoVisible++;
        }
        this.redux.dispatch(<any>this.applicationActions.set({shownPhoto: this.photos[this.photoVisible]}));
        this.router.navigate(['/en/photo/' + this.photos[this.photoVisible].image_id]);
        this.cd.markForCheck();
        this.showPhoto();
    }

    scrollToTop() {
        this.overlay.nativeElement.scrollTop = 0;
    }

    previous() {
        this.scrollToTop();
        if (this.photoVisible <= 0) {
            this.photoVisible = this.photos.length - 1;
        } else {
            this.photoVisible--;
        }
        this.redux.dispatch(<any>this.applicationActions.set({shownPhoto: this.photos[this.photoVisible]}));
        this.router.navigate(['/en/photo/' + this.photos[this.photoVisible].image_id]);
        this.cd.markForCheck();
        this.showPhoto();
    }

    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 // Short enough
                && Math.abs(direction[1]) < Math.abs(direction[0]) // Horizontal enough
                && Math.abs(direction[0]) > 30) {  // Long enough
                const swipe = direction[0] < 0 ? this.next() : this.previous();
                // Do whatever you want with swipe
            }
        }
    }

    ngOnDestroy() {
        if (this.applicationSubscribtion) {
            this.applicationSubscribtion.unsubscribe();
        }
    }

    metaTags(photo) {
        const {application} = ensureState(this.redux.getState());

        const photoTitle: string = (photo.image_title !== '' ? photo.image_title + ' - ' : '') + photo.user_firstname + ' ' + photo.user_lastname + ' - ' + application.config.siteNameClean;
        // TODO const description = photo.image_text.replace(/(?:\r\n|\r|\n)/g, '<br />');

        this.titleService.setTitle(photoTitle);

        this.meta.addTag({name: 'fb:app_id', content: application.config.facebookAppId});

        this.meta.addTag({name: 'og:image', content: application.config.facebookAppId});
        this.meta.addTag({
            name: 'og:url',
            content: 'https://' + application.config.siteName + '/photos/900/' + photo.thumbnail + '/' + photo.image_id + '.jpg'
        });
        this.meta.addTag({name: 'og:title', content: photoTitle});
        // TODO this.meta.addTag({ name: 'og:description', content: description });

        this.meta.addTag({name: 'twitter:card', content: 'photo'});
        this.meta.addTag({name: 'twitter:site', content: '@site_kujaja'});
        this.meta.addTag({name: 'twitter:creator', content: '@creator_kujaja'});
        this.meta.addTag({
            name: 'twitter:url',
            content: 'https://' + application.config.siteName + '/photos/900/' + photo.thumbnail + '/' + photo.image_id + '.jpg'
        });
        this.meta.addTag({name: 'twitter:title', content: photoTitle});
        // TODO this.meta.addTag({ name: 'twitter:description', content: description });
        this.meta.addTag({name: 'twitter:image', content: ''});
        this.meta.addTag({name: 'twitter:image:width', content: '900'});
        this.meta.addTag({name: 'twitter:domain', content: 'https://twitter.com/kujaja'});

    }

    showPhoto() {
        this.loading = true;
        const id = this.photoVisible;
        const photoName = this.photos[id].full;

        this.profileUrl = this.photos[id].user_login;

        this.metaTags(this.photos[id]);

        this.photoAway = true;

        if (this.photosLoaded.indexOf(photoName) > -1) {
            this.shownImage.nativeElement.src = '/assets/img/empty.gif';
            setTimeout(() => {
                this.shownImage.nativeElement.src =
                    this.photoUrl + 'photos/full/' + this.photos[id].full + '.jpg';

                this.photoAway = false;
                this.cd.markForCheck();
            }, 50);
        } else {
            this.shownImage.nativeElement.src =
                this.photoUrl + 'photos/full/' + this.photos[id].full + '.jpg';

            this.photosLoaded.push(photoName);
        }

        this.photographer = this.photos[id].user_firstname + ' ' + this.photos[id].user_lastname;
        if (this.photographer.trim() === '') {
            this.photographer = this.photos[id].user_login;
        }
        this.photoTitle = this.photos[id].image_title === 'Untitled' ? '&nbsp;' : this.photos[id].image_title + '&nbsp;';
        this.imageId = this.photos[id].image_id;
        this.description = (this.photos[id].text ? this.photos[id].text : '');
        this.photoBackgroundWhite = (this.photos[id].image_background !== '#000000');

        this.redux.dispatch(<any>this.photosActions.countPhotoView({image_id: this.photos[id].image_id}));
        this.locationService.trackPage('/en/photo/' + this.photos[id].image_id);

        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            const id = this.photoVisible;
            if (user.profile === this.photos[id].user_login) {
                // this.isLoggedIn = false;
                this.allowVoting = false;
            } else {
                this.allowVoting = true;
                this.userLogin = this.photos[id].user_login;
            }
        }

        setTimeout(() => {
            this.cd.markForCheck();
        }, 30);
    }
}
