import {Component, ChangeDetectorRef, OnInit, OnDestroy} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {FormGroup, FormBuilder} from '@angular/forms';
import {AsyncLocalStorage} from 'angular-async-local-storage';
import {photos} from '../../store/photos';
import {InterActions} from '../../store/inter';

import {user} from '../../store/user';
import {application} from '../../store/application';

import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs/Rx";

@Component({
    selector: 'confirm-account',
    templateUrl: './confirmaccount.component.html',
    styleUrls: ['./confirmaccount.component.scss']
})

export class ConfirmAccountComponent implements OnInit, OnDestroy {
    @select(user) user: Observable<any>;
    @select(application) application: Observable<any>;
    @select(photos) photos: Observable<any>;
    error: boolean;
    subscription: Subscription;

    applicationSubscription: Subscription;
    form: FormGroup;
    formResend: FormGroup;
    loading: false;

    code: string = '';
    email: string = '';
    codeId: string = '';
    accountIsConfirmed: boolean = false;
    resendDone: boolean = false;

    constructor(public userActions: UserActions,
                public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                public formBuilder: FormBuilder,
                public interActions: InterActions,
                public route: ActivatedRoute,
                public router: Router,
                protected localStorage: AsyncLocalStorage,
                public redux: NgRedux<any>) {

        this.form = formBuilder.group({
            code: ['']
        });
        this.formResend = formBuilder.group({
            email: ['']
        });
    }

    ngOnInit() {
        this.subscription = this.user.subscribe(use => {
            this.error = use.error;
            this.loading = use.loading;

            if (use.recoveryId) {
                this.code = use.recoveryId;
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            }

            if (use.emailToBeConfirmed) {
                this.accountIsConfirmed = true;
                this.email = use.emailToBeConfirmed;
                this.formResend.setValue({email: this.email});
                this.codeId = use.user_temp_id;
            }
        });

        // this.applicationSubscription = this.application.subscribe(app => {
        //     this.email = app.emailToBeConfirmed;
        // });

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }

    submit(form) {
        console.log(form);
        const confirmActivationCode = Object.assign({}, {tokenTime: Date.now(), code: form.value.code, codeId: this.codeId});

        if (form.status !== 'INVALID') {
            this.redux.dispatch(<any>this.userActions.confirmActivationCode(confirmActivationCode));
        } else {
            this.error = true;
        }
    }

    resendEmail(form) {
        if (!form.value.email) {
            return;
        }

        console.log('form', form);
        const resendConfirmationEmail = Object.assign({},  {tokenTime: Date.now(), code: this.code, codeId: this.codeId, email: form.value.email});
        if (form.status !== 'INVALID' && !this.error) {
            this.redux.dispatch(<any>this.userActions.resendConfirmationEmail(resendConfirmationEmail));
            this.resendDone = true;
        } else {
            this.error = true;
        }
    }

    signUp(form) {

    }
}
