import {Component, OnInit, ChangeDetectorRef, OnDestroy, OnChanges} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {application} from '../../../store/application';
import {user} from '../../../store/user';


@Component({
    selector: 'logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    userSubscription: any;
    applicationSubscribtion: any;
    path = '/';
    siteName = '';
    siteShortName = '';

    constructor(public redux: NgRedux<any>,
                public cd: ChangeDetectorRef,) {
    }

    ngOnInit() {
        const {user} = ensureState(this.redux.getState());

        this.userSubscription = this.user.subscribe(item => {
                if (item.isLoggedIn) {
                    this.path = '/';
                    this.cd.markForCheck();
                } else {
                    this.path = '/';
                    this.cd.markForCheck();
                }
            }
        );

        this.applicationSubscribtion = this.application.subscribe(app => {
            if (app.config && app.config) {
                this.siteName = app.config.siteName;
                this.siteShortName = app.config.siteShortName;
            }
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.applicationSubscribtion) {
            this.applicationSubscribtion.unsubscribe();
        }

    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        this.cd.markForCheck();
    }

}
