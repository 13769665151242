import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user} from '../../../store/user';
import {PhotosActions} from '../../../store/photos';

import {application, ApplicationActions} from '../../../store/application';
import {photos} from '../../../store/photos';
import {interactions, InterActions} from '../../../store/inter';

@Component({
    selector: 'selectedPhotographerButton',
    templateUrl: './selected.photographer.component.html',
    styleUrls: ['./selected.photographer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedPhotographerButtonComponent implements OnInit, OnDestroy, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() imageId: number;
    @Input() userLogin: string;

    hasselectedphotographerd = false;

    subscription: any;
    showLoginAs = false;
    hasDeleted = false;
    setTop = false;


    constructor(public redux: NgRedux<any>,
                public cd: ChangeDetectorRef,
                public photoActions: PhotosActions,
                public interActions: InterActions,
                public app: ApplicationActions) {

    }

    ngOnInit() {
        this.resetButton();

        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            if (user.roles) {
                user.roles.find(role => {
                    if (role.role === 1 || role.role === 2) {
                        this.showLoginAs = true;
                    }
                });
                this.cd.markForCheck();
            }
        }
    }

    ngOnChanges() {
        this.resetButton();
    }

    resetButton() {
        this.hasselectedphotographerd = false;
        this.cd.markForCheck();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    selectedphotographer() {

        this.subscription = this.user.subscribe(use => {
            if (use.profile !== '') {
                this.redux.dispatch(<any>this.app.set({overlay: false}));
                this.redux.dispatch(<any>this.interActions.selectedphotographer({image_id: this.imageId}));
                this.hasselectedphotographerd = true;
                this.cd.markForCheck();
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }

    deselectPhoto() {
        this.subscription = this.user.subscribe(use => {
            if (use.profile !== '') {
                this.redux.dispatch(<any>this.app.set({overlay: false}));
                this.redux.dispatch(<any>this.interActions.deselectPhoto({image_id: this.imageId}));
                this.hasDeleted = true;
                this.cd.markForCheck();
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }

    setFirstImage() {
        this.subscription = this.user.subscribe(use => {
            if (use.profile !== '') {
                this.redux.dispatch(<any>this.app.set({overlay: false}));
                this.redux.dispatch(<any>this.interActions.setFirstImage({image_id: this.imageId}));
                this.setTop = true;
                this.cd.markForCheck();
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }
}
