import {Injectable} from '@angular/core';
import {PlatformLocation} from '@angular/common';
import {DomainInterface} from "./domain.interface";

@Injectable()
export class DomainService {

    public siteShortName: string = 'WSP';
    public siteName: string = '';
    public siteDescription: string = '';
    public siteNameClean: string = '';
    public siteNameSlogan1: string = '';
    public siteNameSlogan2: string = '';

    public facebookAppId: string = '';
    public googleApiKeyMaps: string = '';
    public googleApiKeyLogin: string = '';
    public googleAnalyticsId: string = '';
    public messageIcon: string = '';
    public url: string = '';
    public favicon: string = '';
    public image: string = '';
    public siteKeyWords: string = '';
    public urlBackend: string = '';
    public urlApi: string = '';

    constructor(public platformLocation: PlatformLocation) {
        // console.log((platformLocation as any).location.hostname);

        switch ((platformLocation as any).location.hostname) {
            case 'www.kujaja.com':
            case 'kujaja.com':
                this.url = 'https://www.kujaja.com';
                this.urlApi = 'https://api.kujaja.com';
                this.urlBackend = 'https://backend.kujaja.com';
                this.siteShortName = 'KJ';
                this.siteName = 'Kujaja';
                this.siteNameClean = 'Kujaja';
                this.siteDescription = 'Our theme, Our book, Our Aid';
                this.siteKeyWords = 'photography,photography,Digital Photography,Corporate Photography,fine art photography,photographers,street photography,book,photobook,making a photobook together,united photographers,community,photos with youtube,youtube emded,photocommunity,foto,fotos, foto community';
                this.siteNameSlogan1 = 'We are a growing group of photographers who joined creative forces to realise photography projects.';
                this.siteNameSlogan2 = 'Stay safe and stay healthy'; //We make photobooks, ebooks, organise exhibitions, run competitions, blog and share our works and experiences.';

                this.facebookAppId = '498054613551387';
                this.googleApiKeyMaps = 'AIzaSyDYERlMar94noaMz9gzTTxivTxi2mnwuqA ';
                this.googleApiKeyLogin = '202246381252-4a657ocmoftgu51g2p2khnm1qaj57vc4.apps.googleusercontent.com';
                this.googleAnalyticsId = 'UA-6985324-23';

                this.messageIcon = 'kujajaMessageIcon.jpg';
                this.favicon = 'favicon.ico';
                this.image = 'kujaja_logo.jpg';
                break;
            case 'www.world-street.photography':
            case 'world-street.photography':
            case 'localhost':
                this.url = 'https://world-street.photography';
                this.urlApi = 'https://api.world-street.photography';
                this.urlBackend = 'https://backend.world-street.photography';
                this.siteShortName = 'W-S.P';
                this.siteName = 'World-Street.Photography';
                this.siteNameClean = 'World Street Photography';
                this.siteDescription = 'World Street Photography is an independent organization, we promote and encourage street photographers from all around the world. Here you can join the world street photography awards, get published and exhibited. Every body can join us, this is not a closed collective or about a single street photographer. Our profits we donate to charity organizations.';
                this.siteNameSlogan1 = 'We are a growing group of photographers who joined creative forces to realise photography projects.';
                this.siteNameSlogan2 = 'Stay safe and stay healthy'; //We make photobooks, ebooks, organise exhibitions, run competitions, blog and share our works and experiences.';
                this.siteKeyWords = 'streetphotography, street photography, street photographer,streetphotographer, street photography blog';
                this.facebookAppId = '240663442788105';
                this.googleApiKeyMaps = 'AIzaSyDe3E_R2u30mvIMuNfkwNfXi3UM6YaSof4';
                this.googleApiKeyLogin = '202246381252-4a657ocmoftgu51g2p2khnm1qaj57vc4.apps.googleusercontent.com';
                this.googleAnalyticsId = 'UA-6985324-32';
                this.messageIcon = 'wspMessageIcon.jpg';
                this.favicon = 'wsp_favicon.ico';
                this.image = 'wsp_logo.jpg';
                break;
            case 'www.world-landcsape.photography':
            case 'world-landcsape.photography':
                this.url = 'https://world-landcsape.photography';
                this.urlApi = 'https://api.world-landcsape.photography';
                this.urlBackend = 'https://backend.world-landcsape.photography';
                this.siteShortName = 'W-L.P';
                this.siteName = 'World-Landscape.Photography';
                this.siteNameClean = 'World Landscape Photography';
                this.siteKeyWords = 'landscapephotography,landscape photo, landscape photos,landscape photography,landscape contest,competition,landscape photography competition,landscape photo book,photobook,photo book,books,blog,photography blog, interviews,photographer interview,interviews';
                this.siteDescription = 'World landscape Photography is an independent organization, we promote and encourage landscape photographers from all around the world. ';
                this.siteNameSlogan1 = 'We are a growing group of photographers who joined creative forces to realise photography projects.';
                this.siteNameSlogan2 = 'Stay safe and stay healthy'; //We make photobooks, ebooks, organise exhibitions, run competitions, blog and share our works and experiences.';
                this.facebookAppId = '835752826483494';
                this.googleApiKeyMaps = 'AIzaSyCWYL1PabAqFnLoh7ya3lep8SiIHZsDlL0 ';
                this.googleApiKeyLogin = '260395168155-66isld3qhk67sonkd2b1qkqdp7njb7d2.apps.googleusercontent.com';
                this.googleAnalyticsId = 'UA-6985324-35';
                this.messageIcon = 'kujajaMessageIcon.jpg';
                this.favicon = 'wsp_favicon.ico';
                this.image = 'wsp_logo.jpg';

                break;
            default:
                this.url = 'https://world-street.photography';
                this.urlApi = 'https://api.world-street.photography';
                this.urlBackend = 'https://backend.world-street.photography';
                this.siteShortName = 'W-S.P';
                this.siteName = 'World-Street.Photography';
                this.siteNameClean = 'World Street Photography';
                this.siteKeyWords = 'streetphotography, street photography,street photographer,streetphotographer, street photography blog';
                this.siteDescription = 'World Street Photography is an independent organization, we promote and encourage street photographers from all around the world. Here you can join the world street photography awards, get published and exhibited. Every body can join us, this is not a closed collective or about a single street photographer. Our profits we donate to charity organizations.';
                this.siteNameSlogan1 = 'We are a growing group of photographers who joined creative forces to realise photography projects.';
                this.siteNameSlogan2 = 'Stay safe and stay healthy'; //We make photobooks, ebooks, organise exhibitions, run competitions, blog and share our works and experiences.';
                this.facebookAppId = '240663442788105';
                this.googleApiKeyMaps = 'AIzaSyDe3E_R2u30mvIMuNfkwNfXi3UM6YaSof4';
                this.googleApiKeyLogin = '202246381252-4a657ocmoftgu51g2p2khnm1qaj57vc4.apps.googleusercontent.com';
                this.googleAnalyticsId = 'UA-6985324-32';
                this.messageIcon = 'wspMessageIcon.jpg';
                this.favicon = 'wsp_favicon.ico';
                this.image = 'wsp_logo.jpg';
                break;
        }
    }

    getConfig(): DomainInterface {
        return {
            siteShortName: this.siteShortName,
            siteName: this.siteName,
            siteNameClean: this.siteNameClean,
            siteDescription: this.siteDescription,
            siteKeyWords: this.siteKeyWords,
            facebookAppId: this.facebookAppId,
            siteNameSlogan1: this.siteNameSlogan1,
            siteNameSlogan2: this.siteNameSlogan2,
            googleApiKeyMaps: this.googleApiKeyMaps,
            googleApiKeyLogin: this.googleApiKeyLogin,
            googleAnalyticsId: this.googleAnalyticsId,
            messageIcon: this.messageIcon,
            favicon: this.favicon,
            image: this.image,
            url: this.url,
            urlApi: this.urlApi,
            urlBackend: this.urlBackend
        } as DomainInterface;
    }
}

/*
console.log('document.location.hostname', document.location.hostname);

export const
*/