import {flow} from '../initials';

import {
    FLOW_GET,
    FLOW_GET_ERROR,
    FLOW_GET_SUCCESS,


} from './constants';

export default (state = flow, action: any) => {
    switch (action.type) {

        case FLOW_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                competition: false
            });

        case FLOW_GET_SUCCESS:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });
        case FLOW_GET_ERROR:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        default:
            return state;
    }
};
