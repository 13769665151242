export const URLGETDATA = 'https://api.world-street.photography/en/api/';
export const URLPHOTOSGALLERY = URLGETDATA + 'photos/en/photos/today';
export const URLPHOTOS = URLGETDATA + 'photos';
export const URLPROLIE = URLGETDATA + 'profile';

export const URL_PHOTOS = URLGETDATA + 'photos';
export const URL_PHOTOS_PROFILE = URLGETDATA + 'profile';
export const URL_PROFILE = URLGETDATA + 'profile';
export const URL_PROFILE_LIKE = URLGETDATA + 'profilelike';
export const URL_PROFILE_FAVOURITES = URLGETDATA + 'favourites';
export const URL_PROFILE_FOLLOWING = URLGETDATA + 'following';
export const URL_PROFILE_FOLLOWERS = URLGETDATA + 'followers';
export const URL_PROFILE_FOLLOW = URLGETDATA + 'follow';
export const URL_PROFILE_UNFOLLOW = URLGETDATA + 'unfollow';
export const URL_PROFILE_ABOUT = URLGETDATA + 'about';
export const URL_PROFILE_COMMENTS = URLGETDATA + 'comments';
export const URL_PROFILE_RECOMMEND = URLGETDATA + 'recommend';

export const URLCOMMENTS = URLGETDATA + 'comments';
export const URL_COMMENT_ADD = URLGETDATA + 'commentAdd';
export const URL_COMMENT_BOOK_ADD = URLGETDATA + 'commentBookAdd';
export const URL_COMMENT_DELETE = URLCOMMENTS + '/delete';
export const URL_COMMENT_GET = URLCOMMENTS + '/get';
export const URL_COMMENT_LIKE = URLGETDATA + '/likecomment';

export const URL_COMMENTS_GET = URLGETDATA + 'comments';

export const URLREGISTER = URLGETDATA + 'register';
export const URLLOGIN = URLGETDATA + 'login';
export const URLGETUSERDATA = URLGETDATA + 'user';

export const URLGETBOOKDATA = URLGETDATA + 'book';
export const URLGETCOMPETITIONDATA = URLGETDATA + 'competitions';

export const URL_GET_COMPETITION_DISCUSSION = URLGETDATA + 'discussion';

export const URL_GET_PHOTOBOOKS = URLGETDATA + 'photobooks';

export const URLPRESETS = URLGETDATA + 'app';
export const URL_PAGE_PHOTOS = URLGETDATA + 'page/';

export const URL_PHOTOGRAPHERS = URLGETDATA + 'photographers';
export const URL_SEARCH_PHOTOGRAPHERS = URLGETDATA + 'searchPhotographer';


export const URL_PHOTOGRAPHERS_FOLLOW = URLGETDATA + 'photographers/follow';
export const URL_PHOTOGRAPHERS_UN_FOLLOW = URLGETDATA + 'photographers/unfollow';

export const URL_COMPETITION = URLGETDATA + 'competition';
export const URL_COMPETITION_PHOTOGRAPHERS = URLGETDATA + 'competitionphotographers';

export const URL_ONE_SELLECT_PHOTOGRAPHER = URLGETDATA + 'getOneSelectedPhotographer';
export const URL_INTERVIEW = URLGETDATA + 'interviews';
export const URL_GET_INTERVIEWS = URLGETDATA + 'interviews';

export const URL_GET_INTERVIEW_QUESTIONS = URLGETDATA + 'getInterviewQuestions';
export const URL_SAVE_INTERVIEW_QUESTIONS = URLGETDATA + 'saveInterviewQuestions';


export const URL_COMPETITIONS_CLOUD = 'https://1be8765335d207ed2c2b-94686f106658c7cf9a3cea5aeb52bfac.ssl.cf5.rackcdn.com/';

export const BASE_URL = '';
export const URL_GET_FLOW = URLGETDATA + 'flow';

export const DEFAULT_IMAGE = '/assets/img/empty.png';

export const URL_GET_NEWS = URLGETDATA + 'news';

export const allLanguages = [
    {language: "Arabic", lan: "ar"},
    {language: "Brasilien", lan: "br"},
    {language: "Bulgarian", lan: "bg"},
    {language: "Chinese", lan: "cn"},
    {language: "Danish", lan: "da"},
    {language: "English", lan: "en"},
    {language: "Hebrew", lan: "he"},
    {language: "Hindi", lan: "hi"},
    {language: "Italien", lan: "it"},
    {language: "Indonesian", lan: "id"},
    {language: "Finish", lan: "fi"},
    {language: "French", lan: "fr"},
    {language: "German", lan: "de"},
    {language: "Greec", lan: "el"},
    {language: "Korean", lan: "ko"},
    {language: "Japanese", lan: "jp"},
    {language: "Persian", lan: "fa"},
    {language: "Polish", lan: "pl"},
    {language: "Portuguese", lan: "pt"},
    {language: "Russian", lan: "ru"},
    {language: "Spanish", lan: "es"},
    {language: "Thai", lan: "th"},
    {language: "Turkey", lan: "tr"},
];

export const allLanguagesShort = ['en', 'fr', 'de', 'nl', 'da', 'ru', 'it', 'fr', 'ar', 'es', 'pr', 'pl', 'th', 'cn', 'tr'];