import {Component, OnInit, ChangeDetectorRef, OnDestroy, Input} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';

import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    Event
} from '@angular/router';

import {PhotosActions, photos} from '../../store/photos';


@Component({
    selector: 'photosViewType',
    templateUrl: './photos.view.type.component.html',
    styleUrls: ['./photos.view.type.component.scss']
})
export class PhotosViewTypeComponent implements OnInit, OnDestroy {
    @select(photos) photos: Observable<any>;

    active: string;
    viewLocalType: string;
    url = '';
    viewTypeSubscription: any;
    showViewType: boolean = false;

    constructor(public cd: ChangeDetectorRef,
                public photosActions: PhotosActions,
                public redux: NgRedux<any>,
                public route: ActivatedRoute,
                public router: Router) {

        router.events.subscribe((event: Event) => {
            if(event instanceof NavigationEnd ){
                this.hasPhotoViewType(event.url);
            }
        });
    }

    ngOnInit() {
        this.viewTypeSubscription = this.photos.subscribe(item => {
            this.viewLocalType = item.viewType;
        });
    }

    ngOnDestroy() {
        if (this.viewTypeSubscription) {
            this.viewTypeSubscription.unsubscribe();
        }
    }

    setViewType(viewType: string) {
        this.redux.dispatch(<any>this.photosActions.setViewType(viewType));
        this.viewLocalType = viewType;
    }

    hasPhotoViewType(url) {
       this.showViewType = (url.indexOf('recommendations') === -1
                        && url.indexOf('winner') === -1
                        && url.indexOf('discussion') === -1
                        && url.indexOf('curator') === -1
                        && url.indexOf('about') === -1
                        && url.indexOf('following') === -1
                        && url.indexOf('followers') === -1
                        && url.indexOf('terms') === -1);
    }
}
