import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';

import {
    allLanguages,
    allLanguagesShort
} from '../../../config/constants';


@Component({
    selector: 'language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent {
    languages = allLanguages;

    constructor(public translate: TranslateService,
                public route: ActivatedRoute,
                public router: Router) {
        translate.addLangs(allLanguagesShort);
        translate.setDefaultLang('en');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    selectLanguage(language) {
        this.translate.use(language);
        const url = this.router.url;
        this.router.navigate(['/' + language + '/' + url.substr(4)]);
    }
}
