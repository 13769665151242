import {Injectable} from '@angular/core';

import {
    PHOTO_VOTE,
    PHOTO_ADD_TO_FAV,
    PHOTO_REMOVE_FROM_FAV,
    PHOTO_GET_INFOS,
    PHOTOS_GET,
    PHOTOS_COMPETITION_GET,
    PHOTO_SET_VIEW_TYPE,
    PHOTO_SET_CATEGORY_TYPE,
    PHOTOS_PROFILE_GET,
    PHOTOS_WINNER_COMPETITION_GET,
    PHOTO_GET_FAVS_AND_VOTES,
    REMOVE_PHOTOS,
    PHOTOS_COUNT_VISITS,
    PHOTO_SET_FILTER,
    PHOTOS_SET
} from './constants';

import {PhotosService} from './service';

@Injectable()
export class PhotosActions {
    constructor(private photos: PhotosService) {
    }

    setPhotos (data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: PHOTOS_SET
            });
        };
    }

    countPhotoView(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_COUNT_VISITS,
                isAsync: true,
                promise: this.photos.countPhotoView(data)
            });
        };
    }

    removePhotos() {
        return (dispatch) => {
            return dispatch({
                type: REMOVE_PHOTOS
            });
        };
    }

    vote(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_VOTE,
                isAsync: true,
                promise: this.photos.vote(data)
            });
        };
    }

    addtofavourites(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_ADD_TO_FAV,
                isAsync: true,
                promise: this.photos.addtofavourites(data)
            });
        };
    }

    removeFromfavourites(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_REMOVE_FROM_FAV,
                isAsync: true,
                promise: this.photos.removeFromfavourites(data)
            });
        };
    }

    getFavesAndVotes(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_GET_FAVS_AND_VOTES,
                isAsync: true,
                promise: this.photos.getFavesAndVotes(data)
            });
        };
    }

    getInformation(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_GET_INFOS,
                isAsync: true,
                promise: this.photos.getInfo(data)
            });
        };
    }

    setViewType(data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: PHOTO_SET_VIEW_TYPE
            });
        };
    }

    setCategory(data) {
        return (dispatch) => {
            return dispatch({
                data,
                category: PHOTO_SET_CATEGORY_TYPE
            });
        };
    }

    setFilter(data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: PHOTO_SET_FILTER
            });
        };
    }

    getPhotos(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_GET,
                isAsync: true,
                loading: true,
                promise: this.photos.getPhotos(data)
            });
        };
    }

    getRandomGalleryPhotos(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_GET,
                isAsync: true,
                loading: true,
                promise: this.photos.getRandomGalleryPhotos(data)
            });
        };
    }
    getPhotosProfile(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_PROFILE_GET,
                isAsync: true,
                promise: this.photos.getPhotosProfile(data)
            });
        };
    }

    getPhotosCompetition(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_COMPETITION_GET,
                isAsync: true,
                promise: this.photos.getPhotosCoompetition(data)
            });
        };
    }

    getWinningPhotosCompetition(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOS_WINNER_COMPETITION_GET,
                isAsync: true,
                promise: this.photos.getWinningPhotosCompetition(data)
            });
        };
    }
}
