import {Component, OnInit, Input, OnDestroy, ChangeDetectorRef, OnChanges,Renderer2, ViewChild} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {ApplicationActions} from '../../store/application';
import {PhotosActions, photos, viewType} from '../../store/photos';

import {Router} from '@angular/router';


import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';
@Component({
    selector: 'photo-slider',
    templateUrl: './photo.slider.component.html',
    styleUrls: ['./photo.slider.component.scss']
})
export class PhotoSliderComponent implements OnInit, OnDestroy, OnChanges {
    @select(photos) photoObserver: Observable<any>;
    @Input() photos: any;
    @Input() ForceViewType = false;
    @Input() curator: any;
    @select(viewType) viewTypes: Observable<any>;

    viewType: string;
    subscription: any;
    total = 0;

    swipeCoord?: [number, number];
    swipeTime?: number;
    mouseDown = false;


    options: NgxSiemaOptions = {
      selector: '.siema',
      duration: 200,
      easing: 'ease-out',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      threshold: 20,
      loop: true,
      onInit: () => {
        // runs immediately after first initialization
      },
      onChange: () => {
        // runs after slide change
      },
    };

    constructor(private applicationActions: ApplicationActions,
                public cd: ChangeDetectorRef,
                public photosActions: PhotosActions,
                private ngxSiemaService: NgxSiemaService,
                public redux: NgRedux<any>,
                private renderer: Renderer2,
                private router: Router) {
    }

    ngOnInit() {
        this.total = photos.length;
    }

    ngOnChanges() {
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    makeImage(thumbnail) {
        return 'https://www.kujaja.com/photos/900/' + thumbnail + '.jpg';
    }

    photoAction() {
    }
    mouseIsDown() {
        this.mouseDown = true;
    }
    mouseIsUp() {
        this.mouseDown = false;
    }

    goToImage(photo) {
        window.scrollTo(0, 0);
        this.redux.dispatch(<any>this.applicationActions.set({shownPhoto: photo}));

        this.router.navigate(['/en/photo/', photo.image_id]);
    }


    noGo() {
        return false;
    }

    next() {
      this.ngxSiemaService.next(1)
        .subscribe((data: any) => {
          // console.log(data);
        });
    }

    previous() {
      // Use the prev function only for ngx-siema instance with selector '.siema'
      this.ngxSiemaService.prev(1, '.siema')
        .subscribe((data: any) => {
          // console.log(data);
        });
    }

}
