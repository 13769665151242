import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {AlertComponent} from './components/alert/alert.component';
import {SharedDirectivesModule} from './shared.directives.module';
import {CommentsComponent} from './components/comments/comments.component';
import {FooterComponent} from './components/footer/footer.component';
import {IconComponent} from './components/icon/icon.component';

import {LoadingComponent} from './components/loading/loading.component';
import {NgMasonryGridModule} from 'ng-masonry-grid';
import {PagerComponent} from './components/pager/pager.component';

import {UserUrlsComponent} from './components/user.urls/user.urls.component';

import {PhotoCompetitionsComponent} from './components/photo.competitions/photo.competitions.component';
import {PhotoInformationComponent} from './components/photo.information/photo.information.component';
import {PhotoNavigatorComponent} from './components/photo.navigator/photo.navigator.component';
import {PhotoMenuComponent} from './containers/photos/menu/photo.menu.component';
import {PhotographersMenuComponent} from './containers/photographers/menu/photographers.menu.component';
import {CompetitionsMenuComponent} from './containers/competitions/competition/menu/competitions.menu.component';

import { ProfileTeaserComponent } from './containers/profile/teaser/profile.teaser.component';

import {PhotosViewTypeComponent} from './components/view.type/photos.view.type.component';
import {PhotographersViewTypeComponent} from './containers/photographers/view.type/photographers.view.type.component';

import {ThumbnailComponent} from './components/thumbnail/thumbnail.component';
import {VoteButtonsComponent} from './components/votebuttons/votebuttons.component';
import {InterviewsButtonComponent} from './components/votebuttons/interview/interview.buttons.component';

import {FollowButtonComponent} from './components/votebuttons/follow/follow.component';
import {NominateButtonComponent} from './components/votebuttons/nominate/nominate.component';
import {GalleryButtonComponent} from './components/votebuttons/gallery/gallery.component';
import {RecommendPhotographerComponent} from './components/votebuttons/recommend.photographer/recommend.photographer.component';
import {SelectedPhotographerButtonComponent} from './components/votebuttons/selected.photographer/selected.photographer.component';
import {LoginAsComponent} from './components/votebuttons/login/login.as.component';

import {PhotographerComponent} from './components/photographer/photographer.component';
import {InfoComponent} from './compunds/overlay/info/info.component';
import {TeaserStandardComponent} from './components/teaser/teaser.component';
import {PhotosYearComponent} from './components/year/photos.year.component';
import {CategoryListComponent} from './components/category.list/category.list.component';

import {TimeAgoPipe} from 'time-ago-pipe';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from './shared.module';
import {CuratorVotingStarsComponent} from "./components/votebuttons/curator.voting.stars/curator-voting-stars.component";
import { StarRatingModule } from 'angular-star-rating';
import {LazyLoadImageModule} from "ng-lazyload-image";

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDYERlMar94noaMz9gzTTxivTxi2mnwuqA', // apiKey: 'AIzaSyCI_KQOEn4B15Vm_9zmwjejr_MqmhzI76A'
        }),
        CommonModule,
        FormsModule,
        LazyLoadImageModule,
        SharedDirectivesModule,
        ReactiveFormsModule,
        StarRatingModule.forRoot(),
        RouterModule,
        NgMasonryGridModule,
        SharedModule
    ],
    declarations: [
        AlertComponent,
        CommentsComponent,
        CompetitionsMenuComponent,
        CategoryListComponent,
        CuratorVotingStarsComponent,
        InfoComponent,
        FollowButtonComponent,
        FooterComponent,
        GalleryButtonComponent,
        IconComponent,
        InterviewsButtonComponent,
        LoadingComponent,
        LoginAsComponent,
        NominateButtonComponent,
        PagerComponent,
        ProfileTeaserComponent,
        UserUrlsComponent,
        PhotoCompetitionsComponent,
        PhotoInformationComponent,
        PhotoNavigatorComponent,
        PhotoMenuComponent,
        PhotographersMenuComponent,
        PhotosViewTypeComponent,
        PhotographerComponent,
        PhotographersViewTypeComponent,
        PhotosYearComponent,
        RecommendPhotographerComponent,
        SelectedPhotographerButtonComponent,
        ThumbnailComponent,
        TeaserStandardComponent,
        TimeAgoPipe,
        VoteButtonsComponent
    ],
    exports: [
        AlertComponent,
        CommentsComponent,
        CompetitionsMenuComponent,
        CuratorVotingStarsComponent,
        CategoryListComponent,
        InfoComponent,
        InterviewsButtonComponent,
        FollowButtonComponent,
        FooterComponent,
        FormsModule,
        GalleryButtonComponent,
        IconComponent,
        LoadingComponent,
        LoginAsComponent,
        NominateButtonComponent,
        PagerComponent,
        ProfileTeaserComponent,
        UserUrlsComponent,
        PhotoCompetitionsComponent,
        PhotoInformationComponent,
        PhotoNavigatorComponent,
        PhotoMenuComponent,
        PhotographersMenuComponent,
        PhotographerComponent,
        PhotosViewTypeComponent,
        PhotosYearComponent,
        ReactiveFormsModule,
        RecommendPhotographerComponent,
        SelectedPhotographerButtonComponent,
        ThumbnailComponent,
        TeaserStandardComponent,
        TimeAgoPipe,
        VoteButtonsComponent,
        NgMasonryGridModule,
        SharedModule
    ]
})
export class SharedComponentsModule {
}
