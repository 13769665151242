
export const USER_CHANGE_LANGUAGE = 'USER_CHANGE_LANGUAGE';

export const USER_ACCEPT_DISCLAIMER = 'USER_ACCEPT_DISCLAIMER';
export const USER_ACCEPT_DISCLAIMER_SUCCESS = 'USER_ACCEPT_DISCLAIMER_SUCCESS';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';


export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';


export const USER_CONFIRM_ACCOUNT = 'USER_CONFIRM_ACCOUNT';
export const USER_CONFIRM_ACCOUNT_SUCCESS = 'USER_CONFIRM_ACCOUNT_SUCCESS';
export const USER_CONFIRM_ACCOUNT_ERROR = 'USER_CONFIRM_ACCOUNT_ERROR';



export const USER_RESEND_CONFIRMATION_EMAIL = 'USER_RESEND_CONFIRMATION_EMAIL';
export const USER_RESEND_CONFIRMATION_EMAIL_SUCCESS = 'USER_RESEND_CONFIRMATION_EMAIL_SUCCESS';
export const USER_RESEND_CONFIRMATION_EMAIL_ERROR = 'USER_RESEND_CONFIRMATION_EMAIL_ERROR';

export const USER_CONFIRM_ACTIVATION_CODE = 'USER_CONFIRM_ACTIVATION_CODE';
export const USER_CONFIRM_ACTIVATION_CODE_SUCCESS = 'USER_CONFIRM_ACTIVATION_CODE_SUCCESS';
export const USER_CONFIRM_ACTIVATION_CODE_ERROR = 'USER_CONFIRM_ACTIVATION_CODE_ERROR';


export const USER_VALIDATE_PASSWORD_TOKEN = 'USER_VALIDATE_PASSWORD_TOKEN';
export const USER_VALIDATE_PASSWORD_TOKEN_SUCCESS = 'USER_VALIDATE_PASSWORD_TOKEN_SUCCESS';
export const USER_VALIDATE_PASSWORD_TOKEN_ERROR = 'USER_VALIDATE_PASSWORD_TOKEN_ERROR';

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR';

export const USER_FORGOT_PASSWORD_PROCESS = 'USER_FORGOT_PASSWORD_PROCESS';

export const USER_DATA_GET = 'USER_DATA_GET';
export const USER_DATA_GET_ERROR = 'USER_DATA_GET_ERROR';
export const USER_DATA_GET_SUCCESS = 'USER_DATA_GET_SUCCESS';

export const USER_DATA_SAVE = 'USER_DATA_SAVE';
export const USER_DATA_SAVE_ERROR = 'USER_DATA_SAVE_ERROR';
export const USER_DATA_SAVE_SUCCESS = 'USER_DATA_SAVE_SUCCESS';

export const USER_SET = 'USER_SET';
export const USER_SET_ERROR = 'USER_SET_ERROR';
export const USER_SET_SUCCESS = 'USER_SET_SUCCESS';

export const USER_FOLLOW = 'USER_FOLLOW';
export const USER_FOLLOW_ERROR = 'USER_FOLLOW_ERROR';
export const USER_FOLLOW_SUCCESS = 'USER_FOLLOW_SUCCESS';

export const USER_UNFOLLOW = 'USER_UNFOLLOW';
export const USER_UNFOLLOW_ERROR = 'USER_UNFOLLOW_ERROR';
export const USER_UNFOLLOW_SUCCESS = 'USER_UNFOLLOW_SUCCESS';



export const USER_LOGIN_AS = 'USER_LOGIN_AS';
export const USER_LOGIN_AS_SUCCESS = 'USER_LOGIN_AS_SUCCESS';
export const USER_LOGIN_AS_ERROR = 'USER_LOGIN_AS_ERROR';




