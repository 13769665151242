import {Injectable} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

@Injectable()
export class UserService {

    constructor(
                public redux: NgRedux<any>) {
    }

    public isLoggedIn(): boolean {
        const {user} = ensureState(this.redux.getState());

        if (user.isLoggedIn) {
            return true;
        }

        return false;
    }

    public isCurator(): boolean {
        const {user} = ensureState(this.redux.getState());
        let isCurator = false;
        if (user.isLoggedIn) {
            if (user.roles) {
                user.roles.find(role => {
                    if (role.role === 1 || role.role === 48 || role.role === 5 || role.role === 19 || role.role === 2) {
                        isCurator = true;
                    }
                });
            }
        }
        return isCurator;
    }

    public isAdmin(): boolean {
        const {user} = ensureState(this.redux.getState());
        let isAdmin = false;
        if (user.isLoggedIn) {
            if (user.roles) {
                user.roles.find(role => {
                    if (role.role === 1) {
                        isAdmin = true;
                    }
                });
            }
        }
        return isAdmin;
    }
}
