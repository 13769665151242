import {Injectable} from '@angular/core';

import {
    CanActivate,
    CanActivateChild,
    Router
} from '@angular/router';

import {NgRedux} from '@angular-redux/store';
import {ensureState} from 'redux-optimistic-ui';

import {ApplicationActions} from '../../store/application';
import {UserActions} from '../../store/user';

@Injectable()
export class CanActivateViaLoggedInGuard implements CanActivate, CanActivateChild {
    constructor(private redux: NgRedux<any>,
                private app: ApplicationActions,
                private userActions: UserActions,
                private router: Router) {
    }

    public canActivate() {
        return this.isLoggedIn();
    }

    public canActivateChild() {
        return this.isLoggedIn();
    }

    isLoggedIn() {
        const {user} = ensureState(this.redux.getState());
        const isLoggedIn = user.isLoggedIn;

        const acceptedDisclaimer = true; //user.acceptedDisclaimer;
        if (isLoggedIn) {
            return true;
        }


        if (isLoggedIn === 'true' && acceptedDisclaimer) {
            return true;
        } else if (isLoggedIn && !acceptedDisclaimer) {
            this.router.navigate(['/en/disclaimer']);
            return false;
        } else {
            this.redux.dispatch(<any>this.app.set({overlay: 'true'}));
            this.redux.dispatch(<any>this.app.set({redirect: location.pathname}));
            // this.router.navigate(['/en/login']);
            return false;
        }
    }
}
