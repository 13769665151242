import {Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {FormGroup, FormBuilder} from '@angular/forms';
import {AsyncLocalStorage} from 'angular-async-local-storage';
import {photos} from '../../store/photos';
import {InterActions} from '../../store/inter';

import {user} from '../../store/user';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs/Rx";

@Component({
    selector: 'reset-password',
    templateUrl: './resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    error: boolean;
    subscription: Subscription;
    routeSubscription: Subscription;
    form: FormGroup;
    loading: false;
    errorNotEqual: boolean = false;
    errorNotEnough: boolean = false;
    code: string = '';

    constructor(public userActions: UserActions,
                public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                public formBuilder: FormBuilder,
                public interActions: InterActions,
                public route: ActivatedRoute,
                public router: Router,
                protected localStorage: AsyncLocalStorage,
                public redux: NgRedux<any>) {

        this.form = formBuilder.group({
            password: [''],
            password2: ['']
        });
    }

    ngOnInit() {
        this.subscription = this.user.subscribe(use => {
            this.error = use.error;
            this.loading = use.loading;

            if (use.recoveryId) {
                this.code = use.recoveryId;
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            }

            if (use.passwordChanged) {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }

    submit(form) {
        const recoverpassword = Object.assign({}, form.value, {tokenTime:  Date.now(), code: this.code} );

        this.errorNotEqual = false;
        this.errorNotEnough = false;

        if (this.code ==='') {
            this.error = true;
        }
        this.error = false;


        if (form.value.password!== form.value.password2) {
            this.errorNotEqual = true;
            return;
        }
        this.errorNotEqual = false;

        if (form.value.password.length < 8) {
            this.errorNotEnough = true;
            return;
        }

        if (form.status !== 'INVALID') {
            this.redux.dispatch(<any>this.userActions.resetPassword(recoverpassword));
        } else {
            this.error = true;
        }
    }

    signUp(form) {

    }
}
