import {combineReducers} from 'redux';

import {USER_LOGOUT_SUCCESS} from './user/constants';

// import reducer
import interactions from './inter/reducer';
import application from './application/reducer';
import flow from './flow/reducer';
import interviews from './interviews/reducer';
import comment from './comments/reducer';
import user from './user/reducer';
import messages from './messages/reducer';
import news from './news/reducer';
import pages from './pages/reducer';
import photos from './photos/reducer';
import profile from './profile/reducer';
import photographers from './photographers/reducer';
import competitions from './competitions/reducer';

const appReducer = combineReducers({
    interactions,
    application,
    comment,
    flow,
    interviews,
    competitions,
    messages,
    news,
    pages,
    photos,
    profile,
    photographers,
    user
});

export default (state, action) => {
    // console.log(state);
    if (action.type === USER_LOGOUT_SUCCESS) {
        state = Object.assign({}, state, {
            user: {
                error: false,
                loading: false,
                isLoggedIn: false,
                emailConfirmed: 3,
                birthday: '',
                name: '',
                id: '',
                profile: '',
                profile_image: '',
                token: ''
            }
        });
        localStorage.removeItem('user');

        // localStorage.setItem('user', ['cookiesAccept': true])
        /* state = Object.assign(state, {
            application: state.application.set('loading', false)
        }); */
    }

    return appReducer(<any>state, action);
};
