import {
    ChangeDetectionStrategy,
    ChangeDetectorRef, Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild,
    Input
} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';

import {PhotosActions} from '../../store/photos';

@Component({
    selector: 'teaser',
    templateUrl: './teaser.component.html',
    styleUrls: ['./teaser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserStandardComponent implements OnDestroy, OnInit {
    @ViewChild('teaserWrapper') teaserWrapper;
    @ViewChild('teaserImage') teaserImage;

    @ViewChild('backgroundImage') backgroundImage;
    @Output() visibilityPage = new EventEmitter<any>();
    @Input() teaser: any;

    gallerySubscription: any;
    startPreLoading = false;
    constructor(public cd: ChangeDetectorRef,
                public photosActions: PhotosActions,
                public redux: NgRedux<any>) {

    }

    onResize(event) {
        this.setSize(); // event.target.innerHeight
    }

    ngOnInit() {
        let imageName = 'https://1be8765335d207ed2c2b-94686f106658c7cf9a3cea5aeb52bfac.ssl.cf5.rackcdn.com/' +
                        this.teaser.id + '_teaser.jpg';

        if (!this.teaser.id) {
            imageName = this.teaser.full;
        }
        if (this.teaser.image_id) {
            this.redux.dispatch(<any>this.photosActions.countPhotoView({image_id: this.teaser.image_id}));
        }
// console.log(this.teaser);
        if (this.teaser.book_image_id) {
            this.teaser.image_id = this.teaser.book_image_id;
            this.redux.dispatch(<any>this.photosActions.countPhotoView({image_id: this.teaser.book_image_id}));
        }

        this.backgroundImage.nativeElement.src = imageName;
        this.teaserImage.nativeElement.style.backgroundImage = `url(${imageName})`;

        setTimeout(() => {
            this.cd.markForCheck();
        }, 30);
    }

    ngOnDestroy() {
        if (this.gallerySubscription) {
            this.gallerySubscription.unsubscribe();
        }
    }

    prepareHTML(html) {
        if (this.teaser.title_colour === 'FFFFFF') {
        return html.replace('<a href=', '<a class="white" href=')
            .replace('https://world-street.photography/en', '/en/profile')
            .replace('https://www.kujaja.com/en', '/en/profile');
        }
        return html;
    }

    initiateSets($event) {
        this.visibilityPage.emit($event);
        this.startPreLoading = true;
        this.setSize();
    }

    setSize() {
        if (this.backgroundImage.nativeElement.height > this.backgroundImage.nativeElement.width) {
            this.backgroundImage.nativeElement.style.width = 'auto';
            this.backgroundImage.nativeElement.style.height = 'auto';
            this.teaserImage.nativeElement.style.backgroundSize = 'contain';
        } else {
            this.backgroundImage.nativeElement.style.width = '100%';
            this.teaserImage.nativeElement.style.backgroundSize = 'cover';
            this.teaserImage.nativeElement.style.animation = 'zoom 30s ease-in infinite alternate';
        }
    }

    noGo() {
        return false;
    }

}
