import { fromJS } from 'immutable';

import { ensureState } from 'redux-optimistic-ui';
const persistState = require('redux-localstorage');

import { application, competitions, user} from './initials';

interface Window {
  devToolsExtension?: () => void;
}

const enhancers = [
  persistState(undefined, {
    key: `application`,
    serialize: (store) => {
      const { application } = ensureState(store);
      return JSON.stringify(application ? application : {}); // .toJS()
    },
    deserialize: (state) => {
      state = state ? JSON.parse(state) : application;
      return Object.assign({ application: state });
    }
  }),
  persistState(undefined, {
    key: `user`,
    serialize: (store) => {
      const { user } = ensureState(store);
      return JSON.stringify(user ? user : {});
    },
    deserialize: (state) => {
      state = state ? JSON.parse(state) : user;
      return Object.assign({ user: state });
    }
  }),
  persistState(undefined, {
    key: `competitions`,
    serialize: (store) => {
      const { competitions } = ensureState(store);
      // console.log('serialize competitions', competitions);
      return JSON.stringify(competitions ? competitions : {});
    },
    deserialize: (state) => {
      state = state ? JSON.parse(state) : competitions;
      return Object.assign({ competitions: state });
    }
  })
    /*,
  persistState(undefined, {
    key: `solaris-profile`,
    serialize: (store) => {
      const { profile } = ensureState(store);
      return JSON.stringify(profile ? profile.toJS() : {});
    },
    deserialize: (state) => {
      state = state ? JSON.parse(state) : profile;
      return Object.assign({ profile: fromJS(state) });
    }
  }),
  persistState(undefined, {
    key: `solaris-user`,
    serialize: (store) => {
      const { user } = ensureState(store);
      return JSON.stringify(user ? user.toJS() : {});
    },
    deserialize: (state) => {
      state = state ? JSON.parse(state) : user;
      return Object.assign({ user: fromJS(state) });
    }
  }) */
];
/*
if (window.devToolsExtension) {
  enhancers = [ ...enhancers, window.devToolsExtension() ];
}
*/
export const enhancer = enhancers;
