import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, OnDestroy, OnInit
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';

import {
    application,
} from '../../store/application';
import {Subscription} from "rxjs/Rx";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {
    @select(application) application: Observable<any>;

    constructor(public cd: ChangeDetectorRef) {}

    hideFooter = false;
    subscription: Subscription;

    ngOnInit(): void {
        this.subscription = this.application.subscribe(item => {
            // console.log('hideFooter', item.loading)
            this.hideFooter = item.loading;

            setTimeout(() => {
                this.cd.markForCheck();
            }, 20);
        });
    }
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
