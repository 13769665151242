import {news} from '../initials';

import {
    NEWS_GET,
    NEWS_GET_ERROR,
    NEWS_GET_SUCCESS
} from './constants';


export default (state = news, action: any) => {
    switch (action.type) {


        case NEWS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: false
            });

        case NEWS_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case NEWS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        default:
            return state;
    }
};
