import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ChangeDetectorRef} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {application, ApplicationActions} from '../../store/application';
import {user, UserActions} from '../../store/user';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges, OnDestroy {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    show = false;
    subscriptionApplication: any;
    subscriptionUser: any;
    text = '';
    type = '';
    email = '';
    hideAlert = false;

    constructor(public redux: NgRedux<any>,
                public cd: ChangeDetectorRef,
                public applicationActions: ApplicationActions,
                public userActions: UserActions) {
     }

    ngOnInit() {
        this.subscriptionApplication = this.application.subscribe(item => {
            if (item.alert && item.alert.show ) {
                this.show = true;
                this.hideAlert = false;

                this.text = item.alert.text;
                this.type = item.alert.type;

                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            }
        });
    }

    acceptCookies() {
        this.redux.dispatch(<any>this.applicationActions.set({'alert': false}));
        this.redux.dispatch(<any>this.applicationActions.set({'cookiesAccept': true}));
        this.redux.dispatch(<any>this.userActions.set({'cookiesAccept': true}));

        this.hideAlert = true;
    }

    resendConfirmationEmail() {
        this.hideAlert = true;
    }

    close() {
        this.hideAlert = true;
        this.redux.dispatch(<any>this.applicationActions.set({'alert': false}));
    }

    ngOnDestroy() {
        if (this.subscriptionApplication) {
            this.subscriptionApplication.unsubscribe();
        }
        if (this.subscriptionUser) {
            this.subscriptionUser.unsubscribe();
        }
    }
    ngOnChanges() {

    }
}
