import {
    Component,
    ChangeDetectorRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user} from '../../store/user';
import {PhotosActions} from '../../store/photos';
import {application, ApplicationActions} from '../../store/application';
import {photos} from '../../store/photos';
import {interactions, InterActions} from '../../store/inter';
import {LocationService} from '../../services/location.service';
import {UserService} from '../../services/user.service';


@Component({
    selector: 'voteButtons',
    templateUrl: './votebuttons.component.html',
    styleUrls: ['./votebuttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoteButtonsComponent implements OnInit, OnDestroy, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() showExtraButtons: boolean;
    @Input() imageId: number;
    @Input() overLay: boolean;
    @Input() userLogin: string;
    @Input() allowVoting: boolean;


    hasVoted = false;
    hasFaved = false;
    hasRecommended = false;
    isFollowing = false;

    subscription: any;
    subscriptionVotes: any;
    viewType: string;

    showGalleryButton = false;
    showNominateButton = false;
    showExtraButton = false;
    showFollowButton = false;
    galleryVoted = false;
    isCurator = false;

    constructor(public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                private locationService: LocationService,
                public redux: NgRedux<any>,
                public photoActions: PhotosActions,
                public interActions: InterActions,
                public userService: UserService) {
    }

    ngOnInit() {
        this.checkVotes();
    }

    ngOnChanges() {
        this.checkVotes();
    }

    checkVotes() {
        this.showFollowButton = (this.locationService.getPreviuosPage().indexOf('profile') === -1);

        this.showExtraButton = this.showExtraButtons ? this.showExtraButtons : false;

        const {user} = ensureState(this.redux.getState());
        if (this.userService.isLoggedIn()) {

            if (this.userService.isCurator()) {
                this.showGalleryButton = true;
                this.showNominateButton = true;
            }

            if (this.userService.isAdmin()) {
                this.isCurator = true;
            }

            this.cd.markForCheck();

            this.subscriptionVotes = this.interactions.subscribe(item => {
                this.viewType = item.viewType;
                if (item.voted && item.voted.length) {
                    this.hasVoted = (item.voted.indexOf(this.imageId) > -1);
                }
                if (item.faved && item.faved.length) {
                    this.hasFaved = (item.faved.indexOf(this.imageId) > -1);
                }
                if (item.galleryVoted && item.galleryVoted.length) {
                    this.galleryVoted = (item.galleryVoted.indexOf(this.imageId) > -1);
                }
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionVotes) {
            this.subscriptionVotes.unsubscribe();
        }
    }

    photoAction($event, type) {

        this.subscription = this.user.subscribe(use => {
            if (use.profile !== '') {
                if (this.allowVoting) {
                    this.redux.dispatch(<any>this.app.set({overlay: false}));
                    if (type === 'vote') {
                        this.redux.dispatch(<any>this.interActions.vote({image_id: this.imageId}));
                        this.hasVoted = true;
                    } else if (type === 'addtofavourites') {
                        this.redux.dispatch(<any>this.interActions.addtofavourites({image_id: this.imageId}));
                        this.hasFaved = true;
                    } else if (type === 'removeFromfavourites') {
                        this.redux.dispatch(<any>this.interActions.removeFromfavourites({image_id: this.imageId}));
                        this.hasFaved = false;
                    } else if (type === 'recommended') {
                        this.redux.dispatch(<any>this.interActions.recommend({image_id: this.imageId}));
                        this.hasRecommended = true;
                    }
                }
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }

    shareWithFacebook(): void {
        window.open('https://share.world-street.photography/en/getphoto/index/world-street.photography/en/'+ this.imageId, '_blank')
    }
}
