import {Injectable} from '@angular/core';

import {
    COMPETITION_GET,
    COMPETITIONS_GET,
    DISCUSSIONS_GET,
    DISCUSSIONS_GET_MORE,
    PHOTOBOOKS_GET,
    PHOTOGRAPHERS_GET
} from './constants';

import {CompetitionsService} from './service';

@Injectable()
export class CompetitionsActions {
    constructor(private competitions: CompetitionsService) {
    }

    getCompetition(data) {
        return (dispatch) => {
            return dispatch({
                type: COMPETITION_GET,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.competitions.getCompetition(data)
            });
        };
    }

    getPhotographers(data) {
            return (dispatch) => {
                return dispatch({
                    type: PHOTOGRAPHERS_GET,
                    isAsync: true,
                    loading: true,
                    error: false,
                    promise: this.competitions.getPhotographers(data)
                });
            };
        }

    getDiscussion(data) {
        return (dispatch) => {
            return dispatch({
                type: DISCUSSIONS_GET,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.competitions.getDiscussion(data)
            });
        };
    }

    getMoreDiscussion(data) {
        return (dispatch) => {
            return dispatch({
                type: DISCUSSIONS_GET_MORE,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.competitions.getMoreDiscussion(data)
            });
        };
    }

    getAllCompetitions(data) {
        return (dispatch) => {
            return dispatch({
                type: COMPETITIONS_GET,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.competitions.getCompetitions(data)
            });
        };
    }

    getAllPhotobooks() {
        return (dispatch) => {
            return dispatch({
                type: PHOTOBOOKS_GET,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.competitions.getAllPhotobooks()
            });
        };
    }
}
