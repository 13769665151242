import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {
    ActivatedRoute,
    Router
} from '@angular/router';

import {PhotosActions, photos} from '../../store/photos';


@Component({
    selector: 'categoryList',
    templateUrl: './category.list.component.html',
    styleUrls: ['./category.list.component.scss']
})
export class CategoryListComponent implements OnInit {
    @select(photos) photos: Observable<any>;

    active: string;
    viewLocalType: string;
    url = '';

    constructor(public redux: NgRedux<any>,
                public photosActions: PhotosActions,
                public route: ActivatedRoute,
                public router: Router) {
        this.router.events.subscribe(par => {
            this.url = router.url;
        });
        this.route.params.subscribe(params => {
            this.active = (params.id ?  params.id : '');
        });
    }

    ngOnInit() {
        // this.viewLocalType = this.photoService.getcategoryType();
    }


    setcategoryType(categoryType: string) {
        this.redux.dispatch(<any>this.photosActions.setCategory(categoryType));
        this.viewLocalType = categoryType;
    }
}
