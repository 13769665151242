import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {PhotoService} from '../../services/photos.service';
import {Masonry, MasonryGridItem} from 'ng-masonry-grid'; // import necessary datatypes
import { AsyncLocalStorage } from 'angular-async-local-storage';

import {NgRedux, select} from '@angular-redux/store';
import {ApplicationActions} from '../../store/application';
import {ensureState} from 'redux-optimistic-ui';


@Component({
    selector: 'masonry-list',
    templateUrl: './masonrylist.component.html',
    styleUrls: ['./masonrylist.component.scss']
})
export class MasonryListComponent implements OnInit, OnDestroy {
    @Input() photos: any;
    @Input() viewType = 'thumbnails';
    @Input() showButtons = false;
    _masonry: Masonry;
    masonryItems: any[];
    BottomInterval: any;
    pageNumber: number;
    newPhotos: any;
    masonrySubscription: any;
    masoneryPhotos: any;

    constructor(protected localStorage: AsyncLocalStorage,
                private router: Router,
                private applicationActions: ApplicationActions,
                public redux: NgRedux<any>,
                public photoService: PhotoService) {
        /* this.masonrySubscription = this.photoService.getPhotos().subscribe((masonry) => {
            if (masonry['photos'].length > 0) {
                this.masoneryPhotos = masonry['photos'];
                this.startEndOfPage();
            }
        });*/
    }

    ngOnInit() {
        this.pageNumber = 0;
        this.startEndOfPage();
    }


    ngOnDestroy() {
        this.killInterval();
    }

    killInterval() {
        if (this.BottomInterval) {
            clearInterval(this.BottomInterval);
        }
    }

    startEndOfPage() {
        return this.BottomInterval = window.setInterval(() => {
            this.checkEndOfPage();
        }, 300);
    }

    checkEndOfPage() {
        if ((window.innerHeight + 300) + window.scrollY >= window.document.body.scrollHeight) {
            this.killInterval();
            this.goGetMoreImages();
        }
    }

    goToImage(photo) {
        this.redux.dispatch(<any>this.applicationActions.set({shownPhoto: photo}));
        this.photoService.setShownPhoto(photo);
        this.router.navigate(['/en/photo/', photo.image_id]);
    }

    goGetMoreImages() {
        this.pageNumber = this.pageNumber + 1;
        this.newPhotos = this.photoService.getPhotosByPage('/en/photos/recommendations/', this.pageNumber);

        this.newPhotos.subscribe(photos => {
            photos['photos'].forEach(photo => {
                const AddOrNot = this.photos.find(foto => (foto.image_id === photo.image_id));
                if (!AddOrNot) {
                    this.photos.push(photo);
                }
            });
            this.localStorage.setItem('recommendations', {photos: this.photos, total: 20}).subscribe(() => {});

            setTimeout(() => {
                this.startEndOfPage();
            }, 5000);
            this.localStorage.getItem('recommendations').subscribe((data) => {

            });
        });
    }

    setViewType(viewType: string) {
        this.viewType = viewType;
    }

    appendItems(photos) {
        if (this._masonry) { // Check if Masonry instance exists
            this._masonry.setAddStatus('append'); // set status to 'append'
            this.masonryItems.push(...photos); // some grid items: items
        }
    }
}
