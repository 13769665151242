import {
    ChangeDetectionStrategy,
    Component,
    Input, OnChanges,
    OnDestroy,
    Output, ChangeDetectorRef,
    ViewChild
} from '@angular/core';


import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions, user} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {CommentActions, comment} from '../../store/comments';
import {FormArray, FormGroup, FormControl} from '@angular/forms';
import {PhotosActions} from '../../store/photos';

@Component({
    selector: 'comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent implements OnChanges, OnDestroy {

    @select(user) user: Observable<any>;
    @select(comment) comment: Observable<any>;

    @ViewChild('textWrapper') textWrapper;
    @ViewChild('photoComment') photoComment;

    @Input() imageId: any;
    @Input() bookId: string;
    @Input() profileId: string;
    @Input() commentType: number;
    @Input() photoBackgroundWhite: boolean;

    formComment = new FormGroup({
        firstText: new FormControl()
    });

    PageActive: 0;
    comments: any;
    list: any;
    loading: boolean = true;
    subscriptionUser: any;
    subscriptionComment: any;
    pageNumber: number = 0;
    mayLoadMore: boolean = false;
    hasMoreComments: boolean = false;

    constructor(public applicationActions: ApplicationActions,
                public cd: ChangeDetectorRef,
                public commentActions: CommentActions,
                public photosActions: PhotosActions,
                public redux: NgRedux<any>) {}

    ngOnChanges() {
        this.getComments();
    }

    loadMoreComments() {
        const {user} = ensureState(this.redux.getState());
        this.pageNumber++;

        if (user.isLoggedIn) {
            this.redux.dispatch(<any>this.commentActions.getMoreCommentsWithToken({
                imageId: this.imageId ? this.imageId : null,
                competition: this.bookId ? this.bookId : null,
                profile: this.profileId ? this.profileId : null,
                pageNumber: this.pageNumber
            }));
        } else {
            this.redux.dispatch(<any>this.commentActions.getMoreComments({
                imageId: this.imageId ? this.imageId : null,
                competition: this.bookId ? this.bookId : null,
                profile: this.profileId ? this.profileId : null,
                pageNumber: this.pageNumber
            }));
        }
    }

    likeComment(id, userLogin) {
        this.redux.dispatch(<any>this.commentActions.likeComment({
            commentId: id,
            userLogin: userLogin,
            imageId: this.imageId ? this.imageId : null,
            competition: this.bookId ? this.bookId : null,
            profile: this.profileId ? this.profileId : null,
        }));
    }

    getComments() {
        if (this.subscriptionComment) {
            this.subscriptionComment.unsubscribe();
        }

        const {user} = ensureState(this.redux.getState());


        this.comments = null;
        setTimeout(() => {
            this.cd.markForCheck();
        }, 0);
        if ((this.imageId && this.imageId > 0) || (this.bookId && this.bookId !== '') || (this.profileId && this.profileId !== '')) {

            this.comments = null;
            if (user.isLoggedIn) {
                this.redux.dispatch(<any>this.commentActions.getCommentsWithToken({
                    imageId: this.imageId ? this.imageId : null,
                    competition: this.bookId ? this.bookId : null,
                    profile: this.profileId ? this.profileId : null,
                    pageNumber: this.pageNumber
                }));
            } else {
                this.redux.dispatch(<any>this.commentActions.getComments({
                    imageId: this.imageId ? this.imageId : null,
                    competition: this.bookId ? this.bookId : null,
                    profile: this.profileId ? this.profileId : null,
                    pageNumber: this.pageNumber
                }));
            }


            if (this.imageId == !undefined) {
                this.redux.dispatch(<any>this.photosActions.countPhotoView({image_id: this.imageId}));
            }

            this.subscriptionUser = this.comment.subscribe(comments => {
                if (comments) {
                    if (comments['comments']) {
                        this.comments = comments['comments'];
                    }
                    this.hasMoreComments = comments['more'];

                    if (comments['moreComments']) {
                        let allComments = this.comments;
                        this.comments = allComments.concat(comments['moreComments']);
                    }

                    this.loading = false;

                    this.comments.forEach(item => {
                        this.formComment.addControl('commentparent' + item.id, new FormControl());
                    });

                    setTimeout(() => {
                        this.cd.markForCheck();
                    }, 0);
                }
            });
        }
    }

    replaceBreaks(value) {
        if (value && value.length) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
        return value;
    }

    ngOnDestroy() {
        if (this.subscriptionComment) {
            this.subscriptionComment.unsubscribe();
        }
        if (this.subscriptionUser) {
            this.subscriptionUser.unsubscribe();
        }
    }

    photoActionLikeComment($event) {

    }

    photoActionReply($event) {
        $event.showTextArea = true;
        this.cd.markForCheck();
    }

    photoActionComment($event, commentId) {
        let comment = '';
        let submitComment = false;
        const formValue = this.formComment.value;

        if (commentId !== 0) {
            this.list = [];
            this.comments.find(item => {
                if (item.id === commentId && item.showTextArea === 'true') {
                    submitComment = true;
                    comment = formValue['commentparent' + commentId];
                    submitComment = true;
                    this.list.push(item);
                }

                if (item.id === commentId && item.showTextArea === 'false') {
                    item.showTextArea = 'true';
                    submitComment = false;
                }
            });
        } else {
            comment = formValue['firstText'];
            submitComment = !!comment;
        }

        if (submitComment && comment !== '' && comment !== null) {
            this.subscriptionUser = this.user.subscribe(use => {
                if (use && use.isLoggedIn) {
                    this.redux.dispatch(<any>this.commentActions.postComment({
                        commentType: this.commentType,
                        parentid: commentId,
                        image_id: this.imageId,
                        profile_id: this.profileId,
                        book_id: this.bookId,
                        comment: commentId === 0 ? this.photoComment.nativeElement.value : comment
                    }));
                    if (commentId === 0) {
                        this.formComment.patchValue({firstText: ''});
                    } else {
                        const formName = 'commentparent' + commentId;
                        this.formComment.patchValue({formName: ''});

                        this.comments.find(item => {
                            if (item.id === commentId && item.showTextArea === 'true') {
                                item.showTextArea = 'false';
                                item.comment_end = 0;
                            }
                        });
                    }
                    this.formComment.patchValue({firstText: ''});
                } else {
                    this.redux.dispatch(<any>this.applicationActions.set({overlay: 'login'}));
                }
            });
        }

//         this.photoAction.emit($event);
    }
}
