import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const screenfull = require('screenfull');

@Directive({
    exportAs: 'toggleFullscreen',
  selector: '[toggleFullscreen]'
})
export class ToggleFullscreenDirective {
  constructor(private el: ElementRef) {}
  @HostListener('mouseenter') onMouseEnter() {
  }

  @HostListener('click') onClick() {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
}
