import {Component, Input, OnChanges, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {application, ApplicationActions} from '../../store/application';
import {photos, PhotosActions} from '../../store/photos';


import {
    ActivatedRoute,
    Router,
    RouterModule
} from '@angular/router';

@Component({
    selector: 'photoCompetitions',
    templateUrl: './photo.competitions.component.html',
    styleUrls: ['./photo.competitions.component.scss']
})
export class PhotoCompetitionsComponent implements OnInit, OnDestroy {
    @select(application) application: Observable<any>;
    @select(photos) photos: Observable<any>;
    @Input() user: Observable<string>;
    @Input() imageId: Observable<any>;

    show = false;
    photoCompetitionsSubscribtion: any;
    photo: any;

    textColor = '#000000';
    showInfo = false;
    loading = false;
    copyright: any;
    competitionsAvailable = false;
    competitions: any;

    otherUploads = false;


    constructor(public applicationActions: ApplicationActions,
                public cd: ChangeDetectorRef,
                private domSanitizer: DomSanitizer,
                public photosActions: PhotosActions,
                public redux: NgRedux<any>,
                public route: ActivatedRoute,
                public router: Router) {
    }

    ngOnInit() {
        this.showCompetitions();
    }

    ngOnDestroy() {
        if (this.photoCompetitionsSubscribtion) {
            this.photoCompetitionsSubscribtion.unsubscribe();
        }
    }

    goToPhoto(page) {
        this.cd.markForCheck();
        this.router.navigate([page]);
        window.scrollTo(0, 0);
    }

    goToCompetition(item: any) {
        const page = (item.book_status === 25 ? 'winner' : 'photos');
        window.scrollTo(0, 0);
        this.cd.markForCheck();
        this.router.navigate(['/en/photo-competitions/' + item.book_id_out + '/' + page]);
    }

    showCompetitions() {
        this.otherUploads = false;
        if (this.imageId) {
            this.redux.dispatch(<any>this.applicationActions.getCompetitionsPhotosFromMember('/' + this.imageId));
        }

        this.photoCompetitionsSubscribtion =  this.application.subscribe(item => {

            this.loading = item.loading;

                if (item.competitionphotos && item.competitionphotos.length && item.competitionphotos[0]) {
                    this.otherUploads = true;
                    /*
                    if (item.competitions[0] && item.competitions[0].photos && item.competitions[0].photos.length && item.competitions[0].photos[0]) {
                        if (item.competitions[0].photos.length === 1 && item.competitions[0].photos[0].image_id === this.photo.image_id) {
                            this.otherUploads = false;
                        } else {
                            this.otherUploads = true;
                        }
                    } else {
                        this.otherUploads = true;
                    } */
                    this.competitionsAvailable = true;
                    this.competitions = item.competitionphotos;
                } else {
                    this.competitionsAvailable = false;
                    this.competitions = null;
                }
                this.loading = false;
            setTimeout(() => {
                this.cd.markForCheck();
            }, 0);

        });
    }
}
