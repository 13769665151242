import {NgModule} from '@angular/core';

import {SharedComponentsModule} from './shared.components.module';
import {SharedDirectivesModule} from './shared.directives.module';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {OverlayComponent} from './compunds/overlay/overlay.component';
import {HeaderComponent} from './compunds/header/header.component';
import {LogoComponent} from './compunds/header/logo/logo.component';
import {LanguageComponent} from './compunds/header/language/language.component';
import {MenuComponent} from './compunds/header/menu/menu.component';
import {MessagesComponent} from './compunds/header/messages/messages.component';
import {MyMenuComponent} from './compunds/header/my.menu/my.menu.component';
import {LoginComponent} from './compunds/login/login.component';
import {ForgotPasswordComponent} from './compunds/forgotpassword/forgotpassword.component';

import {PhotoListComponent} from './compunds/photolist/photolist.component';
import {PhotoSliderComponent} from './compunds/photo.slider/photo.slider.component';
import {NewsListComponent} from './compunds/newlist/newslist.component';
import {SharedModule} from './shared.module';

import {MasonryListComponent} from './compunds/masonrylist/masonrylist.component';
import {PhotographerListComponent} from './compunds/photographerlist/photographerlist.component';

import {PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import { NgxSiemaModule } from 'ngx-siema';
import {RegisterComponent} from "./compunds/register/register.component";
import {ResetPasswordComponent} from "./compunds/resetpassword/resetpassword.component";
import {ConfirmAccountComponent} from "./compunds/confirmaccount/confirmaccount.component";
import {RegisteredComponent} from "./compunds/registered/registered.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const list = [
    ForgotPasswordComponent,
    HeaderComponent,
    LogoComponent,
    LanguageComponent,
    MenuComponent,
    MessagesComponent,
    MyMenuComponent,
    LoginComponent,
    NewsListComponent,
    OverlayComponent,
    RegisterComponent,
    ResetPasswordComponent,
    PhotoListComponent,
    PhotoSliderComponent,
    PhotographerListComponent,
    MasonryListComponent
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PerfectScrollbarModule,
        RouterModule,
        SharedComponentsModule,
        SharedDirectivesModule,
        SharedModule,
        NgxSiemaModule.forRoot(),
    ],
    declarations: [
        ConfirmAccountComponent,
        ForgotPasswordComponent,
        HeaderComponent,
        LogoComponent,
        LanguageComponent,
        MenuComponent,
        MessagesComponent,
        MyMenuComponent,
        LoginComponent,
        NewsListComponent,
        OverlayComponent,
        PhotoListComponent,
        PhotoSliderComponent,
        PhotographerListComponent,
        RegisterComponent,
        RegisteredComponent,
        ResetPasswordComponent,
        MasonryListComponent
    ],
    exports: [
        ConfirmAccountComponent,
        ForgotPasswordComponent,
        HeaderComponent,
        LogoComponent,
        LanguageComponent,
        MenuComponent,
        MessagesComponent,
        MyMenuComponent,
        LoginComponent,
        NewsListComponent,
        OverlayComponent,
        PhotoListComponent,
        PhotoSliderComponent,
        PhotographerListComponent,
        RegisterComponent,
        RegisteredComponent,
        ResetPasswordComponent,
        MasonryListComponent,
        SharedModule

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class SharedCompoundsModule {
}
