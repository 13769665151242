/*
 * These are globally available services in any component or any other service
 */
import {Title} from '@angular/platform-browser';

import {PhotoService} from '../../services/photos.service';
import {CanActivateViaLoggedInGuard} from '../guards/logged_in';

import {
    InterActionService,
    InterActions
} from '../../store/inter';

import {
    ApplicationActions,
    ApplicationService
} from '../../store/application';

import {
    CommentActions,
    CommentService,
} from '../../store/comments';

import {
    FlowActions,
    FlowService
} from '../../store/flow';



import {
    InterviewsActions,
        InterviewsService,
} from '../../store/interviews';



import {PageActions, PageService} from '../../store/pages';
import {
    MessagesActions,
    MessagesService
} from '../../store/messages';

import {
    NewsActions,
    NewsService
} from '../../store/news';

import {
    PhotosActions,
    PhotosService
} from '../../store/photos';

import {
    ProfileActions,
    ProfileService
} from '../../store/profile';

import {
    UserService,
    UserActions
} from '../../store/user';


import {
    PhotographersService,
    PhotographersActions
} from '../../store/photographers';


import {
    BaseService
} from './services';

import {CompetitionsActions, CompetitionsService} from '../../store/competitions';

import {GenericResolve} from './router.resolve';

export const BASE_PROVIDERS = [
    InterActions,
    InterActionService,

    ApplicationActions,
    ApplicationService,
    BaseService,

    CanActivateViaLoggedInGuard,

    CommentActions,
    CommentService,

    CompetitionsActions,
    CompetitionsService,

    FlowActions,
    FlowService,

    GenericResolve,

    InterviewsActions,
    InterviewsService,

    MessagesActions,
    MessagesService,

    NewsActions,
    NewsService,

    PageActions,
    PageService,

    PhotoService,
    PhotosService,
    PhotosActions,

    ProfileActions,
    ProfileService,

    PhotographersService,
    PhotographersActions,

    Title,

    UserService,
    UserActions,


];
