import {Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {FormGroup, FormBuilder} from '@angular/forms';
import {photos} from '../../store/photos';
import {InterActions} from '../../store/inter';



import {user} from '../../store/user';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit, OnDestroy {
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    error: boolean;
    subscription: any;
    form: FormGroup;
    loading: false;
    loggedIn = false;
    errorNotEnough: boolean = false;

    constructor(public userActions: UserActions,
                public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                public formBuilder: FormBuilder,
                public interActions: InterActions,
                public redux: NgRedux<any>) {

        this.form = formBuilder.group({
            email: [''],
            password: [''],
            agree: ['']
        });
    }

    ngOnInit() {
        this.redux.dispatch(<any>this.userActions.set({registered: false, emailConfirmed: false, success: false, accountconfirmed: false}));
        this.subscription = this.user.subscribe(use => {
            if (!this.error && use.registered && !use.accountconfirmed) {
                this.redux.dispatch(<any>this.app.set({overlay: 'confirmaccount'}));
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    login() {
        this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
    }

    forgotPassword() {
        this.redux.dispatch(<any>this.app.set({overlay: 'forgotpassword'}));
    }

    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }


    submit(form) {
        const register = Object.assign({}, form.value, {tokenTime:  Date.now()});

        if (form.value.password.length < 8) {
            this.errorNotEnough = true;
            return;
        }

        if (form.status !== 'INVALID') {
            this.redux.dispatch(<any>this.userActions.register(register));
        } else {
            this.error = true;
        }
    }
}
