import {
    ApplicationRef,
    CUSTOM_ELEMENTS_SCHEMA,
    LOCALE_ID,
    NgModule,
    Injector
} from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserModule} from '@angular/platform-browser';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {NgReduxModule, NgRedux} from '@angular-redux/store';
import {enhancer, middleware} from './store';
import rootReducerPlatform from './store/root-reducer.platform';
import {MatomoModule} from 'ngx-matomo';

import {ROUTER_DECLARATIONS, ROUTES} from './app.routes';

import {registerLocaleData, LocationStrategy, HashLocationStrategy} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {appInitializerFactory} from './app-initializer.factory';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}

import {environment} from '../environments/environment';
import { NotifierModule } from 'angular-notifier';

registerLocaleData(localeFr, 'en');


import {NgReduxRouterModule, NgReduxRouter, routerReducer} from '@angular-redux/router';

import {optimistic} from 'redux-optimistic-ui';
import {ScrollToService} from 'ng2-scroll-to-el';
import {AppState} from './state';
import {BASE_PROVIDERS} from './shared/base/providers';

import {
    SocialLoginModule,
    AuthServiceConfig,
    GoogleLoginProvider,
    FacebookLoginProvider,
} from 'angular5-social-login';

import {AppComponent} from './app.component';
import {SharedComponentsModule} from './shared.components.module';
import {SharedCompoundsModule} from './shared.compounds.module';
import {SharedDirectivesModule} from './shared.directives.module';
import {SharedServicesModule} from './shared.services.module';


import {NocontentComponent} from './containers/nocontent/nocontent.component';
import {LanguageComponent} from './components/language/language.component';
import {NgMasonryGridModule} from 'ng-masonry-grid';
import {AsyncLocalStorageModule} from 'angular-async-local-storage';


export function getAuthServiceConfigs() {
    const config = new AuthServiceConfig(
        [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider('498054613551387')
            },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider('DT17ATUoVk4cEzVS9neQQFa_')
                // provider: new GoogleLoginProvider('202246381252-4a657ocmoftgu51g2p2khnm1qaj57vc4.apps.googleusercontent.com')
            },
        ]
    );
    return config;
}


@NgModule({
    imports: [
        SharedDirectivesModule,
        AsyncLocalStorageModule,
        BrowserAnimationsModule,
        BrowserModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
        HttpClientModule,
        // HttpModule,
        MatomoModule,
        NgMasonryGridModule,
        NgReduxModule,
        NgReduxRouterModule.forRoot(),
        NotifierModule,

        RouterModule.forRoot(ROUTES, {
            useHash: false,
            preloadingStrategy: PreloadAllModules,
            paramsInheritanceStrategy: 'always',
            scrollPositionRestoration: 'enabled'
        }),
        SharedComponentsModule,
        SharedCompoundsModule,

        SharedServicesModule.forRoot(),
        SocialLoginModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        })
    ],
    declarations: [
        AppComponent,
        NocontentComponent,
        LanguageComponent
    ],
    providers: [
        AppState,
        {provide: LOCALE_ID, useValue: 'en', useFactory: appInitializerFactory, deps: [TranslateService, Injector]},
        ScrollToService,

        ...BASE_PROVIDERS,
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        }
    ],
    bootstrap: [
        AppComponent,
        ...ROUTER_DECLARATIONS
    ]
})
export class AppModule {
    /* constructor(ngRedux: NgRedux<AppState>) {

      ngRedux.configureStore(rootReducerPlatform,{});
    }*/
    constructor(private ngRedux: NgRedux<any>,
                public appRef: ApplicationRef,
                public appState: AppState,
                private ngReduxRouter: NgReduxRouter) {
        ngRedux.configureStore(optimistic(rootReducerPlatform), {}, middleware, enhancer);
        // this.ngReduxRouter.initialize();
        // this.storeUtils.initialize(this.ngRedux);

    }
}



