
export const PHOTOGRAPHERS_FOLLOW = 'PHOTOGRAPHERS_FOLLOW';
export const PHOTOGRAPHERS_FOLLOW_SUCCESS = 'PHOTOGRAPHERS_FOLLOW_SUCCESS';
export const PHOTOGRAPHERS_FOLLOW_ERROR = 'PHOTOGRAPHERS_FOLLOW_ERROR';

export const PHOTOGRAPHERS_GET = 'PHOTOGRAPHERS_GET';
export const PHOTOGRAPHERS_GET_SUCCESS = 'PHOTOGRAPHERS_GET_SUCCESS';
export const PHOTOGRAPHERS_GET_ERROR = 'PHOTOGRAPHERS_GET_ERROR';

export const PHOTOGRAPHERS_UN_FOLLOW = 'PHOTOGRAPHERS_UN_FOLLOW';
export const PHOTOGRAPHERS_UN_FOLLOW_SUCCESS = 'PHOTOGRAPHERS_UN_FOLLOW_SUCCESS';
export const PHOTOGRAPHERS_UN_FOLLOW_ERROR = 'PHOTOGRAPHERS_UN_FOLLOW_ERROR';

export const PHOTOGRAPHERS_SET_FILTER = 'PHOTOGRAPHERS_SET_FILTER';

export const PHOTOGRAPHERS_SEARCH = 'PHOTOGRAPHERS_SEARCH';
export const PHOTOGRAPHERS_SEARCH_SUCCESS = 'PHOTOGRAPHERS_SEARCH_SUCCESS';
export const PHOTOGRAPHERS_SEARCH_ERROR = 'PHOTOGRAPHERS_SEARCH_ERROR';

