import {messages} from '../initials';

import {
    GET_MESSAGES,
    GET_MESSAGES_ERROR,
    GET_MESSAGES_SUCCESS,
    GET_NEW_MESSAGES,
    GET_NEW_MESSAGES_ERROR,
    GET_NEW_MESSAGES_SUCCESS,
    GET_OLD_MESSAGES,
    GET_OLD_MESSAGES_ERROR,
    GET_OLD_MESSAGES_SUCCESS,
    MARK_MESSAGE_AS_READ,
    MARK_MESSAGE_AS_READ_ERROR,
    MARK_MESSAGE_AS_READ_SUCCESS,
    MARK_ALL_MESSAGE_AS_READ,
    MARK_ALL_MESSAGE_AS_READ_ERROR,
    MARK_ALL_MESSAGE_AS_READ_SUCCESS,
} from './constants';


export default (state = messages, action: any) => {
    const {list} = state;

    switch (action.type) {
        case GET_MESSAGES:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case GET_MESSAGES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case GET_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });
            
        case GET_NEW_MESSAGES:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case GET_NEW_MESSAGES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
           
        case GET_NEW_MESSAGES_SUCCESS:
            if (list){
                let newList = state.list;
                const latestList = action.payload.list;

                latestList.forEach(value => {
                    newList.unshift(value);
                });
                return Object.assign({}, state, {
                    error: false,
                    loading: false,
                    list: newList
                });
            }
           

        case GET_OLD_MESSAGES:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
           
        case GET_OLD_MESSAGES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
           
        case GET_OLD_MESSAGES_SUCCESS:
            if (list){
                let newList = state.list;
                const latestList = action.payload.list;

                latestList.forEach(value => {
                    newList.push(value);
                });
                return Object.assign({}, state, {
                    error: false,
                    loading: false,
                    list: newList
                });
            }
           
        case MARK_MESSAGE_AS_READ:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
           
        case MARK_MESSAGE_AS_READ_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case MARK_MESSAGE_AS_READ_SUCCESS:

            if (list){
                let newList = [];
                list.forEach(value => {

                    if (value.id === action.data.message_id) {
                        value.seen_by_user = "1";

                    }
                    newList.push(value);
                });
                return Object.assign({}, state, {
                    error: false,
                    loading: false,
                    list: newList
                });
            }

            return Object.assign({}, state, {
                error: false,
                loading: true
            });
           

        case MARK_ALL_MESSAGE_AS_READ:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
           

        case MARK_ALL_MESSAGE_AS_READ_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
           

        case MARK_ALL_MESSAGE_AS_READ_SUCCESS:
            if (list){
                let newList = [];
                list.forEach(value => {
                    value.seen_by_user = "1";
                    newList.push(value);
                });

                return Object.assign({}, state, {
                    error: false,
                    loading: false,
                    list: newList
                });
            }
           
        default:
            return state;
    }
};
