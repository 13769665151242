import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ToggleFullscreenDirective} from './directives/screenfull.directive';
import {PollingDirective} from './directives/polling';

const list = [];

@NgModule({
    imports: [CommonModule],
    declarations: [
        ToggleFullscreenDirective,
        PollingDirective],
    exports: [ToggleFullscreenDirective,
        PollingDirective]

})

export class SharedDirectivesModule {
}
