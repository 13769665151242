import {Component, OnInit, Input, OnDestroy, ChangeDetectorRef, OnChanges} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {ApplicationActions} from '../../store/application';
import {PhotosActions, photos, viewType} from '../../store/photos';


import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
@Component({
    selector: 'photo-list',
    templateUrl: './photolist.component.html',
    styleUrls: ['./photolist.component.scss']
})
export class PhotoListComponent implements OnInit, OnDestroy, OnChanges {
    @select(photos) photoObserver: Observable<any>;
    @Input() photos: any;
    @Input() ForceViewType = false;
    @Input() curator: any;
    @select(viewType) viewTypes: Observable<any>;

    viewType: string;
    subscription: any;

    constructor(public redux: NgRedux<any>,
                    public photosActions: PhotosActions,
                    private applicationActions: ApplicationActions,
                    public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.setViewType();
    }

    ngOnChanges() {
        this.setViewType();
    }

    setViewType() {
        if (!this.ForceViewType) {
            this.subscription = this.photoObserver.subscribe(item => {
                if (!item.error && item.photos) {
                    this.viewType = item.viewType;
                    this.cd.markForCheck();
                }
            });
        } else {
            this.viewType = '900';
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    photoAction () {
    }
}
