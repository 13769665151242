import { ensureState } from 'redux-optimistic-ui';

export const comment = s => ensureState(s).comment;

// const test = ensureState().comment.get('overl')
export const debug = s => ensureState(s).comment.get('debug');

export const loading = s => ensureState(s).comment.get('loading');
// export const overlay = s => ensureState(s).comment.get('overlay');
// export const overlay = ensureState().comment.get('overlay');

export const rawError = s => ensureState(s).comment.get('rawError');
export const shownPhoto = s => ensureState(s).comment.get('shownPhoto');


export const reportSent = s => ensureState(s).comment.get('reportSent');
