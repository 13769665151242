import {Injectable} from '@angular/core';

import 'rxjs/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/shareReplay';
import {HttpHeaders} from '@angular/common/http';
import {MatomoTracker} from 'ngx-matomo';
import {NgRedux} from '@angular-redux/store';

import {
    ApplicationActions
} from '../store/application';


@Injectable()
export class LocationService {
    public previousPage = '';

    constructor(private applicationActions: ApplicationActions,
                private matomoTracker: MatomoTracker,
                public redux: NgRedux<any>) {

    }

    trackPage(page: string): void {
        this.matomoTracker.setUserId('1');
        this.matomoTracker.setDocumentTitle(page);
        this.matomoTracker.trackPageView(page);

    }

    setPreviousPage(page: string): void {
        this.matomoTracker.setUserId('1');
        this.matomoTracker.setDocumentTitle(page);
        this.matomoTracker.trackPageView(page);

        // console.log('tracking', 'https://world-street.photography' + page)
        this.redux.dispatch(<any>this.applicationActions.set({url: page}));

        // this.matomoTracker.setUserId('3');
        // this.matomoTracker.setDocumentTitle(page);
        // this.matomoTracker.trackEvent('category', page, 'name');
        // console.log('set previous page:', page);
        // console.log(page)
        this.previousPage = page;
    }

    getPreviuosPage() {
        return this.previousPage;
    }
}
