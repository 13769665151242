import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PhotographerInterfaace} from '../../store/photographers/interface';
import {TranslateService} from '@ngx-translate/core';

import {
    ActivatedRoute,
    Router
} from '@angular/router';

import {DEFAULT_IMAGE} from '../../config/constants';

@Component({
    selector: 'photographer',
    templateUrl: './photographer.component.html',
    styleUrls: ['./photographer.component.scss'],
      // encapsulation: ViewEncapsulation.Native
})
export class PhotographerComponent implements OnInit {
    @Input() photographer: PhotographerInterfaace;
    @Input() viewType: string;
    @Input() size = '';
    defaultImage = DEFAULT_IMAGE;

    routeSubscription: any;

    constructor(private router: Router,
                public translate: TranslateService,
                public route: ActivatedRoute) {
    }

    ngOnInit() {
        // console.log('this.photographer', this.photographer);
    }

    typeChecker(thumbnails: string) {
        if (this.viewType === 'list') {
            return thumbnails.replace('thumbnails', '900');
        }
        return thumbnails;
    }

    // (click)="goToUrl('/' + translate.currentLang + (params['id'] !== 1 ? '/selected-photographer/' : '/interview/') + userLogin)"
    goToProfile(photographer) {
        this.routeSubscription = this.route.params.subscribe(params => {
            let url = 'profile';
            if (params['id'] === 'selected') {
                url = params['id'] === 'selected' ? '/selected-photographer/' : '/interview/';
            }

            window.scrollTo(0, 0);
            this.router.navigate([ this.translate.currentLang + '/' + url + '/', photographer.user_login]);
        });
    }

    makeImage(profileImage) {
        return 'https://www.kujaja.com' + profileImage + '.jpg';
    }

    noGo() {
        return false;
    }
}
