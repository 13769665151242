import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
    Input
} from '@angular/core';

@Component({
    selector: 'icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
    @Output() iconAction = new EventEmitter<any>();
    @Input() iconType: string;
    @Input() iconClass: string;

    constructor() {
    }

    iconEmit(action) {
         this.iconAction.emit(action);
     }
}
