import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';
import {URLGETDATA, URL_PHOTOS, URL_PHOTOS_PROFILE} from '../../config/constants';


@Injectable()
export class PhotosService {
    constructor(private base: BaseService) {
    }

    vote(data) {
        return this.base.postWithToken(URLGETDATA + 'vote', data, data);
    }

    countPhotoView(data) {
        return this.base.getHttp(URLGETDATA + 'countPhotoVisit/' + data.image_id, '', data);
    }

    addtofavourites(data) {
        return this.base.postWithToken(URLGETDATA + 'addtofavourites', data, data);
    }

    removeFromfavourites(data) {
        return this.base.postWithToken(URLGETDATA + 'removefavourites', data, data);
    }

    getFavesAndVotes(data) {
        return this.base.postWithToken(URLGETDATA + 'getFavesAndVotes', data, data);
    }

    getInfo(data) {
        return this.base.getHttp(URLGETDATA + 'photoinfo', data, data);
    }

    getPhotos(data) {
        return this.base.getHttp(URL_PHOTOS + data.url, data.query ? data.query : '', data);
    }

    getRandomGalleryPhotos(data) {
            return this.base.getHttp(URL_PHOTOS + data.url, data.query ? data.query : '', data);
    }

    getPhotosProfile(data) {
        return this.base.getHttp(URL_PHOTOS_PROFILE + data.url, '', data);
    }

    getPhotosCoompetition(data) {
        return this.base.getHttp(URLGETDATA + 'competitions' + data, '', data);
    }

    getWinningPhotosCompetition(data) {
        return this.base.getHttp(URLGETDATA + 'competitions' + data, '', data);
    }
}
