import {Component, OnChanges, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {application, ApplicationActions} from '../../store/application';
import {comment} from '../../store/comments';


import {
    ActivatedRoute,
    Router,
    RouterModule
} from '@angular/router';

@Component({
    selector: 'photoInformation',
    templateUrl: './photo.information.component.html',
    styleUrls: ['./photo.information.component.scss']
})
export class PhotoInformationComponent implements OnInit, OnDestroy {
    @select(application) application: Observable<any>;
    @select(comment) comment: Observable<any>;
    show: boolean = false;
    photoInformationSubscribtion: any;
    applicationSubscribtion: any;
    photo: any;
    keywords: any;
    description: SafeHtml;
    textColor: string = '#000000';
    showInfo: boolean = false;
    loading: boolean = false;
    copyright: any;
    didInformationOnce: boolean = false;
    competitionsAvailable: boolean = false;
    competitions: any;
    countDone: boolean = false;
    otherUploads: boolean = false;
    aiKeywords = [];

    mapStyle = [{
        "featureType": "water",
        "stylers": [{
            "weight": 0.1
        }, {
            "color": "#ffffff"
        }]
    }, {
        "featureType": "landscape",
        "stylers": [{
            "color": "#bbbbbb"
        }]
    }, {
        "featureType": "transit",
        "stylers": [{
            "color": "#979797"
        }]
    }, {
        "featureType": "road",
        "stylers": [{
            "color": "#454545"
        }]
    }, {
        "featureType": "landscape.natural.terrain"
    }, {
        "featureType": "poi.park",
        "stylers": [{
            "color": "#979797"
        }]
    }, {}];

    constructor(public applicationActions: ApplicationActions,
                public cd: ChangeDetectorRef,
                private domSanitizer: DomSanitizer,
                public redux: NgRedux<any>,
                public route: ActivatedRoute,
                public router: Router) {
    }

    ngOnInit() {
        this.showInformation();
    }

    ngOnDestroy() {
        this.countDone = false;
        if (this.photoInformationSubscribtion) {
            this.photoInformationSubscribtion.unsubscribe();
        }
        if (this.applicationSubscribtion) {
            this.applicationSubscribtion.unsubscribe();
        }
    }

    toggleInformation() {
        this.showInfo = !this.showInfo;
        this.redux.dispatch(<any>this.applicationActions.set({'showInformation': this.showInfo}));
    }

    resetInformation() {
        this.competitions = null;
        this.copyright = null;
        this.competitionsAvailable = false;
        this.loading = false;
        this.description = '';
        this.otherUploads = false;
        if (this.photo) {
            this.photo.image_camera = '';
            this.photo.image_focal = '';
            this.photo.image_shutter = '';
            this.photo.image_aperture = '';
            this.photo.image_iso = '';
            this.photo.image_distance = '';
            this.photo.user_login = '';
            this.photo.image_youtube = '';
        }
        this.competitions = false;
        this.photo = null;
        this.aiKeywords = null;
        this.cd.markForCheck();
    }

    showInformation() {
        this.resetInformation();
        this.photoInformationSubscribtion = this.comment.subscribe(item => {

            this.loading = item.loading;
            if (item.photo && !item.loading) {
                this.keywords = item.keywords;
                this.photo = item.photo;
                this.textColor = (item.photo.image_background === '#000000' ? 'white' : 'black');

                this.description = ( item.photo.image_text ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_text.replace(/(?:\r\n|\r|\n)/g, '<br />')) : '');

                this.photo.image_camera = ((this.photo.image_camera !== item.photo.image_camera) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_camera) : '');
                this.photo.image_focal = ((this.photo.image_focal !== item.photo.image_focal) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_focal) : '');
                this.photo.image_shutter = ((this.photo.image_shutter !== item.photo.image_shutter) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_shutter) : '');
                this.photo.image_aperture = ((this.photo.image_aperture !== item.photo.image_aperture) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_aperture) : '');
                this.photo.image_iso = ((this.photo.image_iso !== item.photo.image_iso) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_iso) : '');
                this.photo.image_distance = ((this.photo.image_distance !== item.photo.image_distance) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.image_distance) : '');
                this.photo.user_login = ((this.photo.user_login !== item.photo.user_login) ? this.domSanitizer.bypassSecurityTrustResourceUrl(item.photo.user_login) : '');
                this.copyright = ((this.photo.user_firstname !== item.photo.user_firstname) ? this.domSanitizer.bypassSecurityTrustHtml(item.photo.user_firstname + ' ' + item.photo.user_lastname) : '');

                this.photo.image_visits = this.photo.image_external_visits + this.photo.image_visits;

                let tempVideo = this.photo.image_youtube;
                if (tempVideo && tempVideo !== '' && tempVideo !== undefined) {
                    if (typeof(tempVideo) === 'string') {
                        tempVideo = tempVideo.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                        this.photo.image_youtube = this.domSanitizer.bypassSecurityTrustResourceUrl(tempVideo + '?rel=0&autoplay=1&mute=0'); // + '?autoplay=1'
                    }
                }

                this.loading = false;
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 0);
            }
        });

        this.applicationSubscribtion = this.application.subscribe(value => {
            if (value.showInformation) {
                this.showInfo = value.showInformation;
            }
        });
    }
}