import {
    RouterModule,
    Routes
} from '@angular/router';

import {NocontentComponent} from './containers/nocontent/nocontent.component';
import {LanguageComponent} from './components/language/language.component';

export const ROUTES: Routes = [
    {path: '', redirectTo: 'en', pathMatch: 'full'},
    {
        path: ':home_language', component: LanguageComponent, children: [
        {
            path: '',
            loadChildren: './containers/home#HomeModule'
        },
        {
            path: 'register',
            loadChildren: './containers/home#HomeModule'
        },
        {
            path: 'login',
            loadChildren: './containers/home#HomeModule'
        },
        {
            path: 'forgot',
            loadChildren: './containers/home#HomeModule'
        },
        {
            path: 'resetpassword',
            loadChildren: './containers/home#HomeModule'
        },
        {
            path: 'photo-competitions',
            loadChildren: './containers/competitions#CompetitionsModule'
        },
        {
            path: 'photobooks',
            loadChildren: './containers/photobooks#PhotobooksModule'
        },
        {
            path: 'faq',
            loadChildren: './containers/faq#FaqModule'
        },
        {
            path: 'interview',
            loadChildren: './containers/interview#InterviewModule'
        },
        {
            path: 'selected-photographer',
            loadChildren: './containers/interview#InterviewModule'
        },

        {
            path: 'pages',
            loadChildren: './containers/pages#PagesModule'
        },
        {
            path: 'photos',
            loadChildren: './containers/photos#PhotosModule'
        },
        {
            path: 'flow',
            loadChildren: './containers/flow#FlowModule'
        },
        {
            path: 'photo',
            loadChildren: './containers/photo#PhotoModule'
        },
        {
            path: 'photographers',
            loadChildren: './containers/photographers#PhotographersModule'
        },
        {
            path: 'organizer',
            loadChildren: './containers/organizer#OrganizerModule'
        },
        {
            path: 'myaccount',
            loadChildren: './containers/myaccount#MyAccountModule'
        },
        {
            path: 'myblogs',
            loadChildren: './containers/myblogs#MyBlogsModule'
        },
        {
            path: 'talk',
            loadChildren: './containers/talk#TalkModule'
        },
        {
            path: 'notifications',
            loadChildren: './containers/mynotifications#MyNotificationsModule'
        },
        {
            path: 'myprojects',
            loadChildren: './containers/myprojects#MyProjectsModule'
        },
        /*{
            path: 'en/photo/',
            component: OverlayComponent,
            outlet: 'modal'
        },
        {
            path: 'en/login',
            component: LoginComponent,
            outlet: 'modal'
        },*/
        {
            path: 'profile',
            loadChildren: './containers/profile#ProfileModule'
        },
        {
            path: ':home_language/:id',
            // redirectTo: ':home_language/profile/:id'
            loadChildren: './containers/profile#ProfileModule'
        }]
    }/*,
    {
        path: '**',
        component: NocontentComponent
    }*/
];

export const ROUTER_DECLARATIONS = [];
