import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';

import {
    URL_GET_INTERVIEW_QUESTIONS,
    URL_SAVE_INTERVIEW_QUESTIONS,
    URL_INTERVIEW,
    URL_ONE_SELLECT_PHOTOGRAPHER
} from '../../config/constants';


@Injectable()
export class InterviewsService {
    constructor(private base: BaseService) {
    }

    getOnePhotographer() {
        return this.base.getHttp(URL_ONE_SELLECT_PHOTOGRAPHER , '', '');
    }

    getInterview(data) {
        return this.base.getHttp(URL_INTERVIEW + '/', data, data);
    }

    getInterviewPreview(data) {
        return this.base.getHttp(URL_INTERVIEW + '/preview', data, data);
    }

    getRandomInterviewPreview(data) {
        return this.base.getHttp(URL_INTERVIEW + 'random', data, data);
    }

    getLatestInterviewPreview(data) {
        return this.base.getHttp(URL_INTERVIEW + 'latest', data, data);
    }

    getYourLatestInterviews(data) {
        return this.base.postWithToken(URL_INTERVIEW + data, null, null);
    }

    get(data) {
        return this.base.getHttp(URL_INTERVIEW, data, data);
    }

    getInterviewQuestions() {
        return this.base.postWithToken(URL_GET_INTERVIEW_QUESTIONS, null, null);
    }

    saveInterviewQuestions(data) {
        return this.base.postWithToken(URL_SAVE_INTERVIEW_QUESTIONS, data, data);
    }
}
