import {Component, OnInit, OnChanges, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
import {user} from '../../../store/user';
import {messages, MessagesActions} from "../../../store/messages";
import {NotificationService} from '../../../services/notification/notification.service';
import {application} from '../../../store/application';


@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnChanges {
    @select(application) application: Observable<any>;
    @select(messages) messages: Observable<any>;
    @select(user) user: Observable<any>;

    active = false;
    config: any;
    firstTotal = 0;
    getMesssagesOnce = false;
    lastMessage = 0;
    list: any;
    loading: false;
    loggedInUser = false;
    oldMessage = 0;
    messageIcon: string;
    makeSound = false;
    prohibitSound = false;
    prohibitNotifications = false;
    show = false;
    showMessages = false;
    subscriptionUser: any;
    subscriptionMessages: any;
    subscriptionNewMessages: any;
    subscribtionApplication: any;
    total = 0;

    constructor(public redux: NgRedux<any>,
                public cd: ChangeDetectorRef,
                public messagesActions: MessagesActions,
                public notificationService: NotificationService,
                public router: Router) {
        if (localStorage.hasOwnProperty('prohibitNotifications')) {
            this.prohibitNotifications = (localStorage.getItem('prohibitNotifications') === 'true');
        }

        if (localStorage.hasOwnProperty('prohibitSound')) {
            this.prohibitSound = (localStorage.getItem('prohibitSound') === 'true');
        }
    }

    ngOnInit() {
        this.subscriptionUser = this.user.subscribe(use => {
            if (use.isLoggedIn && use.tokenTime !== '') {
                this.show = (use.profile !== '');
                this.loggedInUser = use;
                if (!this.getMesssagesOnce) {
                    this.redux.dispatch(<any>this.messagesActions.getMessages({tokenTime: Date.now()}));
                    this.getMesssagesOnce = true;
                }
                this.cd.markForCheck();
            }
            else {
                this.show = false;
                this.cd.markForCheck();
            }
        });

        this.subscribtionApplication = this.application.subscribe(app => {
            if (app.config) {
                this.messageIcon = app.config.messageIcon
            }
        });

        this.subscriptionMessages = this.messages.subscribe(message => {
            this.loading = message.loading;

            if (message.list && message.list.length > 0) {
                this.list = message.list;
                this.total = 0;
                this.list.find(item => {
                    this.lastMessage = item.id;
                    if (item.seen_by_user === 0) {
                        this.total++;
                        this.firstTotal = this.total;
                        this.active = true;
                        this.cd.markForCheck();
                    }
                });

                this.cd.markForCheck();
                this.getNewMessages();
                this.makeSound = true;
            } else {
                this.total = 0;
            }
            this.cd.markForCheck();
        });
    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    getNewMessages() {
        if (this.subscriptionMessages) {
            this.subscriptionMessages.unsubscribe();
        }

        this.subscriptionNewMessages = this.messages.subscribe(message => {
            if (message.list && message.list.length > 0) {
                this.list = message.list;
                this.total = 0;
                let id = 0;

                let ItemNotify = null;

                this.list.find(item => {
                    if (id === 0) {
                        this.lastMessage = item.id;
                        id = item.id;
                        ItemNotify = item;
                    }
                    this.oldMessage = item.id;
                    if (item.seen_by_user === 0) {
                        this.total++;
                        this.active = true;
                        this.cd.markForCheck();
                    }
                });

                if (this.firstTotal !== this.total) {
                    if (this.firstTotal < this.total && this.notificationService.hasPermission() && !this.prohibitNotifications) {
                        if (this.total === 1) {
                            let body = '';

                            if (ItemNotify.image_title !== '') {
                                if (ItemNotify.image_title === 'undefined') {
                                    ItemNotify.image_title = 'untitled';
                                }
                                body = `${ItemNotify.user_firstname_action} ${ItemNotify.user_lastname_action} ${ItemNotify.name} your photo "${ItemNotify.book_title}" - ${ItemNotify.action_date} ${ItemNotify.action_id}`;
                            } else {
                                body = `${ItemNotify.user_firstname_action} ${ItemNotify.user_lastname_action} ${ItemNotify.name} "${ItemNotify.image_title}${ItemNotify.book_title}" - ${ItemNotify.action_date} ${ItemNotify.action_id}`;
                            }

                            this.notificationService.trigger({
                                title: 'World Street Photography',
                                tag: `messages_${this.lastMessage}`,
                                body: body,
                                url: this.getUrlFromMessage(ItemNotify),
                                icon: 'https://www.kujaja.com/photos/thumbnails/' + ItemNotify.thumbnail
                            }, 'messages');
                        }

                        if (this.total > 1) {
                            this.notificationService.trigger({
                                title: 'World Street Photography',
                                tag: `message_${this.lastMessage}_${this.total}`,
                                body: `You have ${this.total} new messages`,
                                icon: 'https://www.kujaja.com/assets/img/' + this.messageIcon
                            }, 'messages');
                        }
                    }
                    this.notifySound();
                    this.firstTotal = this.total;
                }

                this.cd.markForCheck();
            } else {
                this.total = 0;
            }
        });
    }

    toggleSound() {
        this.prohibitSound = !this.prohibitSound;
        localStorage.setItem('prohibitSound',this.prohibitSound.toString());
        this.cd.markForCheck();
        return this.prohibitSound;
    }

    toggleNotifications() {
        this.prohibitNotifications = !this.prohibitNotifications;
        localStorage.setItem('prohibitNotifications',this.prohibitNotifications.toString());
        this.notificationService.requestPermission();
        this.cd.markForCheck();
        return this.prohibitNotifications;
    }

    notifySound() {
        if (this.makeSound && !this.prohibitSound) {
            let audio = new Audio();
            audio.src = "/assets/sounds/166500__thompsonman__camera-shutter.mp3";
            audio.load();
            audio.play();
        }
    }

    toggleMessages() {
        if (this.showMessages) {
            this.showMessages = false;
            this.active = false;
        } else {
            this.showMessages = true;
            this.active = true;
        }

        if (!this.notificationService.deniedPermission()) {
            this.notificationService.requestPermission();
        }
    }

    reduceCounter(item) {
        this.firstTotal = this.total - 1;
        this.total = this.total - 1;
        if (this.total <= 0 && !this.showMessages) {
            this.active = false;
        }
    }

    getUrlFromMessage(item) {
        let url = '';

        if (item.hasOwnProperty('book_id_out')) {
            let bookType = 'photobooks';

            switch (item.book_status) {
                /*case 4 || 5 || 9 || 10:
                    bookType = 'project';
                    break;
                case 6 || 7 || 8:
                    bookType = 'calendar';
                    break;*/
                case 19 || 20 || 24 || 25:
                    bookType = 'photo-competitions'
            }
            url = 'en/' + bookType + '/' + item.book_id_out + '/discussion';
        }

        if (item.image_id && item.image_id > 0) {
            url = '/en/photo/' + item.image_id + '/message';
        }

        if (item.action_id === 13 || item.action_id === 4) {
            const {user} = ensureState(this.redux.getState());
            url = '/en/profile/' + user.profile + '/comment';
        }

        if (item.action_id === 10) {
            url = '/en/profile/' + item.user_login_action + '/photos';
        }
        return url;
    }

    gotoMessage(item) {
        this.active = false;
        this.makeSound = false;
        this.reduceCounter(item);

        const url = this.getUrlFromMessage(item);

        this.redux.dispatch(<any>this.messagesActions.markMessageAsRead({message_id: item.id}));
        this.router.navigate([url]);
        setTimeout(() => {
            this.cd.markForCheck();
            this.makeSound = true;
            this.showMessages = false;
        }, 200);
        window.scrollTo(0, 0);
    }

    markAllAsRead() {
        // markAllmessageasreadAction
        this.redux.dispatch(<any>this.messagesActions.markAllmessageasreadAction());
    }

    ngOnDestroy() {
        if (this.subscriptionUser) {
            this.subscriptionUser.unsubscribe();
        }
        if (this.subscriptionMessages) {
            this.subscriptionMessages.unsubscribe();
        }
        if (this.subscriptionNewMessages) {
            this.subscriptionNewMessages.unsubscribe();
        }
        if (this.subscribtionApplication) {
            this.subscribtionApplication.unsubscribe();
        }
    }

    checkMessages($event) {
        if (this.lastMessage !== 0) {
            this.redux.dispatch(<any>this.messagesActions.getNewMessages({
                tokenTime: Date.now(),
                lastMessage: this.lastMessage
            }));
        }
    }

    getOldMessages($event) {
        if (this.oldMessage !== 0) {
            this.redux.dispatch(<any>this.messagesActions.getOldMessages({
                tokenTime: Date.now(),
                oldMessage: this.oldMessage
            }));
        }
    }
}
