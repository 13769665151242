import {photos} from '../initials';

import {
    PHOTO_VOTE,
    PHOTO_VOTE_SUCCESS,
    PHOTO_VOTE_ERROR,
    PHOTO_ADD_TO_FAV,
    PHOTO_ADD_TO_FAV_ERROR,
    PHOTO_ADD_TO_FAV_SUCCESS,
    PHOTO_GET_INFOS,
    PHOTO_GET_INFOS_ERROR,
    PHOTO_GET_INFOS_SUCCESS,
    PHOTOS_COMPETITION_GET,
    PHOTOS_COMPETITION_GET_ERROR,
    PHOTOS_COMPETITION_GET_SUCCESS,
    PHOTO_SET_VIEW_TYPE,
    PHOTOS_GET,
    PHOTOS_GET_ERROR,
    PHOTOS_GET_SUCCESS,
    PHOTOS_PROFILE_GET,
    PHOTOS_PROFILE_GET_ERROR,
    PHOTOS_PROFILE_GET_SUCCESS,
    PHOTOS_WINNER_COMPETITION_GET,
    PHOTOS_WINNER_COMPETITION_GET_ERROR,
    PHOTOS_WINNER_COMPETITION_GET_SUCCESS,
    PHOTO_GET_FAVS_AND_VOTES,
    PHOTO_GET_FAVS_AND_VOTES_ERROR,
    PHOTO_GET_FAVS_AND_VOTES_SUCCESS,
    REMOVE_PHOTOS,
    PHOTOS_SET
} from './constants';


export default (state = photos, action: any) => {
    switch (action.type) {

        case PHOTOS_SET:
            return Object.assign({}, state, {
                ...action.data
            });

        case REMOVE_PHOTOS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                list: [],
                voted: [],
                faved: [],
            });

        case PHOTO_VOTE:
            return Object.assign({}, state, {
                ...action.data
            });


        // return state.push(action.data);
        // console.log(action.data, action);
        // return action.data;
        case PHOTO_GET_INFOS:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case PHOTO_GET_INFOS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTO_GET_INFOS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });

        case PHOTOS_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTOS_PROFILE_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });

        case PHOTOS_PROFILE_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOS_PROFILE_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTO_SET_VIEW_TYPE:
            return Object.assign({}, state, {
                viewType: action.data
            });


        case PHOTOS_COMPETITION_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });

        case PHOTOS_COMPETITION_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOS_COMPETITION_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTOS_WINNER_COMPETITION_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });

        case PHOTOS_WINNER_COMPETITION_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOS_WINNER_COMPETITION_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTO_GET_FAVS_AND_VOTES:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case PHOTO_GET_FAVS_AND_VOTES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTO_GET_FAVS_AND_VOTES_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        default:
            return state;
    }
};
