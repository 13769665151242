import {fromJS} from 'immutable';
import {user} from '../initials';

import {
    USER_SET,
    USER_LOGIN_AS,
    USER_LOGIN_AS_ERROR,
    USER_LOGIN_AS_SUCCESS,
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_LOGOUT_SUCCESS,
    USER_FORGOT_PASSWORD,
    USER_FORGOT_PASSWORD_ERROR,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_FOLLOW, USER_FOLLOW_ERROR, USER_FOLLOW_SUCCESS,
    USER_UNFOLLOW, USER_UNFOLLOW_ERROR, USER_UNFOLLOW_SUCCESS,
    USER_RESET_PASSWORD,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_ERROR, USER_REGISTER_SUCCESS, USER_REGISTER_ERROR, USER_REGISTER, USER_CONFIRM_ACCOUNT,
    USER_CONFIRM_ACCOUNT_ERROR, USER_CONFIRM_ACCOUNT_SUCCESS, USER_RESEND_CONFIRMATION_EMAIL,
    USER_RESEND_CONFIRMATION_EMAIL_ERROR, USER_RESEND_CONFIRMATION_EMAIL_SUCCESS, USER_CONFIRM_ACTIVATION_CODE,
    USER_CONFIRM_ACTIVATION_CODE_ERROR, USER_CONFIRM_ACTIVATION_CODE_SUCCESS
} from './constants';

export default (state = user, action: any) => {
    switch (action.type) {
        case USER_SET:
            return Object.assign({}, state, {
                ...action.data
            });

        case USER_LOGIN:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                isLoggedIn: false
            });

        case USER_LOGIN_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                isLoggedIn: false
            });

        case USER_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                isLoggedIn: true,
                ...action.payload
            });
            
        case USER_CONFIRM_ACTIVATION_CODE:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_CONFIRM_ACTIVATION_CODE_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });
            
            
        case USER_CONFIRM_ACTIVATION_CODE_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

            
        case USER_RESEND_CONFIRMATION_EMAIL:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_RESEND_CONFIRMATION_EMAIL_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });
            
            
        case USER_RESEND_CONFIRMATION_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case USER_REGISTER:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_REGISTER_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_REGISTER_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });
           

        case USER_CONFIRM_ACCOUNT:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_CONFIRM_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                registered: false,
                accountconfirmed: false,
                isLoggedIn: false
            });

        case USER_CONFIRM_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                registered: true,
                accountconfirmed: true,
                ...action.payload
            });
            
            
        case USER_LOGOUT:
            return Object.assign({}, state, {
               loggedIn: false,
               role: null,
               following: null,
               userId: null,
               user: null,
               emailConfirmed: null,
               token: '',
               tokenTime: '',
               birthday:  '',
               logout: null,
               profile: null,
               profile_image: null
             });

           case USER_LOGOUT_SUCCESS:
               return Object.assign({}, state, {
                   error: false,
                   id: '',
                   isLoggedIn: false,
                   following: null,
                   loading: false,
                   loggedIn: false,
                   logout: 'done',
                   name: '',
                   profile: '',
                   profile_image: '',
                   role: null,
                   userId: null
                });
        case USER_FORGOT_PASSWORD:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                isLoggedIn: false
            });

        case USER_FORGOT_PASSWORD_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                isLoggedIn: false
            });

        case USER_FORGOT_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                isLoggedIn: false,
                ...action.payload
            });

        case USER_RESET_PASSWORD:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                isLoggedIn: false
            });

        case USER_RESET_PASSWORD_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                isLoggedIn: false
            });

        case USER_RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                isLoggedIn: false,
                ...action.payload
            });

        case USER_FOLLOW:
            return Object.assign({}, state, {
                error: false,
                loading: true            });

        case USER_FOLLOW_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case USER_FOLLOW_SUCCESS:

            let following = state.following;
            following.push(action.payload.following);
            state.following = following;

            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case USER_UNFOLLOW:
            return Object.assign({}, state, {
                error: false,
                loading: true            });

        case USER_UNFOLLOW_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case USER_UNFOLLOW_SUCCESS:
            let followings = state.following;
            const user_login = action.payload.unfollowing;
            const index = followings.indexOf(user_login, 0);

            if (index > -1) {
                followings.splice(index, 1);
            }
            state.following = followings;

            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case USER_LOGIN_AS:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case USER_LOGIN_AS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case USER_LOGIN_AS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        default:
            return state;
    }
};
