export const GET_INTERVIEW_QUESTIONS = 'GET_INTERVIEW_QUESTIONS';
export const GET_INTERVIEW_QUESTIONS_SUCCESS = 'GET_INTERVIEW_QUESTIONS_SUCCESS';
export const GET_INTERVIEW_QUESTIONS_ERROR = 'GET_INTERVIEW_QUESTIONS_ERROR';

export const SAVE_INTERVIEW_QUESTIONS = 'SAVE_INTERVIEW_QUESTIONS';
export const SAVE_INTERVIEW_QUESTIONS_SUCCESS = 'SAVE_INTERVIEW_QUESTIONS_SUCCESS';
export const SAVE_INTERVIEW_QUESTIONS_ERROR = 'SAVE_INTERVIEW_QUESTIONS_ERROR';

export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_INTERVIEW_SUCCESS = 'GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_ERROR = 'GET_INTERVIEW_ERROR';

export const GET_INTERVIEWS = 'GET_INTERVIEWS';
export const GET_INTERVIEWS_SUCCESS = 'GET_INTERVIEWS_SUCCESS';
export const GET_INTERVIEWS_ERROR = 'GET_INTERVIEWS_ERROR';


export const GET_ONE_PHOTOGRAPHER = 'GET_ONE_PHOTOGRAPHER';
export const GET_ONE_PHOTOGRAPHER_SUCCESS = 'GET_ONE_PHOTOGRAPHER_SUCCESS';
export const GET_ONE_PHOTOGRAPHER_ERROR = 'GET_ONE_PHOTOGRAPHER_ERROR';

