import {Injectable} from '@angular/core';

import {
    PHOTOGRAPHERS_UN_FOLLOW,
    PHOTOGRAPHERS_FOLLOW,
    PHOTOGRAPHERS_GET,
    PHOTOGRAPHERS_SET_FILTER,
    PHOTOGRAPHERS_SEARCH
} from './constants';

import {PhotographersService} from './service';

@Injectable()
export class PhotographersActions {
    constructor(private photographers: PhotographersService) {
    }

    get (data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOGRAPHERS_GET,
                isAsync: true,
                loading: true,
                promise: this.photographers.get(data)
            });
        };
    }

    search (data){
        return (dispatch) => {
            return dispatch({
                type: PHOTOGRAPHERS_SEARCH,
                isAsync: true,
                loading: true,
                promise: this.photographers.search(data)
            });
        };
    }

    follow (data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOGRAPHERS_FOLLOW,
                isAsync: true,
                loading: true,
                promise: this.photographers.follow(data)
            });
        };
    }

    unfollow (data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOGRAPHERS_UN_FOLLOW,
                isAsync: true,
                loading: true,
                promise: this.photographers.unfollow(data)
            });
        };
    }

    setFilter(data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: PHOTOGRAPHERS_SET_FILTER
            });
        };
    }
}
