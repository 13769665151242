import {
    Component,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    EventEmitter,
    ElementRef,
    Input,
    OnInit,
    Output,
    OnChanges, SimpleChanges, SimpleChange,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';

@Component({
    selector: 'photo-navigator',
    templateUrl: './photo.navigator.component.html',
    styleUrls: ['./photo.navigator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoNavigatorComponent implements OnChanges {
    @Input() photos: any;

    constructor(public router: Router,
                public route: ActivatedRoute,
                public cd: ChangeDetectorRef) {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.cd.markForCheck();
    }

    goToPageChild(index: number) {
        window.scrollTo(0, 0);
        this.cd.markForCheck();
        // this.router.navigate([this.PageURL, index + 1]);
    }

    previousPhoto() {

    }

    nextPhoto() {

    }
}
