import { Injectable } from '@angular/core';

import { BaseService } from '../../shared/base/services';

import {URL_PAGE_PHOTOS} from '../../config/constants';

@Injectable()
export class PageService {
  constructor(private base: BaseService) {}

    getPage(data) {
    return this.base.getHttp(URL_PAGE_PHOTOS, data, data);
  }
}
