import {comment} from '../initials';

import {
    GET_COMMENTS,
    GET_COMMENTS_ERROR,
    GET_COMMENTS_SUCCESS,
    GET_MORE_COMMENTS,
    GET_MORE_COMMENTS_ERROR,
    GET_MORE_COMMENTS_SUCCESS,
    POST_COMMENT_SUCCESS,
    POST_COMMENT_ERROR,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_ERROR,
    LIKE_COMMENT,
    LIKE_COMMENT_ERROR,
    LIKE_COMMENT_SUCCESS,
} from './constants';

export default (state = comment, action: any) => {
    switch (action.type) {

        case GET_COMMENTS:
            return Object.assign({}, state, {
                error: false,
                comments: [],
                loading: true
            });

        case GET_COMMENTS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case GET_COMMENTS_ERROR:
            return Object.assign({}, state, {
                error: true,
                comments: [],
                loading: false,
                ...action.payload
            });

        case GET_MORE_COMMENTS:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case GET_MORE_COMMENTS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                moreComments: action.payload.comments,
                more: action.payload.more
            });

        case GET_MORE_COMMENTS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });


        case POST_COMMENT_SUCCESS:
            let newComments = [];

            if (action.data.parentid === 0) {
                newComments = state.comments;
                newComments.unshift(action.payload.comments[0]);
            } else {
                const comments = state.comments;
                comments.forEach((item: any) => {
                    newComments.push(item);
                    if (item.id === action.data.parentid) {
                        newComments.push(action.payload.comments[0]);
                    }
                });
            }

            return Object.assign({}, state, {
                error: false,
                loading: false,
                comments: newComments
            });

        case POST_COMMENT_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });

        case DELETE_COMMENT_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case DELETE_COMMENT_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });


        case LIKE_COMMENT:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case LIKE_COMMENT_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case LIKE_COMMENT_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });


        default:
            return state;
    }
};
