import {Injectable} from '@angular/core';
import {BaseService} from '../../shared/base/services';

import {
    URL_PROFILE,
    URL_PROFILE_ABOUT,
    URL_PROFILE_COMMENTS,
    URL_PROFILE_FAVOURITES,
    URL_PROFILE_FOLLOW,
    URL_PROFILE_FOLLOWERS,
    URL_PROFILE_FOLLOWING,
    URL_PROFILE_LIKE,
    URL_PROFILE_RECOMMEND,
    URL_PROFILE_UNFOLLOW,
} from '../../config/constants';


@Injectable()
export class ProfileService {
    constructor(private base: BaseService) {
    }

    getProfile(data) {
        return this.base.getHttp(URL_PROFILE + '/' + data.url, '', data);
    }

    like(data) {
        return this.base.post(URL_PROFILE_LIKE + '/' + data, data, data);
    }

    follow(data) {
        return this.base.post(URL_PROFILE_FOLLOW + '/' + data, data, data);
    }

    unFollow(data) {
        return this.base.post(URL_PROFILE_UNFOLLOW + '/' + data, '', data);
    }

    recommend(data) {
        return this.base.post(URL_PROFILE_RECOMMEND + '/' + data, data, data);
    }

    profilePhotos(data) {
        return this.base.getHttp(URL_PROFILE + '/' + data, '', data);
    }

    profileFavourites(data) {
        return this.base.getHttp(URL_PROFILE_FAVOURITES + '/' + data, '', data);
    }

    profileFollowing(data) {
        return this.base.getHttp(URL_PROFILE + '/' + data, '', data);
    }

    profileFollowers(data) {
        return this.base.getHttp(URL_PROFILE + '/' + data, '', data);
    }

    profileAbout(data) {
        return this.base.getHttp(URL_PROFILE_ABOUT + '/' + data, '', data);
    }
}
