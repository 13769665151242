const optimisticUI = require('redux-optimistic-ui');
const {BEGIN, COMMIT, REVERT} = optimisticUI;

let nextTransactionID = 0;

export function optimistic() {
    return next => action => {
        const {isAsync, isOptimistic} = action;

        if (!isAsync) {
            return next(action);
        }

        const {type, data, promise} = action;

        const id = ++nextTransactionID;

        next(Object.assign({}, action, {
            meta: isOptimistic ? {optimistic: {type: BEGIN, id}} : {},
            data: data || {}
        }));

        promise.then(
            result => {
                next({
                    type: `${type}_SUCCESS`,
                    payload: result,
                    data: data || {},
                    meta: isOptimistic ? {optimistic: {type: COMMIT, id}} : {}
                });
            },
            error => {
                next({
                    type: `${type}_ERROR`,
                    payload: 'error',
                    data: data || {},
                    meta: isOptimistic ? {optimistic: {type: COMMIT, id}} : {}
                });
                /*
                next({
                    type: 'APPLICATION_SET',
                    data: Object.assign({loading: false}, error)
                });
                window.setTimeout(() => {
                    next({
                        type: `${type}_ERROR`,
                        payload: error,
                        data: data || {},
                        meta: isOptimistic ? {optimistic: {type: REVERT, id}} : {}
                    });
                }, 100); */
            }
        );
    };
}
