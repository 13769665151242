import {fromJS} from 'immutable';
import {pages} from '../initials';

import {
    GET_PAGE,
    GET_PAGE_ERROR,
    GET_PAGE_SUCCESS
} from './constants';

export default (state = pages, action: any) => {
    switch (action.type) {
        case GET_PAGE:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                ...action.payload
            });

        case GET_PAGE_SUCCESS:
            const pages =  Object.assign({}, state, {
                error: false,
                loading: false,
                page: action.payload.page,
                title: action.payload.title
            });
            // console.log(pages);
            return pages;

        case GET_PAGE_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });
        default:
            return state;
    }
};
