import {Injectable} from '@angular/core';

import {
    GET_COMMENTS,
    GET_MORE_COMMENTS,
    POST_COMMENT,
    DELETE_COMMENT,
    LIKE_COMMENT
} from './constants';

import {CommentService} from './service';

@Injectable()
export class CommentActions {
    constructor(private comment: CommentService) {
    }

    getComments(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_COMMENTS,
                isAsync: true,
                loading: true,
                comments: [],
                competitions: [],
                error: false,
                promise: this.comment.getComments(data)
            });
        };
    }

    getMoreComments(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_MORE_COMMENTS,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.comment.getMoreComments(data)
            });
        };
    }


    getCommentsWithToken(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_COMMENTS,
                isAsync: true,
                loading: true,
                comments: [],
                competitions: [],
                error: false,
                promise: this.comment.getCommentsWithToken(data)
            });
        };
    }

    getMoreCommentsWithToken(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_MORE_COMMENTS,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.comment.getMoreCommentsWithToken(data)
            });
        };
    }

    postComment(data) {
        // console.log('data', data);
        return (dispatch) => {
            return dispatch({
                type: POST_COMMENT,
                isAsync: true,
                loading: true,
                data: data,
                error: false,
                promise: this.comment.postComment(data)
            });
        };
    }

    deleteComments(data) {
        return (dispatch) => {
            return dispatch({
                type: DELETE_COMMENT,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.comment.deleteComment(data)
            });
        };
    }

    likeComment(data) {
            return (dispatch) => {
                return dispatch({
                    type: LIKE_COMMENT,
                    isAsync: true,
                    loading: true,
                    error: false,
                    promise: this.comment.likeComment(data)
                });
            };
        }
}
