import {competitions} from '../initials';

import {
    COMPETITION_GET,
    COMPETITION_GET_SUCCESS,
    COMPETITION_GET_ERROR,

    COMPETITIONS_GET,
    COMPETITIONS_GET_SUCCESS,
    COMPETITIONS_GET_ERROR,

    DISCUSSIONS_GET,
    DISCUSSIONS_GET_SUCCESS,
    DISCUSSIONS_GET_ERROR,

    DISCUSSIONS_GET_MORE,
    DISCUSSIONS_GET_MORE_SUCCESS,
    DISCUSSIONS_GET_MORE_ERROR,
    PHOTOBOOKS_GET,
    PHOTOBOOKS_GET_ERROR,
    PHOTOBOOKS_GET_SUCCESS,

    PHOTOGRAPHERS_GET,
    PHOTOGRAPHERS_GET_SUCCESS,
    PHOTOGRAPHERS_GET_ERROR

} from './constants';

export default (state = competitions, action: any) => {
    switch (action.type) {

        case COMPETITION_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                competition: false
            });

        case COMPETITION_GET_SUCCESS:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });

        case COMPETITION_GET_ERROR:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case PHOTOGRAPHERS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photographers: false
            });

        case PHOTOGRAPHERS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });

        case PHOTOGRAPHERS_GET_ERROR:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });
        case COMPETITIONS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                competition: null
            });

        case COMPETITIONS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case COMPETITIONS_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOBOOKS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                competition: null
            });

        case PHOTOBOOKS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTOBOOKS_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case DISCUSSIONS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                discusion: false
            });

        case DISCUSSIONS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                ...action.payload
            });

        case DISCUSSIONS_GET_ERROR:
            return Object.assign({}, state, {
                error: false,
                loading: false
            });
        default:
            return state;
    }
};
