export const COMPETITION_GET = 'COMPETITION_GET';
export const COMPETITION_GET_SUCCESS = 'COMPETITION_GET_SUCCESS';
export const COMPETITION_GET_ERROR = 'COMPETITION_GET_ERROR';

export const COMPETITIONS_GET = 'COMPETITIONS_GET';
export const COMPETITIONS_GET_SUCCESS = 'COMPETITIONS_GET_SUCCESS';
export const COMPETITIONS_GET_ERROR = 'COMPETITIONS_GET_ERROR';

export const DISCUSSIONS_GET = 'DISCUSSIONS_GET';
export const DISCUSSIONS_GET_SUCCESS = 'DISCUSSIONS_GET_SUCCESS';
export const DISCUSSIONS_GET_ERROR = 'DISCUSSIONS_GET_ERROR';

export const DISCUSSIONS_GET_MORE = 'DISCUSSIONS_GET_MORE';
export const DISCUSSIONS_GET_MORE_SUCCESS = 'DISCUSSIONS_GET_MORE_SUCCESS';
export const DISCUSSIONS_GET_MORE_ERROR = 'DISCUSSIONS_GET_MORE_ERROR';

export const PHOTOGRAPHERS_GET = 'PHOTOGRAPHERS_GET';
export const PHOTOGRAPHERS_GET_SUCCESS = 'PHOTOGRAPHERS_GET_SUCCESS';
export const PHOTOGRAPHERS_GET_ERROR = 'PHOTOGRAPHERS_GET_ERROR';


export const PHOTOBOOK_GET = 'PHOTOBOOK_GET';
export const PHOTOBOOK_GET_SUCCESS = 'PHOTOBOOK_GET_SUCCESS';
export const PHOTOBOOK_GET_ERROR = 'PHOTOBOOK_GET_ERROR';

export const PHOTOBOOKS_GET = 'PHOTOBOOKS_GET';
export const PHOTOBOOKS_GET_SUCCESS = 'PHOTOBOOKS_GET_SUCCESS';
export const PHOTOBOOKS_GET_ERROR = 'PHOTOBOOKS_GET_ERROR';
