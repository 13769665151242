import {Injectable} from '@angular/core';

import {
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEWS,
    GET_INTERVIEW,
    GET_ONE_PHOTOGRAPHER,
    SAVE_INTERVIEW_QUESTIONS
} from './constants';

import {InterviewsService} from './service';

@Injectable()
export class InterviewsActions {
    constructor(private interviews: InterviewsService) {
    }

    getInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_INTERVIEW,
                isAsync: true,
                loading: true,
                promise: this.interviews.getInterview(data)
            });
        };
    }

    getOnePhotographer() {
        return (dispatch) => {
            return dispatch({
                type: GET_ONE_PHOTOGRAPHER,
                isAsync: true,
                loading: true,
                promise: this.interviews.getOnePhotographer()
            });
        };
    }

    get(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_INTERVIEWS,
                isAsync: true,
                loading: true,
                promise: this.interviews.get(data)
            });
        };
    }

    getInterviewQuestions() {
        return (dispatch) => {
            return dispatch({
                type: GET_INTERVIEW_QUESTIONS,
                isAsync: true,
                promise: this.interviews.getInterviewQuestions()
            });
        };
    }

    saveInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: SAVE_INTERVIEW_QUESTIONS,
                isAsync: true,
                promise: this.interviews.saveInterviewQuestions(data)
            });
        };
    }
}
