import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    TranslateModule
  ],
  exports: [
    TranslateModule
  ]
})
export class SharedModule { }