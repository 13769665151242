import {ModuleWithProviders, NgModule} from '@angular/core';
import {AppService} from './services/app.service';

import {LocationService} from './services/location.service';
import {PhotoService} from './services/photos.service';
import {VotesService} from './services/votes.service';
import {DomainService} from './services/domain.service';
import {UserService} from './services/user.service';


import {NotificationService} from './services/notification/notification.service';


@NgModule({})
export class SharedServicesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedServicesModule,
            providers: [
                AppService,
                DomainService,
                LocationService,
                NotificationService,
                PhotoService,
                UserService,
                VotesService
            ]
        };
    }
}
