import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    OnDestroy,
    OnChanges
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {LocationService} from '../../services/location.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'icon',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageComponent implements OnInit, OnDestroy, OnChanges {
    routeSubscription: any;

    constructor(
        private locationService: LocationService,
        public route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {

    }

    ngOnChanges() {
        this.setLanguage();
    }

    ngOnInit() {
        this.translate.addLangs(['en', 'fr']);
        this.translate.setDefaultLang('en');

        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
        let langSelect = 'en';

        this.routeSubscription = this.route.params.subscribe(params => {
            if (params.home_language) {
                this.translate.use(params.home_language);
            }
        });
    }

    setLanguage() {

    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }
}
