import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user} from '../../../store/user';

import {application, ApplicationActions} from '../../../store/application';
import {photos} from '../../../store/photos';
import {interactions, InterActions} from '../../../store/inter';

@Component({
    selector: 'recommendPhotographerButton',
    templateUrl: './recommend.photographer.component.html',
    styleUrls: ['./recommend.photographer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendPhotographerComponent {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() photographer: string;
    @Input() recommendPhotographerVoted = false;

    constructor(public redux: NgRedux<any>,
                public interActions: InterActions,
                public app: ApplicationActions) {
    }

    recommendPhotographer() {
        this.redux.dispatch(<any>this.interActions.recommendPhotographer({user: this.photographer}));
    }

}
