import {Injectable} from '@angular/core';
import {BaseService} from '../../shared/base/services';
import {URLGETDATA} from '../../config/constants';

@Injectable()
export class UserService {
    constructor(private base: BaseService) {
    }

    login(data) {
        return this.base.post(URLGETDATA + 'login', data, data);
    }

    register(data) {
        return this.base.post(URLGETDATA + 'register', data, data);
    }

    confirmEmail(data) {
        return this.base.post(URLGETDATA + 'confirmemail', data, data);
    }


    loginAs(data) {
        return this.base.postWithToken(URLGETDATA + 'loginas', data, data);
    }


    logout() {
        return this.base.post(URLGETDATA + 'logout', null, null);
    }

    getUserData() {
        return this.base.postWithToken(URLGETDATA + 'getUserData', {},{});
    }

    forgotPassword(data) {
        return this.base.post(URLGETDATA + 'forgot', data, data);
    }

    resetPassword(data) {
        return this.base.post(URLGETDATA + 'resetpassword', data, data);
    }

    resendConfirmationEmail(data) {
        return this.base.post(URLGETDATA + 'resendconfirmationemail', data, data);
    }

    confirmActivationCode(data) {
        return this.base.post(URLGETDATA + 'confirmActivationCode', data, data);
    }

    saveUserData(data) {
        return this.base.post(URLGETDATA + 'saveUserData', data, data);
    }

    follow(data) {
        return this.base.postWithToken(URLGETDATA + 'follow', data, data);
    }

    unfollow(data) {
        return this.base.postWithToken(URLGETDATA + 'unfollow', data, data);
    }
}
