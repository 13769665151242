import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';
import {URL_GET_FLOW} from '../../config/constants';


@Injectable()
export class FlowService {
    constructor(private base: BaseService) {
    }

    getFlow(data) {
        return this.base.postWithToken(URL_GET_FLOW, data, data);
    }
}
