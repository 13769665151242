import { Injectable } from '@angular/core';

import { BaseService } from '../../shared/base/services';
import {
    URL_PHOTOGRAPHERS, URL_PHOTOGRAPHERS_FOLLOW, URL_PHOTOGRAPHERS_UN_FOLLOW,
    URL_SEARCH_PHOTOGRAPHERS
} from '../../config/constants';


@Injectable()
export class PhotographersService {
  constructor(private base: BaseService) {}

    get(data) {
          return this.base.getHttp(URL_PHOTOGRAPHERS + data.url, data.query ? data.query : '', data);
    }

    search(data) {
          return this.base.postWithToken(URL_SEARCH_PHOTOGRAPHERS + data.url, {search: data.search}, null);
    }
    follow(data) {
        return this.base.post(URL_PHOTOGRAPHERS_FOLLOW , data, data);
    }

    unfollow(data) {
        return this.base.post(URL_PHOTOGRAPHERS_UN_FOLLOW, data, data);
    }
}
