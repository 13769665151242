import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';
import {URLGETDATA,} from '../../config/constants';


@Injectable()
export class InterActionService {
    constructor(private base: BaseService) {
    }

    publishInterview(data) {
        return this.base.postWithToken(URLGETDATA + 'publishInterview', data, data);
    }

    requestInterview(data) {
        return this.base.postWithToken(URLGETDATA + 'requestInterview', data, data);
    }

    voteInterview(data) {
        return this.base.postWithToken(URLGETDATA + 'voteInterview', data, data);
    }

    recommendInterview(data) {
        return this.base.postWithToken(URLGETDATA + 'recommendInterview', data, data);
    }

    recommendPhotographer(data) {
        return this.base.postWithToken(URLGETDATA + 'recommendphotographer', data, data);
    }

    selectedphotographer(data) {
        return this.base.postWithToken(URLGETDATA + 'selectedphotographer', data, data);
    }

    setFirstImage(data) {
        return this.base.postWithToken(URLGETDATA + 'setFirstPhotoSelectedPhotographer', data, data);
    }

    deselectPhoto(data) {
        return this.base.postWithToken(URLGETDATA + 'deletePhotoSelectedPhotographer', data, data);
    }

    vote(data) {
        return this.base.postWithToken(URLGETDATA + 'vote', data, data);
    }

    starvote(data) {
        return this.base.postWithToken(URLGETDATA + 'starvote', data, data);
    }

    nominate(data) {
        return this.base.postWithToken(URLGETDATA + 'nominate', data, data);
    }

    sendToGallery(data) {
        return this.base.postWithToken(URLGETDATA + 'sendToGallery', data, data);
    }

    recommend(data) {
        return this.base.postWithToken(URLGETDATA + 'recommend', data, data);
    }

    addtofavourites(data) {
        return this.base.postWithToken(URLGETDATA + 'addtofavourites', data, data);
    }

    removeFromfavourites(data) {
        return this.base.postWithToken(URLGETDATA + 'removefavourites', data, data);
    }

    getFavesAndVotes(data) {
        return this.base.postWithToken(URLGETDATA + 'getFavesAndVotes', data, data);
    }
}
