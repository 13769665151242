import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Input, OnInit, OnChanges,
    Output, ChangeDetectorRef,
    ViewChild
} from '@angular/core';



@Component({
    selector: 'info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
    @Input() id: number;
    subcription: any;
    showInfo: any;
    toggleInfo = false;

    constructor(public cd: ChangeDetectorRef) {
    }

}
