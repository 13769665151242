import {
    Component,
    ChangeDetectorRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
import {ApplicationActions} from "../../../store/application/actions";
import {LocationService} from "../../../services/location.service";
import {InterActions} from "../../../store/inter/actions";
import {application} from "../../../store/application/selectors";
import {user} from "../../../store/user/selectors";
import {photos} from "../../../store/photos/selectors";
import {interactions} from "../../../store/inter/selectors";


@Component({
    selector: 'curator-voting-stars',
    templateUrl: './curator-voting-stars.component.html',
    styleUrls: ['./curator-voting-stars.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CuratorVotingStarsComponent implements OnInit, OnDestroy, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() showExtraButtons: boolean;
    @Input() imageId: number;
    @Input() overLay: false;
    @Input() userLogin: string;


    hasVoted = false;
    hasFaved = false;
    hasRecommended = false;
    isFollowing = false;

    subscription: any;
    subscriptionVotes: any;
    viewType: string;

    galleryVoted = false;
    isCurator = false;
    rating:any = 0;

    constructor(public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                private locationService: LocationService,
                public redux: NgRedux<any>,
                public interActions: InterActions) {
    }

    ngOnInit() {
        this.checkVotes();
    }

    ngOnChanges() {
        this.checkVotes();
    }

    checkVotes() {
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            if (user.roles) {
                user.roles.find(role => {
                    if (role.role === 1) {
                        this.isCurator = true;
                    }
                });
                this.cd.markForCheck();
            }

            this.subscriptionVotes = this.interactions.subscribe(item => {
                if (item.stars && item.stars.length) {
                    item.stars.forEach(photo => {
                            if (photo.image_id === this.imageId) {
                                this.rating = photo.votes;
                            }
                        });
                }
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionVotes) {
            this.subscriptionVotes.unsubscribe();
        }
    }

    onClick(event){
        this.rating = 0;
        this.redux.dispatch(<any>this.interActions.starphoto({votes: event.rating, image_id: this.imageId, book: 'world-street-photography-photobook-6' }));
    }

    removeVotes() {
        this.redux.dispatch(<any>this.interActions.starphoto({votes: 0, image_id: this.imageId, book: 'world-street-photography-photobook-6' }));
    }
    }
