export const URL_PROFILE = 'URL_PROFILE';
export const URL_PROFILE_SUCCESS = 'URL_PROFILE_SUCCESS';
export const URL_PROFILE_ERROR = 'URL_PROFILE_ERROR';


export const URL_PROFILE_PHOTOS = 'URL_PROFILE_PHOTOS';
export const URL_PROFILE_PHOTOS_SUCCESS = 'URL_PROFILE_PHOTOS_SUCCESS';
export const URL_PROFILE_PHOTOS_ERROR = 'URL_PROFILE_PHOTOS_ERROR';

export const URL_PROFILE_PROFILE_LIKE = 'URL_PROFILE_PROFILE_LIKE';
export const URL_PROFILE_PROFILE_LIKE_SUCCESS = 'URL_PROFILE_PROFILE_LIKE_SUCCESS';
export const URL_PROFILE_PROFILE_LIKE_ERROR = 'URL_PROFILE_PROFILE_LIKE_ERROR';

export const URL_PROFILE_FAVOURITES = 'URL_PROFILE_FAVOURITES';
export const URL_PROFILE_FAVOURITES_SUCCESS = 'URL_PROFILE_FAVOURITES_SUCCESS';
export const URL_PROFILE_FAVOURITES_ERROR = 'URL_PROFILE_FAVOURITES_ERROR';

export const URL_PROFILE_FOLLOWING = 'URL_PROFILE_FOLLOWING';
export const URL_PROFILE_FOLLOWING_SUCCESS = 'URL_PROFILE_FOLLOWING_SUCCESS';
export const URL_PROFILE_FOLLOWING_ERROR = 'URL_PROFILE_FOLLOWING_ERROR';

export const URL_PROFILE_FOLLOWERS = 'URL_PROFILE_FOLLOWERS';
export const URL_PROFILE_FOLLOWERS_SUCCESS = 'URL_PROFILE_FOLLOWERS_SUCCESS';
export const URL_PROFILE_FOLLOWERS_ERROR = 'URL_PROFILE_FOLLOWERS_ERROR';

export const URL_PROFILE_ABOUT = 'URL_PROFILE_ABOUT';
export const URL_PROFILE_ABOUT_SUCCESS = 'URL_PROFILE_ABOUT_SUCCESS';
export const URL_PROFILE_ABOUT_ERROR = 'URL_PROFILE_ABOUT_ERROR';

export const URL_PROFILE_LIKE = 'URL_PROFILE_LIKE';
export const URL_PROFILE_LIKE_SUCCESS = 'URL_PROFILE_LIKE_SUCCESS';
export const URL_PROFILE_LIKE_ERROR = 'URL_PROFILE_LIKE_ERROR';

export const URL_PROFILE_FOLLOW = 'URL_PROFILE_FOLLOW';
export const URL_PROFILE_FOLLOW_SUCCESS = 'URL_PROFILE_FOLLOW_SUCCESS';
export const URL_PROFILE_FOLLOW_ERROR = 'URL_PROFILE_FOLLOW_ERROR';

export const URL_PROFILE_UNFOLLOW = 'URL_PROFILE_UNFOLLOW';
export const URL_PROFILE_UNFOLLOW_SUCCESS = 'URL_PROFILE_UNFOLLOW_SUCCESS';
export const URL_PROFILE_UNFOLLOW_ERROR = 'URL_PROFILE_UNFOLLOW_ERROR';

export const URL_PROFILE_RECOMMEND = 'URL_PROFILE_RECOMMEND';
export const URL_PROFILE_RECOMMEND_SUCCESS = 'URL_PROFILE_RECOMMEND_SUCCESS';
export const URL_PROFILE_RECOMMEND_ERROR = 'URL_PROFILE_RECOMMEND_ERROR';

