import {AfterViewInit, Component, ChangeDetectorRef, Input, OnInit, OnDestroy, OnChanges} from '@angular/core';
import {AsyncLocalStorage} from 'angular-async-local-storage';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: ' user-urls',
    templateUrl: './user.urls.component.html',
    styleUrls: ['./user.urls.component.scss']
})
export class UserUrlsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() user: any;


    urls: string;
    loading: boolean;
    facebook: SafeResourceUrl;
    user_dpi: SafeResourceUrl;
    user_googleplus: SafeResourceUrl;
    user_px500: SafeResourceUrl;
    user_twitter: SafeResourceUrl;
    user_x1: SafeResourceUrl;

    constructor(protected localStorage: AsyncLocalStorage,
                public cd: ChangeDetectorRef,
                public route: ActivatedRoute,
                public router: Router,
                private domSanitizer: DomSanitizer) {

    }

    ngOnInit() {
        // console.log('user', this.user);
        if (this.user) {


            if (this.user.user_facebook.length) {
                this.facebook = this.domSanitizer.bypassSecurityTrustResourceUrl(this.user.user_facebook);
                if (this.user.user_facebook.indexOf('facebook') === -1) {
                    this.facebook = 'https://www.facebook.com/' + this.user.user_facebook;
                }
            }

            if (this.user.user_dpi.length) {
                this.user_dpi = this.domSanitizer.bypassSecurityTrustUrl(this.user.user_dpi);
                if (this.user.user_dpi.indexOf('https://www.instagram.com') === -1) {
                    this.user_dpi = 'https://www.instagram.com/' + this.user.user_dpi;
                }
            }

            if (this.user.user_googleplus.length) {
                this.user_googleplus = this.domSanitizer.bypassSecurityTrustUrl(this.user.user_googleplus);
                if (this.user.user_googleplus.indexOf('https://www.googleplus.com') === -1) {
                    this.user_googleplus = 'https://www.googleplus.com/' + this.user.user_googleplus;
                }
            }

            if (this.user.user_px500.length) {
                this.user_px500 = this.domSanitizer.bypassSecurityTrustUrl(this.user.user_px500);
                if (this.user.user_px500.indexOf('https://500px.com') === -1) {
                    this.user_px500 = 'https://500px.com/' + this.user.user_px500;
                }
            }

            if (this.user.user_twitter.length) {
                this.user_twitter = this.domSanitizer.bypassSecurityTrustUrl(this.user.user_twitter);
                if (this.user.user_twitter.indexOf('https://www.twitter.com') === -1) {
                    this.user_twitter = 'https://www.twitter.com/' + this.user.user_twitter;
                }
            }

            if (this.user.user_x1.length) {
                this.user_x1 = this.domSanitizer.bypassSecurityTrustUrl(this.user.user_x1);
                if (this.user.user_x1.indexOf('https://1x.com') === -1) {
                    this.user_x1 = 'https://1x.com/member/' + this.user.user_x1;
                }
            }
        }
    }


    ngOnDestroy() {

    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        this.cd.markForCheck();
    }
}
