import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';
import {URL_GET_NEWS} from '../../config/constants';


@Injectable()
export class NewsService {
    constructor(private base: BaseService) {
    }

    getNews(data) {
        return this.base.getHttp(URL_GET_NEWS, '', data);
    }

}
