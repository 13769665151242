import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user, UserActions} from '../../../store/user';
import {application, ApplicationActions} from '../../../store/application';
import {interactions, InterActions} from '../../../store/inter';



@Component({
    selector: 'followButton',
    templateUrl: './follow.component.html',
    styleUrls: ['./follow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowButtonComponent implements OnInit, OnChanges {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() overLay: false;
    @Input() userLogin: string;

    isFollowing = false;

    constructor(public cd: ChangeDetectorRef,
                public redux: NgRedux<any>,
                public interActions: InterActions,
                public userActions: UserActions,
                public app: ApplicationActions) {
    }

    ngOnInit() {
        this.checkFollowing();
    }

    ngOnChanges() {
        this.checkFollowing();
    }

    checkFollowing() {
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            if (user.following) {
                this.isFollowing = (user.following.indexOf(this.userLogin) > -1);
                this.cd.markForCheck();
            }
        }
    }

    unFollow() {
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            this.redux.dispatch(<any>this.app.set({overlay: false}));
            this.redux.dispatch(<any>this.userActions.unfollow({userLogin: this.userLogin}));
            this.isFollowing = false;
        } else {
            this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
        }
    }

    follow() {
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) {
            this.redux.dispatch(<any>this.app.set({overlay: false}));
            this.redux.dispatch(<any>this.userActions.follow({userLogin: this.userLogin}));
             this.isFollowing = true;
        } else {
            this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
        }
    }
}
