import {Injectable} from '@angular/core';

import {
    USER_LOGIN,
    USER_LOGIN_AS,
    USER_LOGOUT,
    USER_DATA_GET,
    USER_SET,
    USER_FORGOT_PASSWORD,
    USER_DATA_SAVE,
    USER_FOLLOW,
    USER_CONFIRM_ACTIVATION_CODE,
    USER_UNFOLLOW, USER_RESET_PASSWORD, USER_REGISTER, USER_CONFIRM_ACCOUNT, USER_RESEND_CONFIRMATION_EMAIL
} from './constants';

import {UserService} from './service';

@Injectable()
export class UserActions {
    constructor(private user: UserService) {
    }

    set (data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: USER_SET
            });
        };
    }

    login(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_LOGIN,
                isAsync: true,
                promise: this.user.login(data)
            });
        };
    }

    confirmEmail(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_CONFIRM_ACCOUNT,
                isAsync: true,
                promise: this.user.confirmEmail(data)
            });
        };
    }

    register(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_REGISTER,
                isAsync: true,
                promise: this.user.register(data)
            });
        };
    }

    loginAs(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_LOGIN_AS,
                isAsync: true,
                promise: this.user.loginAs(data)
            });
        };
    }


    follow(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_FOLLOW,
                isAsync: true,
                promise: this.user.follow(data)
            });
        };
    }

    unfollow(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_UNFOLLOW,
                isAsync: true,
                promise: this.user.unfollow(data)
            });
        };
    }

    logoutUser() {
        return (dispatch) => {
            return dispatch({
                type: USER_LOGOUT,
                isAsync: true,
                isOptimistic: true,
                promise: this.user.logout()
            });
        };
    }

    forgotPassword(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_FORGOT_PASSWORD,
                isAsync: true,
                isOptimistic: true,
                promise: this.user.forgotPassword(data)
            });
        };
    }

    resetPassword(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_RESET_PASSWORD,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.user.resetPassword(data)
            });
        };
    }


    resendConfirmationEmail(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_RESEND_CONFIRMATION_EMAIL,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.user.resendConfirmationEmail(data)
            });
        };
    }

    confirmActivationCode(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_CONFIRM_ACTIVATION_CODE,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.user.confirmActivationCode(data)
            });
        };
    }

    getUserData() {
        return (dispatch) => {
            return dispatch({
                type: USER_DATA_GET,
                isAsync: true,
                promise: this.user.getUserData()
            });
        }
    }

    saveUserData(data) {
        return (dispatch) => {
            return dispatch({
                type: USER_DATA_SAVE,
                isAsync: true,
                promise: this.user.saveUserData(data)
            });
        }
    }

}

