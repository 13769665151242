export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';

export const GET_NEW_MESSAGES = 'GET_NEW_MESSAGES';
export const GET_NEW_MESSAGES_SUCCESS = 'GET_NEW_MESSAGES_SUCCESS';
export const GET_NEW_MESSAGES_ERROR = 'GET_NEW_MESSAGES_ERROR';

export const GET_OLD_MESSAGES = 'GET_OLD_MESSAGES';
export const GET_OLD_MESSAGES_SUCCESS = 'GET_OLD_MESSAGES_SUCCESS';
export const GET_OLD_MESSAGES_ERROR = 'GET_OLD_MESSAGES_ERROR';

export const MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ';
export const MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS';
export const MARK_MESSAGE_AS_READ_ERROR = 'MARK_MESSAGE_AS_READ_ERROR';


export const MARK_ALL_MESSAGE_AS_READ = 'MARK_ALL_MESSAGE_AS_READ';
export const MARK_ALL_MESSAGE_AS_READ_SUCCESS = 'MARK_ALL_MESSAGE_AS_READ_SUCCESS';
export const MARK_ALL_MESSAGE_AS_READ_ERROR = 'MARK_ALL_MESSAGE_AS_READ_ERROR';
