import {Injectable} from '@angular/core';

import {
    NEWS_GET
} from './constants';

import {NewsService} from './service';

@Injectable()
export class NewsActions {
    constructor(private news: NewsService) {
    }

    getNews(data) {
        return (dispatch) => {
            return dispatch({
                type: NEWS_GET,
                isAsync: true,
                promise: this.news.getNews(data)
            });
        };
    }

}
