import {Injectable} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';


@Injectable()
export class NotificationService {
    applicationSubscribtion: any;
    available: boolean = false;

    closeTimeout: number;

    globalOptions = {
        timeout: 15000
    };

    messageIcon: string;
    notification: any;

    permissionPending: boolean = false;
    permissionRequired: boolean;
    shownNotifications: Array<string> = [];
    status: string;

    services: Array<{ active: boolean, name: string, type: string }> = [{
        active: true,
        name: 'messages',
        type: 'messages'
    }, {
        active: true,
        name: 'news',
        type: 'news'
    }];

    constructor(public route: ActivatedRoute,
                public router: Router) {
        this.available = 'Notification' in window;
        if (this.available) {
            this.status = Notification.permission;
            this.permissionRequired = this.status === 'default';
        }
    }

    public deniedPermission() {
        if (this.available) {
            return Notification.permission === 'denied';
        }
        return false;
    }

    public hasPermission() {
        if (this.available) {
            return Notification.permission === 'granted';
        }
    }

    public setService(serviceIndex, active) {
        this.services[serviceIndex].active = active;
    }

    public requestPermission() {
        this.permissionRequired = false;
        this.permissionPending = true;

        Notification.requestPermission(status => {
            this.status = status;

            this.permissionRequired = true;
            this.permissionPending = false;
        });
    }

    public trigger(options, type) {
        if (this.shownNotifications.indexOf(options.tag) !== -1) {
            return;
        }

        if (this.notification) {
            this.notification.close();
        }

        if (this.allowed(type)) {
            this.show(Object.assign({}, this.globalOptions, options));
        }
    }

    allowed(type) {
        return this.status === 'granted' && this.services.find(service => {
            return service.type === type;
        });
    }

    public show(options) {
        this.shownNotifications.push(options.tag);
        this.notification = new Notification(options.title, options);
        this.closeTimeout = window.setTimeout(() => this.close(), options.timeout);

        this.notification.addEventListener('click', () => {
            window.focus();

            if (options.url) {
                this.router.navigate([options.url]);
            }
            this.close();
        });
    }

    public close() {
        window.clearTimeout(this.closeTimeout);
        this.notification.close();
    }
}
