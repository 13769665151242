import { Iterable } from 'immutable';
import { ensureState } from 'redux-optimistic-ui';
import { createLogger } from 'redux-logger';

const logger = new createLogger({
  level: 'info',
  collapsed: true,
  predicate: (getState, action) => true,
  stateTransformer: (state) => {
    let newState = {};
    const currentState = ensureState(state);
    for (const i of Object.keys(currentState)) {
      if (Iterable.isIterable(currentState[i])) {
        newState[i] = currentState[i].toJS();
      } else {
        newState[i] = currentState[i];
      }
    }
    return newState;
  }
});

export default logger;
