import {Injectable} from '@angular/core';

import {BaseService} from '../../shared/base/services';
import {URLGETDATA} from '../../config/constants';


@Injectable()
export class MessagesService {
    constructor(private base: BaseService) {
    }

    getMessages(data) {
        return this.base.postWithToken(URLGETDATA + 'getmessages', data, data);
    }

    getNewMessages(data) {
        return this.base.postWithToken(URLGETDATA + 'getmessages', data, data);
    }

    getOldMessages(data) {
        return this.base.postWithToken(URLGETDATA + 'getmessages', data, data);
    }
    markMessageAsRead(data) {
        return this.base.postWithToken(URLGETDATA + 'markmessageasread', data, data);
    }

    markAllmessageasreadAction() {
        return this.base.postWithToken(URLGETDATA + 'markAllmessageasread', null, null);
    }
}
