import {Injectable, OnInit} from '@angular/core';

import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
import {DomainService} from "../../services/domain.service";

@Injectable()
export class BaseService {
    private urlDomain: string = 'https://world-street.photograpy';

    constructor(private httpClient: HttpClient, public redux: NgRedux<any>,
                private domainService: DomainService) {
    }

    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'my-auth-token',
            'Access-Control-Allow-Headers': ''
        })
    };
/*
{
            'Content-Type': 'text/plain',
            'Authorization': 'my-auth-token'
        }
{
            'Access-Control-Allow-Origin': '*',
             'access-control-allow-credentials': 'true',
             'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
             'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
        }
		header('Access-Control-Allow-Origin: *');
		header('HTTP/1.1 500 Internal Server Error');
		exit();

* */
    private httpOptionsPost = {
        headers: new HttpHeaders()
    };

    public getHttp(url, queryString, data = null) {
        // url = this.domainService.urlApi + '/en' + url;
        const {photos} = ensureState(this.redux.getState());

        if (url.indexOf('all') > -1 && (!queryString || queryString === '')) {
            queryString = '?' + (photos.year ? '&year=' + photos.year : '') +
            (photos.music ? '&music=' + photos.music : '') +
            (photos.search ? '&search=' +  photos.search : '')
        }

        if (data.fromCache) {
            let cachedData = window.localStorage[url + queryString];
            if (cachedData) {
                return new Promise((resolve, reject) => {
                    return resolve(JSON.parse(cachedData));
                });
            }
        }

        return new Promise((resolve, reject) => {
            this.httpClient.get<any[]>(url + queryString).shareReplay()
                .subscribe(response => {

                    if (data.fromCache) {
                        window.localStorage.setItem(url + queryString, JSON.stringify(response));
                    }
                    return resolve(response);
                }, (err: HttpErrorResponse) => {
                    // console.log(err.error);
                });
        });
    }

    public postHttp(url, data, options) {
        // url = this.domainService.urlApi + '/en'  + url;
        const postData = new FormData();
        if (data) {
            for (const key in data) {
                postData.append(key, data[key]);
            }
        }

        return this.httpClient.post<any[]>(url, postData, this.httpOptionsPost).shareReplay();
    }

    public postWithTokenHttp(url, data) {
        // url = this.domainService.urlApi  + '/en' + url;
        const {user} = ensureState(this.redux.getState());

        if (user.token) {
            const postData = new FormData();
            if (data) {
                for (const key in data) {
                    postData.append(key, data[key]);
                }
            }

            let headers = new HttpHeaders().set('Authorization', 'Bearer ' + user.token);

            return this.httpClient.post<any[]>(url, postData, {headers: headers}).shareReplay();
        }
    }

    public postWithToken(url, data, token) {

        return new Promise((resolve, reject) => {
            this.postWithTokenHttp(url, data)
                .subscribe(response => {
                    return resolve(response);
                }, (err: HttpErrorResponse) => {
                    reject(err);
                });
        });
    }

    public post(url, data, options) {

        return new Promise((resolve, reject) => {
            this.postHttp(url, data, options)
                .subscribe(response => {
                    return resolve(response);
                }, (err: HttpErrorResponse) => {
                    reject(err);
                });
        });
    }

    public cleanup(query) {
        Object.keys(query).forEach(key => {
            if (!query[key] || query[key].length === 0) {
                delete query[key];
            }
        });
    }
}
