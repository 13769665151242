import {Component, EventEmitter, Input, OnChanges, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {UserActions, user} from '../../../../store/user';
import {BASE_URL} from "../../../../config/constants";
import {select} from "@angular-redux/store";
import {Observable} from "rxjs/Observable";

@Component({
  selector: 'competitionsMenu',
  templateUrl: './competitions.menu.component.html',
  styleUrls: ['./competitions.menu.component.scss']
})
export class CompetitionsMenuComponent implements OnChanges, OnInit, OnDestroy {
  @select(user) user: Observable<any>;
  @Input() active: string;
  @Input() competition: any;
  status: any;
  loggedInUser: null;
  subscription: any;

  constructor(public cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscription = this.user.subscribe(use => {
      if (use.tokenTime !== '') {
        this.loggedInUser = use.isLoggedIn;
        this.wakeUp();
      }
    });
    this.wakeUp();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.wakeUp();
  }

  wakeUp() {
      setTimeout(() => {
          this.cd.markForCheck();
      }, 30);
  }
}
