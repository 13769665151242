import {photos} from '../initials';

import {
    PHOTOGRAPHERS_FOLLOW,
    PHOTOGRAPHERS_FOLLOW_ERROR,
    PHOTOGRAPHERS_FOLLOW_SUCCESS,
    PHOTOGRAPHERS_GET,
    PHOTOGRAPHERS_GET_SUCCESS,
    PHOTOGRAPHERS_GET_ERROR,
    PHOTOGRAPHERS_SEARCH,
    PHOTOGRAPHERS_SEARCH_SUCCESS,
    PHOTOGRAPHERS_SEARCH_ERROR,
    PHOTOGRAPHERS_UN_FOLLOW,
    PHOTOGRAPHERS_UN_FOLLOW_ERROR,
    PHOTOGRAPHERS_UN_FOLLOW_SUCCESS

} from './constants';

export default (state = photos, action: any) => {

    switch (action.type) {
        case PHOTOGRAPHERS_GET:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photographers: []
            });

        case PHOTOGRAPHERS_GET_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTOGRAPHERS_GET_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTOGRAPHERS_SEARCH:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photographers: []
            });

        case PHOTOGRAPHERS_SEARCH_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case PHOTOGRAPHERS_SEARCH_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });


        default:
            return state;
    }
};
