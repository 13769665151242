import {Injectable} from '@angular/core';

import {
    FLOW_GET
} from './constants';

import {FlowService} from './service';

@Injectable()
export class FlowActions {
    constructor(private flow: FlowService) {
    }

    getFlow(data) {
        return (dispatch) => {
            return dispatch({
                type: FLOW_GET,
                isAsync: true,
                promise: this.flow.getFlow(data)
            });
        };
    }
}
