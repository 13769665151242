import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'photographersMenu',
  templateUrl: './photographers.menu.component.html',
  styleUrls: ['./photographers.menu.component.scss']
})
export class PhotographersMenuComponent {
  @Output() changeView = new EventEmitter<any>();
  @Input() active: string;


  constructor(public translate: TranslateService) { }

  callChangeView($event) {
    this.changeView.emit($event);
  }
}
