import { optimistic } from '../shared/middleware/optimistic';
import { applyMiddleware, createStore } from 'redux'
const thunk = require('redux-thunk').default;

import {environment} from '../../environments/environment';

import logger from './configure-logger';

let middlewares = [ optimistic,  thunk ];

if (!environment.production) {
  middlewares = [ ...middlewares, logger ];
}


export const middleware = middlewares;
