import {profile} from '../initials';

import {
    URL_PROFILE_PHOTOS,
    URL_PROFILE_PHOTOS_ERROR,
    URL_PROFILE_PHOTOS_SUCCESS,

    URL_PROFILE_LIKE,
    URL_PROFILE_LIKE_SUCCESS,
    URL_PROFILE_LIKE_ERROR,

    URL_PROFILE_ABOUT,
    URL_PROFILE_ABOUT_ERROR,
    URL_PROFILE_ABOUT_SUCCESS,

    URL_PROFILE_FAVOURITES,
    URL_PROFILE_FAVOURITES_ERROR,
    URL_PROFILE_FAVOURITES_SUCCESS,


    URL_PROFILE_FOLLOWERS,
    URL_PROFILE_FOLLOWERS_ERROR,
    URL_PROFILE_FOLLOWERS_SUCCESS,

    URL_PROFILE_FOLLOWING,
    URL_PROFILE_FOLLOWING_ERROR,
    URL_PROFILE_FOLLOWING_SUCCESS,

    URL_PROFILE_FOLLOW,
    URL_PROFILE_FOLLOW_ERROR,
    URL_PROFILE_FOLLOW_SUCCESS,

    URL_PROFILE_UNFOLLOW,
    URL_PROFILE_UNFOLLOW_ERROR,
    URL_PROFILE_UNFOLLOW_SUCCESS,

    URL_PROFILE_RECOMMEND,
    URL_PROFILE_RECOMMEND_ERROR,
    URL_PROFILE_RECOMMEND_SUCCESS,

    URL_PROFILE,
    URL_PROFILE_ERROR,
    URL_PROFILE_SUCCESS

} from './constants';

export default (state = profile, action: any) => {

    switch (action.type) {

        case URL_PROFILE:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });
        case URL_PROFILE_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_LIKE:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
        case URL_PROFILE_LIKE_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_LIKE_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_PHOTOS:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });
        case URL_PROFILE_PHOTOS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_PHOTOS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_ABOUT:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
        case URL_PROFILE_ABOUT_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_ABOUT_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        case URL_PROFILE_FAVOURITES:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });
        case URL_PROFILE_FAVOURITES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                photos: []
            });
        case URL_PROFILE_FAVOURITES_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        case URL_PROFILE_FOLLOWERS:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });
        case URL_PROFILE_FOLLOWERS_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                photos: []
            });
        case URL_PROFILE_FOLLOWERS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_FOLLOWING:
            return Object.assign({}, state, {
                error: false,
                loading: true,
                photos: []
            });
        case URL_PROFILE_FOLLOWING_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false,
                photos: []
            });
        case URL_PROFILE_FOLLOWING_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_FOLLOW:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
        case URL_PROFILE_FOLLOW_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_FOLLOW_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });

        case URL_PROFILE_UNFOLLOW:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
        case URL_PROFILE_UNFOLLOW_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_UNFOLLOW_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        case URL_PROFILE_RECOMMEND:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });
        case URL_PROFILE_RECOMMEND_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });
        case URL_PROFILE_RECOMMEND_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                ...action.payload
            });


        default:
            return state;
    }
};
