import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhotoService} from '../../../services/photos.service';

@Component({
    selector: 'photographersViewType',
    templateUrl: './photographers.view.type.component.html',
    styleUrls: ['./photographers.view.type.component.scss']
})
export class PhotographersViewTypeComponent implements OnInit {
    @Output() viewType = new EventEmitter<any>();
    @Input() active: string;

    viewLocalType: string;

    constructor(private photoService: PhotoService) {
    }

    ngOnInit() {
        this.photoService.getViewType();
    }

    setViewTypeEmit(viewType: string) {
        this.photoService.setViewType(viewType);
        this.viewLocalType = viewType;
        this.viewType.emit(viewType);
    }
}
