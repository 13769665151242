import {ApplicationState, UiState, StoreData} from './ApplicationState';
import {DomainInterface} from "../services/domain.interface";

export interface ApplicationInterface {
    alerts: object;
    cookiesAccept: boolean;
    shownPhoto: object;
    debug: boolean;
    loading: boolean;
    overlay: boolean;
    showInformation: boolean;
    config: DomainInterface;
    search: object;
    notification: object;
    url: string;
}

export const application: ApplicationInterface = {
    alerts: [],
    debug: false,
    cookiesAccept: false,
    shownPhoto: {
        book_id: 0,
        full: '',
        image_height: 0,
        image_id: 0,
        image_nude: false,
        image_points: 0,
        image_title: '',
        image_width: 0,
        main_domain_id: 0,
        story_link: '',
        thumbnail: '',
        user_firstname: '',
        user_lastname: '',
        competitionphotos: []
    },
    notification: {},
    search: {
        year: 2018
    },
    showInformation: false,
    loading: true,
    overlay: null,
    config: null,
    url: ''
};

// import {fromJS} from 'immutable';

export const INITIAL_UI_STATE: UiState = {
    registerPopup: false,
    photoOverlay: false,
    currentError: ''
};

export const INITIAL_STORE_DATA: StoreData = {
    photos: [],
};

export const INITIAL_APPLICATION_STATE: ApplicationState = {
    uiState: INITIAL_UI_STATE,
    storeData: INITIAL_STORE_DATA
};


export interface PageInterface {
    error: boolean;
    loading: boolean;
    title: string,
    page: string;
}

export const pages: PageInterface = {
    error: false,
    loading: false,
    title: '',
    page: ''
};

export const photos = {
    error: false,
    loading: false,
    list: [],
    voted: [],
    faved: [],
    viewType: 'thumbnails'
};

export const news = {
    error: false,
    loading: false,
    list: [],
};

export interface InterviewInterface {
    error: boolean;
    loading: boolean;
    intro: string,
    questions: object;
    photos: object;

}

export const interviews =  {
    loading: false,
    intro: '',
    notification: '',
    photographer: [],
    questions: [],
    photos: []
};

export const interactions = {
    error: false,
    loading: false,
    list: [],
    voted: [],
    faved: [],
    galleryVoted: [],
    stars: [],
    bookVoted: [],
    recommendPhotographer: []
};

export const flow = {
    error: false,
    loading: false,
    list: []
};


export interface CompetitionsInterface {
    error: boolean;
    loading: boolean;
    competition: object;
    photobooks: object;
    discusion: object;
}

export const competitions: CompetitionsInterface = {
    error: false,
    loading: false,
    competition: null,
    photobooks: null,
    discusion: []
};

export const photo = {};

export const user = {
    cookiesAccept: false,
    emailConfirmed: 0,
    birthday: '',
    error: false,
    following: [],
    loading: false,
    isLoggedIn: false,
    name: '',
    id: '',
    profile: '',
    profile_image: '',
    token: '',
    tokenTime: 0,
    recoveryId: ''
};

export interface MessagesInterface {
    loading: boolean;
    error: boolean;
    list: any;
    alert: boolean;
    unread: number;
    total: number;
}

export const messages: MessagesInterface = {
    loading: false,
    error: false,
    list: [],
    alert: false,
    unread: 0,
    total: 0
};

export const comment = {
    comments: [],
    moreComments: [],
    debug: false,
    error: false,
    loading: false,
    data: {
        commentType: '',
        parentid: 0,
        image_id: 0,
        bookId: 0,
        comment: ''
    },
};


export const profile = {
    error: false,
    loading: false,
    profile: [],
};

export interface ProfilePhotosInterface {
    image_id: number;
    user_firstname: string;
    user_lastname: string;
    user_login: string;
    image_title: string;
    image_nude: string;
    story_link: string;
    image_story: string;
    thumbnail: string;
    full: string;
    image_background: string;
}

export interface ProfileInterface {
    user_login?: string;
    user_firstname: string;
    user_lastname: string;
    user_country?: string;
    user_online?: string;
    user_startdate?: string;
    user_twitter?: string;
    user_facebook?: string;
    user_googleplus?: string;
    user_language?: string;
    user_gender?: string;
    user_about?: string;
    user_dpi?: string;
    user_x1?: string;
    user_px500?: string;
    user_profil_image?: string;
    user_book_image?: string;
    webpage_backgroundcolor?: string;
    webpage_font?: string;
    webpage_font2?: string;
    webpage_fontcolor?: string;
    websitename?: string;
    teaser_image?: string;
}


