import {
  Directive,
  Input,
  Output,
  EventEmitter,
    AfterViewInit
} from '@angular/core';

@Directive({ selector: '[polling]' })
export class PollingDirective implements AfterViewInit{
  @Input() pollAllowedInvisible: boolean = false;
  @Input() pollTime: number;
  @Output() onPolling = new EventEmitter<any>();

  interval: number;
  timeout: number;

  ngAfterViewInit() {
    this.timeout = window.setTimeout(() => {
      this.interval = window.setInterval(() => {
        if (document.visibilityState === 'visible' || this.pollAllowedInvisible) {
          this.onPolling.emit();
        }
      }, this.pollTime);
    }, 1000);
  }

  ngOnDestroy() {
    window.clearInterval(this.interval);
    window.clearTimeout(this.timeout);
  }
}


