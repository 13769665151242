import {Component, EventEmitter, Input, OnChanges,Output, OnInit, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'photoMenu',
  templateUrl: './photo.menu.component.html',
  styleUrls: ['./photo.menu.component.scss']
})
export class PhotoMenuComponent  implements OnChanges, OnInit {
  @Output() changeView = new EventEmitter<any>();
  @Input() active: string;

    constructor(public cd: ChangeDetectorRef,
                public translate: TranslateService,
                        public userService: UserService) {
    }

  callChangeView($event) {
    this.changeView.emit($event);
  }

    ngOnInit() {
        this.wakeUp();
    }

    ngOnChanges() {
        this.wakeUp();
    }

    wakeUp() {
        setTimeout(() => {
            this.cd.markForCheck();
        }, 30);
    }
}
