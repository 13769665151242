import {Component} from '@angular/core';

import {NgRedux} from '@angular-redux/store';
import {ensureState} from 'redux-optimistic-ui';

import {ApplicationActions} from '../../store/application';


@Component({
    selector: 'registered',
    templateUrl: './registered.component.html',
    styleUrls: ['./registered.component.scss']
})

export class RegisteredComponent {


    constructor(public app: ApplicationActions,

                public redux: NgRedux<any>) {

    }


    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }

}
