import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'photographerlist',
    templateUrl: './photographerlist.component.html',
    styleUrls: ['./photographerlist.component.scss']
})
export class PhotographerListComponent implements OnInit {
    @Input() photographers: any;
    @Input() viewType: string;
    @Input() showButtons = false;

    constructor() {
    }

    ngOnInit() {
    }

    replaceBreaks(value) {
        return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    }

    setViewType(viewType: string) {
        this.viewType = viewType;
    }

    photoAction() {
    }
}
