import {Component, OnInit, Input, OnDestroy, ChangeDetectorRef, OnChanges} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';


import {NgRedux, select} from '@angular-redux/store';
import {ApplicationActions} from '../../store/application';
import {news, NewsActions} from '../../store/news';


import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
import {
    ActivatedRoute,
    Router
} from '@angular/router';

@Component({
    selector: 'news-list',
    templateUrl: './newslist.component.html',
    styleUrls: ['./newslist.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy, OnChanges {
    @select(news) news: Observable<any>;

    subscription: any;
    list: any;

    constructor(public router: Router,
                public route: ActivatedRoute, private domSanitizer: DomSanitizer,
                public redux: NgRedux<any>,
                public newsActions: NewsActions,
                private applicationActions: ApplicationActions,
                public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.redux.dispatch(<any>this.newsActions.getNews({fromCache: false}));
        this.subscription = this.news.subscribe(newsItem => {
            if (!newsItem.loading && !newsItem.error) {
                // console.log('newsItem', newsItem);
                // newsItem.book_text = this.replaceBreaks(newsItem.book_news);
                this.list = newsItem.list;
            }
        });
    }

    ngOnChanges() {

    }

    goToPage(page: string) {
        window.scrollTo(0, 0);
        this.cd.markForCheck();
        this.router.navigate([page]);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    replaceBreaks(value) {
        if (value) {
            value = value.substring(0, 300);
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />') + '...';
        }
        return value;
    }

}
