import { Injectable } from '@angular/core';

import { BaseService } from '../../shared/base/services';

import {URLGETDATA} from '../../config/constants';


@Injectable()
export class ApplicationService {
  constructor(private base: BaseService) {}

    getCompetitionsPhotosFromMember(data) {
        return this.base.getHttp(URLGETDATA + 'getCompetitionFotos', data, data);
    }
}
