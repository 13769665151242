import {Injectable} from '@angular/core';

import {
    URL_PROFILE,
    URL_PROFILE_ABOUT,
    URL_PROFILE_FAVOURITES,
    URL_PROFILE_FOLLOWERS,
    URL_PROFILE_FOLLOWING,
    URL_PROFILE_FOLLOW,
    URL_PROFILE_LIKE,
    URL_PROFILE_PHOTOS,
    URL_PROFILE_RECOMMEND,
    URL_PROFILE_UNFOLLOW,

} from './constants';

import {ProfileService} from './service';

@Injectable()
export class ProfileActions {
    constructor(private profile: ProfileService) {
    }

    like (data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_LIKE,
                isAsync: true,
                promise: this.profile.like(data)
            });
        };
    }

    follow (data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_FOLLOW,
                isAsync: true,
                promise: this.profile.follow(data)
            });
        };
    }

    recommend (data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_RECOMMEND,
                isAsync: true,
                promise: this.profile.recommend(data)
            });
        };
    }
    unFollow (data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_UNFOLLOW,
                isAsync: true,
                promise: this.profile.unFollow(data)
            });
        };
    }

    getProfile(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE,
                isAsync: true,
                profile:[],
                promise: this.profile.getProfile(data)
            });
        };
    }
    profilePhotos(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_PHOTOS,
                isAsync: true,
                promise: this.profile.profilePhotos(data)
            });
        };
    }

    profileFavourites(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_FAVOURITES,
                isAsync: true,
                promise: this.profile.profileFavourites(data)
            });
        };
    }

    profileFollowing(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_FOLLOWING,
                isAsync: true,
                promise: this.profile.profileFollowing(data)
            });
        };
    }

    profileFollowers(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_FOLLOWERS,
                isAsync: true,
                promise: this.profile.profileFollowers(data)
            });
        };
    }

    profileAbout(data) {
        return (dispatch) => {
            return dispatch({
                loading: true,
                type: URL_PROFILE_ABOUT,
                isAsync: true,
                promise: this.profile.profileAbout(data)
            });
        };
    }
}
