import {ChangeDetectionStrategy, Component, OnInit, OnDestroy} from '@angular/core';
import {ensureState} from 'redux-optimistic-ui';
import {Router} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {AsyncLocalStorage} from 'angular-async-local-storage';
import {MatomoInjector} from 'ngx-matomo';
import {DomainService} from './services/domain.service';

import {
    application,
    loading,
    ApplicationActions
} from './store/application';

import {user, UserActions} from './store/user';
import {NotifierService} from 'angular-notifier';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(loading) loading: Observable<any>;

    body = document.querySelector('body');
    private readonly notifier: NotifierService;

    showPopup: string;
    subscriptionApplication: any;
    subscriptionUser: any;
    overlay: string = '';

    constructor(public redux: NgRedux<any>,
                public applicationActions: ApplicationActions,
                public domainService: DomainService,
                protected localStorage: AsyncLocalStorage,
                private matomoInjector: MatomoInjector,
                notifierService: NotifierService,
                public userActions: UserActions,
                public router: Router) {

        this.notifier = notifierService;

        this.matomoInjector.init('https://fotos.today/piwik/', 1);
        this.redux.dispatch(<any>this.applicationActions.set({loading: true}));


        this.localStorage.clear().subscribe(() => {
        });

        this.redux.dispatch(<any>this.applicationActions.set({config: this.domainService.getConfig()}));

    }

    ngOnDestroy() {
        if (this.subscriptionApplication) {
            this.subscriptionApplication.unsubscribe();
        }
        if (this.subscriptionUser) {
            this.subscriptionUser.unsubscribe();
        }
    }

    ngOnInit() {

        this.subscriptionApplication = this.application.subscribe(app => {
            this.overlay = app.overlay;
            this.showPopup = (app.overlay);


            if (app.notification && app.notification.type) {
                this.notifier.notify(app.notification.type, app.notification.text, '' + app.notification.id + '');
                this.redux.dispatch(<any>this.applicationActions.set({notification: ''}));
            }
        });

        this.subscriptionUser = this.user.subscribe(item => {
            if (!item.cookiesAccept && !item.isLoggedIn) {
                this.redux.dispatch(<any>this.applicationActions.set({
                    alert: {
                        show: true,
                        text: 'This site uses cookies and other tracking technologies to assist with navigation and your' +
                            ' ability to provide feedback, analyse your use of our products and services, assist with our ' +
                            'promotional and marketing efforts, and provide content from third parties.',
                        type: 'cookiesAccept'
                    }
                }));
            } else if (((item.isLoggedIn && item.emailConfirmed === '0') || (item.success && item.registered && !item.accountconfirmed)) && !item.accountIsConfirmed) {

                this.redux.dispatch(<any>this.applicationActions.set({overlay: 'confirmaccount'}));
                /*
                this.redux.dispatch(<any>this.applicationActions.set({alert: {
                    show: true,
                    text: 'Unfortunately you did not confirm your registration Email.<br>Please confirm the ' +
                    'registration Email, or get your registration Email resend to this Email:',
                    type: 'registrationConfirmation'
                }}));*/
            } else if (item.accountIsConfirmed) {
                this.redux.dispatch(<any>this.applicationActions.set({overlay: 'registered'}));
            }
            // console.log('item.hasAccount && !item.registered && !item.success', item.hasAccount, item.registered, item.success);
            if (item.hasAccount && !item.registered && !item.success) {
                // console.log('asdasdas');
                this.redux.dispatch(<any>this.userActions.set({hasAccount: false}));
                this.redux.dispatch(<any>this.applicationActions.set({overlay: 'login'}));
                this.redux.dispatch(<any>this.applicationActions.set({
                    alert: {
                        show: true,
                        text: 'There is already an account with this Email Address. Please login to enter you account or click on "forgot password" to reset your password,',
                        type: ''
                    }
                }));
            }
            if (item.emailConfirmed && item.success && item.accountconfirmed) {
                this.redux.dispatch(<any>this.applicationActions.set({overlay: 'login'}));
                this.redux.dispatch(<any>this.applicationActions.set({
                    alert: {
                        show: true,
                        text: 'Awesome! Your account has been confirmed, please login now to be able to use this website.',
                        type: ''
                    }
                }));
            }


            this.redux.dispatch(<any>this.applicationActions.set({loading: false}));

        });


        /*
        this.gallerySubscription = this.photos.subscribe(item => {
            this.loading = true;
            if (!this.error && item.photos) {

                this.list = item.photos;

                const {user} = ensureState(this.redux.getState());
                if (this.list.length && user.isLoggedIn && this.thisPage !== this.thisPageTemp) {
                    this.noMoreFavs = true;
                    this.thisPageTemp = this.thisPage;
                    let photoz = [];

                    this.list.filter(img => {
                        photoz.push(img.image_id);
                    });

                    if (photoz.length) {
                        this.redux.dispatch(<any>this.interActions.getFavesAndVotes({
                            images: photoz,
                            fromCache: false
                        }));
                    }
                }

                this.total = parseInt(item.total, 10);
                this.loading = false;
                this.wakeUp();
            }
        });
        */
    }

    closeOverlay() {
        if (this.overlay !== 'confirmaccount') {
            this.redux.dispatch(<any>this.applicationActions.set({overlay: null}));
        }
    }
}
