import {Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnChanges} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';
import {ActivatedRoute, Router} from '@angular/router';

import {BASE_URL} from '../../../config/constants';

import {UserActions, user} from '../../../store/user';
import {ApplicationActions, application} from '../../../store/application';


@Component({
    selector: 'my-menu',
    templateUrl: './my.menu.component.html',
    styleUrls: ['./my.menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyMenuComponent implements OnInit, OnDestroy, OnChanges {
    @select(user) user: Observable<any>;
    @select(application) application: Observable<any>;
    show = false;
    subscription: any;
    profileImage: string;
    menuOpen: boolean;
    loggedInUser: any;

    constructor(public redux: NgRedux<any>,
                public userActions: UserActions,
                public cd: ChangeDetectorRef,
                public app: ApplicationActions,
                public route: ActivatedRoute,
                public router: Router) {
        this.menuOpen = false;
    }

    ngOnInit() {
        this.subscription = this.user.subscribe(use => {
            if (use.isLoggedIn && use.tokenTime !== '') {
                this.show = (use.profile !== '');
                this.loggedInUser = use;
                this.profileImage = BASE_URL + use.profile_image;
                this.cd.markForCheck();
            } else {
                this.show = false;
                this.cd.markForCheck();
            }
        });
    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    checkLogin() {
        const {user} = ensureState(this.redux.getState());
        const fiveHours = (100 * 60 * 60 * 1000);
        if (fiveHours + (parseInt(user.tokenTime, 10)) < Date.now()) {
            this.redux.dispatch(<any>this.userActions.logoutUser());
        }
    }

    gotoProfile() {
        this.router.navigate(['/en/profile/' + this.loggedInUser.profile]);
    }

    logIn() {
        this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
    }

    logOut() {
        this.redux.dispatch(<any>this.userActions.logoutUser());
    }

    toggleMenu() {
        if (!this.menuOpen) {
            document.body.style.overflowY = 'visible';
            this.menuOpen = true;
        } else {
            document.body.style.overflowY = 'visible';
            this.menuOpen = false;
        }
    }
}
