import {
    Component,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    EventEmitter,
    ElementRef,
    Input,
    OnInit,
    Output,
    OnChanges, SimpleChanges, SimpleChange,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';

@Component({
    selector: 'pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent implements OnChanges {
    @Input() PageTotal: number;
    @Input() PageActive: number = 0;
    @Input() PageURL: string;
    @Input() loading: boolean = true;
    @Input() scrollTo: any;

    // @Output() goToPage = new EventEmitter<any>();

    constructor(public router: Router,
                public route: ActivatedRoute,
                public cd: ChangeDetectorRef) {

    }

    ngOnChanges(changes: SimpleChanges) {
        // this.PageActive = this.PageActive;
        this.cd.markForCheck();
    }

    goToPageChild(index: number) {
        window.scrollTo(0, 0);
        this.PageActive = index;
        this.cd.markForCheck();
        this.router.navigate([this.PageURL, index + 1]);
    }

    counter(i: number) {
        // console.log(this.PageActive);
        this.PageActive = this.PageActive === 0 ? 1 : this.PageActive;

        let pages = [];
        let page: number = this.PageActive;

        let from: number = (this.PageActive > 5 ? (parseInt('' + page, 10) - 5) : page);
        let till: number = (this.PageActive > 5 ? (parseInt('' + page, 10) + 5) : (parseInt('' + page, 10) + 10));

        if (this.PageTotal < 10) {
            from = 1;
            till = this.PageTotal;
            // console.log('till', till);
        }

        if (till > this.PageTotal) {
           till = i;
        }

        for (let n = from; n <= till; n++) {
            pages.push(n);
        }

        return pages;// new Array(i + 1);
    }
}
