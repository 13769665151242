import {Component, OnInit} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


import {
    allLanguages,
    allLanguagesShort
} from '../../../config/constants';

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    menuOpen: boolean;
    languages = allLanguages;

    constructor(public route: ActivatedRoute,
                public router: Router,
                public translate: TranslateService) {
        this.menuOpen = false;
    }

    goToUrl(destiny) {
        window.scrollTo(0, 0);
        this.router.navigate([destiny]);
    }

    toggleMenu() {
        if (!this.menuOpen) {
            document.body.style.overflowY = 'visible';
            this.menuOpen = true;
        } else {
            document.body.style.overflowY = 'visible';
            this.menuOpen = false;
        }
    }

    selectLanguage(language) {
        this.translate.use(language);
        const url = this.router.url;
        this.router.navigate(['/' + language + '/' + url.substr(4)]);
    }
}
