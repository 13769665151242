import {Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {FormGroup, FormBuilder} from '@angular/forms';
import {AsyncLocalStorage} from 'angular-async-local-storage';
import {photos} from '../../store/photos';
import {InterActions} from '../../store/inter';

import {user} from '../../store/user';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    @select(user) user: Observable<any>;

    @select(photos) photos: Observable<any>;
    error: boolean;
    subscription: any;
    subscriptionPhotos: any;
    form: FormGroup;
    loading: false;
    loggedIn = false;

    constructor(public action: UserActions,
                public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                public formBuilder: FormBuilder,
                public interActions: InterActions,
                protected localStorage: AsyncLocalStorage,
                public redux: NgRedux<any>) {

        this.form = formBuilder.group({
            email: [''],
            password: [''],
            agree: ['']
        });
    }

    ngOnInit() {
        this.subscription = this.user.subscribe(use => {
            this.error = use.error;
            if (!this.error && use.profile && use.isLoggedIn) {
                this.loggedIn = true;

                this.redux.dispatch(<any>this.app.set({overlay: false}));
                this.loading = false;
                setTimeout(() => {
                    this.cd.markForCheck();
                }, 30);
            } else {
                this.loading = use.loading;
            }
            setTimeout(() => {
                this.cd.markForCheck();
            }, 30);
        });
        const {photos} = ensureState(this.redux.getState());
            this.subscriptionPhotos = this.photos.subscribe(photo => {
            if (this.loggedIn && photo.photo) {
                let photoz = [];
                photo.photo.filter(img => {
                    photoz.push(img.image_id);
                });
                if (photoz.length) {
                    this.redux.dispatch(<any>this.interActions.getFavesAndVotes({images: photoz, fromCache: false}));
                }

            }});
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionPhotos) {
            this.subscriptionPhotos.unsubscribe();
        }
    }

    register() {
        this.redux.dispatch(<any>this.app.set({overlay: 'register'}));
    }

    forgotPassword() {
        this.redux.dispatch(<any>this.app.set({overlay: 'forgotpassword'}));
    }

    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }

    submit(form) {
        const login = Object.assign({}, form.value, {tokenTime:  Date.now()});

        if (form.status !== 'INVALID') {
            this.redux.dispatch(<any>this.action.login(login));
        } else {
            this.error = true;
        }
    }

    signUp(form) {

    }
}
