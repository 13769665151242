import {Injectable} from '@angular/core';

import {
    GET_MESSAGES,
    GET_NEW_MESSAGES,
    GET_OLD_MESSAGES,
    MARK_MESSAGE_AS_READ,
    MARK_ALL_MESSAGE_AS_READ
} from './constants';

import {MessagesService} from './service';

@Injectable()
export class MessagesActions {
    constructor(private messages: MessagesService) {
    }

    getMessages(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_MESSAGES,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.messages.getMessages(data)
            });
        };
    }

    getNewMessages(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_NEW_MESSAGES,
                isAsync: true,
                error: false,
                loading: true,
                promise: this.messages.getNewMessages(data)
            });
        };
    }

    getOldMessages(data) {
        return (dispatch) => {
            return dispatch({
                type: GET_OLD_MESSAGES,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.messages.getOldMessages(data)
            });
        };
    }

    markMessageAsRead(data) {
        return (dispatch) => {
            return dispatch({
                data,
                type: MARK_MESSAGE_AS_READ,
                isAsync: true,
                loading: true,
                error: false,
                promise: this.messages.markMessageAsRead(data)
            });
        };
    }
    markAllmessageasreadAction() {
            return (dispatch) => {
                return dispatch({
                    type: MARK_ALL_MESSAGE_AS_READ,
                    isAsync: true,
                    loading: true,
                    error: false,
                    promise: this.messages.markAllmessageasreadAction()
                });
            };
        }
}
