import {
    Component,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';

import {user} from '../../../store/user';
import {UserActions} from '../../../store/user';

@Component({
    selector: 'loginAs',
    templateUrl: './login.as.component.html',
    styleUrls: ['./login.as.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginAsComponent {
    @select(user) user: Observable<any>;

    @Input() imageId: any;
    @Input() loginAsVoted = false;


    constructor(public redux: NgRedux<any>,
                public userActions: UserActions) {
    }

    sendToLoginAs() {
        this.redux.dispatch(<any>this.userActions.loginAs({image_id: this.imageId}));
    }

}
