export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';

export const GET_MORE_COMMENTS = 'GET_MORE_COMMENTS';
export const GET_MORE_COMMENTS_SUCCESS = 'GET_MORE_COMMENTS_SUCCESS';
export const GET_MORE_COMMENTS_ERROR = 'GET_MORE_COMMENTS_ERROR';

export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_ERROR = 'LIKE_COMMENT_ERROR';

