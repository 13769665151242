import {Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {UserActions} from '../../store/user';
import {ApplicationActions} from '../../store/application';
import {FormGroup, FormBuilder} from '@angular/forms';
import {AsyncLocalStorage} from 'angular-async-local-storage';
/*
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider
} from 'angular5-social-login'; */

import {user} from '../../store/user';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @select(user) user: Observable<any>;
    error: boolean;
    subscription: any;
    form: FormGroup;
    loading: false;
    emailWasSend: boolean = false;

    constructor(public formBuilder: FormBuilder,
                public userActions: UserActions,
                public redux: NgRedux<any>,
                public app: ApplicationActions,
                public cd: ChangeDetectorRef,
                protected localStorage: AsyncLocalStorage,
                /*private socialAuthService: AuthService*/) {

        this.form = formBuilder.group({
            email: [''],
            agree: ['']
        });
    }

    ngOnInit() {
        this.resetPasswordForget();

        this.subscription = this.user.subscribe(use => {
            this.loading = false;
            this.error = use.error;
            if (!this.error && use.send) {
                this.emailWasSend = true;
                // this.redux.dispatch(<any>this.app.set({overlay: false}));
            }

            setTimeout(() => {
                this.cd.markForCheck();
            }, 30);
        });
    }

    resetPasswordForget() {
        this.emailWasSend = false;
        this.redux.dispatch(<any>this.userActions.set({send: false, error: false}));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close() {
        this.redux.dispatch(<any>this.app.set({overlay: null}));
    }

    submit(form) {
        this.error = false;
        this.resetPasswordForget();
        this.sendForm(form)
    }

    sendForm(form) {
        setTimeout(() => {
            this.cd.markForCheck();
        }, 0);
        if (form.status !== 'INVALID') {
            this.redux.dispatch(<any>this.userActions.forgotPassword(form.value));
        } else {
            this.error = true;
        }
    }
}
