import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {URLPROLIE, URLCOMMENTS, URLPHOTOS, URLPHOTOSGALLERY} from '../config/constants';

import {Observer} from 'rxjs/Observer';
import 'rxjs/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import {Data} from './data';
// import {HttpModule} from '@angular/http';
import 'rxjs/add/operator/shareReplay';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError} from 'rxjs/operators';
import {HttpClientModule} from '@angular/common/http';


import {
    PhotosGalleryInterface,
    CommentsInterface,
    PhotoInterFace,
    RecommendedPhotoInterface
} from '../store/interfaces/photos.interface';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};


@Injectable()
export class PhotoService {
    public photos: Observable<any>;
    public viewType = 'thumbnails';
    public profilePhotos: Observable<any>;
    private comments: any;
    public shownPhoto: any;
    public recommendedPhotos: any;
    private gallery: any;
    private data: any;
    private observable: Observable<any>;
    private galleryObservable: Observable<any>;
    private categoryObservable: Observable<any>;

    constructor(private httpClient: HttpClient) {
    }

    getViewType() {
        return this.viewType;
    }

    setViewType(type: string) {
        this.viewType = type;
    }

    callRecommendatedPhotos(url) {
        this.recommendedPhotos = this.httpClient.get<PhotosGalleryInterface[]>(URLPHOTOS + url)
            .shareReplay();
    }

    getRecommendatedPhotos(): Observable<RecommendedPhotoInterface[]> {
        return this.recommendedPhotos;
    }

    setRecommendatedPhotos(recommendedPhotos) {
        this.recommendedPhotos = recommendedPhotos;
    }

    getRecommendatedPhotosByPage(url: string, index: number) {
        this.recommendedPhotos = this.httpClient.get<PhotosGalleryInterface[]>(URLPHOTOS + url + '/' + index)
            .shareReplay();
        return this.recommendedPhotos;
    }


    setShownPhoto(photo) {
        this.shownPhoto = photo;
    }

    photoShow(): Observable<PhotoInterFace[]> {
        return this.shownPhoto;
    }

    callPhotos(url) {
        this.photos = this.httpClient.get<PhotosGalleryInterface[]>(URLPHOTOS + url)
            .shareReplay();
    }

    setPhotos(photos: PhotosGalleryInterface[]) {
        // this.photos = photos;
    }

    getPhotos(): Observable<PhotosGalleryInterface[]> {
        return this.photos;
    }

    getPhotosByPage(url: string, index: number) {
        this.photos = this.httpClient.get<PhotosGalleryInterface[]>(URLPHOTOS + url + '/' + index)
            .shareReplay();
        return this.photos;
    }

    callComments(imageId) {
        this.comments = this.httpClient.get<CommentsInterface[]>(URLCOMMENTS + '/' + imageId)
            .shareReplay();
    }

    getComments(): Observable<CommentsInterface[]> {
        return this.comments;
    }

    callProfilePhotos(url) {
        this.photos = this.httpClient.get<PhotosGalleryInterface[]>(URLPROLIE + url)
            .shareReplay();
    }

    getProfilePhotos(): Observable<PhotosGalleryInterface[]> {
        return this.photos;
    }

    getProfilePhotosByPage(url: string, index: number) {
        this.photos = this.httpClient.get<PhotosGalleryInterface[]>(URLPROLIE + url + index)
            .shareReplay();
    }
}

/*
export interface MyProfileInterface {
    name: 'Gido Carper';
    blogs?: object;
    categories?: object;
}

@Injectable()
export class MyNewProject {

    constructor(private httpClient: HttpClient,
                httpErrorHandler: HttpErrorHandler) {

        this.getProfileData();
    }

    private profileData: any;
    private profileUrl = 'https://';

    // TODO implement error Handeling
    getProfileData() {
        this.profileData = this.httpClient.get<MyProfileInterface[]>(
            this.profileUrl,
            {
                headers: new HttpHeaders().set(
                    'Authorization', `Bearer ${localStorage.getItem('personal  skills')}`
                )
            }
        )
        .shareReplay();
    }

    getProfile(): Observable<ProfileInterface[]> {
        return this.profileData;
    }
}

*/
