import {
    Component,
    Input,
    OnDestroy,
    ChangeDetectionStrategy
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';

import {user} from '../../../store/user';
import {PhotosActions} from '../../../store/photos';

import {application, ApplicationActions} from '../../../store/application';
import {photos} from '../../../store/photos';
import {interactions, InterActions} from '../../../store/inter';

@Component({
    selector: 'interviewsButtons',
    templateUrl: './interview.buttons.component.html',
    styleUrls: ['./interview.buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewsButtonComponent implements OnDestroy {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() userId: number;

    subscription: any;
    hasRecommended = false;
    hasVoted = false;

    constructor(public redux: NgRedux<any>,
                public interActions: InterActions,
                public app: ApplicationActions) {
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    interviewAction($event, type) {
        this.subscription = this.user.subscribe(use => {
            if (use.profile !== '') {
                this.redux.dispatch(<any>this.app.set({overlay: false}));
                if (type === 'vote') {
                    this.redux.dispatch(<any>this.interActions.voteInterview({user_id: this.userId}));
                    this.hasVoted = true;
                } else if (type === 'recommended') {
                    this.redux.dispatch(<any>this.interActions.recommendInterview({image_id: this.userId}));
                    this.hasRecommended = false;
                }
            } else {
                this.redux.dispatch(<any>this.app.set({overlay: 'login'}));
            }
        });
    }
}
