import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    transparent: boolean;

    constructor(public route: ActivatedRoute,
                public router: Router,
                public cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.checkUrl();
    }

    ngOnChanges() {
        this.checkUrl();
    }

    checkUrl() {
        this.router.events.subscribe((url: any) => {
            if (url.url) {
                if (url.url === '/') {
                    this.transparent = true;
                } else {
                    this.transparent = false;
                }
            }
            this.cd.markForCheck();
        });
    }
}
