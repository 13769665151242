export const PHOTO_VOTE = 'PHOTO_VOTE';
export const PHOTO_VOTE_SUCCESS = 'PHOTO_VOTE_SUCCESS';
export const PHOTO_VOTE_ERROR = 'PHOTO_VOTE_ERROR';

export const PHOTO_VOTE_STAR = 'PHOTO_VOTE_STAR';
export const PHOTO_VOTE_STAR_SUCCESS = 'PHOTO_VOTE_STAR_SUCCESS';
export const PHOTO_VOTE_STAR_ERROR = 'PHOTO_VOTE_STAR_ERROR';


export const PHOTO_VOTE_STAR_DELETE = 'PHOTO_VOTE_STAR_DELETE';
export const PHOTO_VOTE_STAR_DELETE_SUCCESS = 'PHOTO_VOTE_STAR_DELETE_SUCCESS';
export const PHOTO_VOTE_STAR_DELETE_ERROR = 'PHOTO_VOTE_STAR_DELETE_ERROR';



export const PHOTO_ADD_TO_FAV = 'PHOTO_ADD_TO_FAV';
export const PHOTO_ADD_TO_FAV_SUCCESS = 'PHOTO_ADD_TO_FAV_SUCCESS';
export const PHOTO_ADD_TO_FAV_ERROR = 'PHOTO_ADD_TO_FAV_ERROR';

export const PHOTO_REMOVE_FROM_FAV = 'PHOTO_REMOVE_FROM_FAV';
export const PHOTO_REMOVE_FROM_FAV_SUCCESS = 'PHOTO_REMOVE_FROM_FAV_SUCCESS';
export const PHOTO_REMOVE_FROM_FAV_ERROR = 'PHOTO_REMOVE_FROM_FAV_ERROR';

export const PHOTO_GET_FAVS_AND_VOTES = 'PHOTO_GET_FAVS_AND_VOTES';
export const PHOTO_GET_FAVS_AND_VOTES_SUCCESS = 'PHOTO_GET_FAVS_AND_VOTES_SUCCESS';
export const PHOTO_GET_FAVS_AND_VOTES_ERROR = 'PHOTO_GET_FAVS_AND_VOTES_ERROR';

export const PHOTO_NOMINATE = 'PHOTO_NOMINATE';
export const PHOTO_NOMINATE_SUCCESS = 'PHOTO_NOMINATE_SUCCESS';
export const PHOTO_NOMINATE_ERROR = 'PHOTO_NOMINATE_ERROR';

export const PHOTO_SEND_TO_GALLERY = 'PHOTO_SEND_TO_GALLERY';
export const PHOTO_SEND_TO_GALLERY_SUCCESS = 'PHOTO_SEND_TO_GALLERY_SUCCESS';
export const PHOTO_SEND_TO_GALLERY_ERROR = 'PHOTO_SEND_TO_GALLERY_ERROR';

export const PHOTO_SELECT_PHOTOGRAPHER = 'PHOTO_SELECT_PHOTOGRAPHER';
export const PHOTO_SELECT_PHOTOGRAPHER_SUCCESS = 'PHOTO_SELECT_PHOTOGRAPHER_SUCCESS';
export const PHOTO_SELECT_PHOTOGRAPHER_ERROR = 'PHOTO_SELECT_PHOTOGRAPHER_ERROR';

export const PHOTO_UNSELECT_PHOTOGRAPHER = 'PHOTO_UNSELECT_PHOTOGRAPHER';
export const PHOTO_UNSELECT_PHOTOGRAPHER_SUCCESS = 'PHOTO_UNSELECT_PHOTOGRAPHER_SUCCESS';
export const PHOTO_UNSELECT_PHOTOGRAPHER_ERROR = 'PHOTO_UNSELECT_PHOTOGRAPHER_ERROR';

export const PHOTO_SELECT_FIRST_PHOTOGRAPHER = 'PHOTO_SELECT_FIRST__PHOTOGRAPHER';
export const PHOTO_SELECT_FIRST__PHOTOGRAPHER_SUCCESS = 'PHOTO_SELECT_FIRST__PHOTOGRAPHER_SUCCESS';
export const PHOTO_SELECT_FIRST__PHOTOGRAPHER_ERROR = 'PHOTO_SELECT_FIRST__PHOTOGRAPHER_ERROR';

export const PHOTO_RECOMMEND = 'PHOTO_RECOMMEND';
export const PHOTO_RECOMMEND_SUCCESS = 'PHOTO_RECOMMEND_SUCCESS';
export const PHOTO_RECOMMEND_ERROR = 'PHOTO_RECOMMEND_ERROR';

export const PHOTOGRAPHER_RECOMMEND = 'PHOTOGRAPHER_RECOMMEND';
export const PHOTOGRAPHER_RECOMMEND_SUCCESS = 'PHOTOGRAPHER_RECOMMEND_SUCCESS';
export const PHOTOGRAPHER_RECOMMEND_ERROR = 'PHOTOGRAPHER_RECOMMEND_ERROR';

export const INTERVIEW_VOTE = 'INTERVIEW_VOTE';
export const INTERVIEW_VOTE_SUCCESS = 'INTERVIEW_VOTE_SUCCESS';
export const INTERVIEW_VOTE_ERROR = 'INTERVIEW_VOTE_ERROR';

export const INTERVIW_RECOMMEND = 'INTERVIW_RECOMMEND';
export const INTERVIW_RECOMMEND_SUCCESS = 'INTERVIW_RECOMMEND_SUCCESS';
export const INTERVIW_RECOMMEND_ERROR = 'INTERVIW_RECOMMEND_ERROR';

export const INTERVIEW_REQUEST = 'INTERVIEW_REQUEST';
export const INTERVIEW_REQUEST_SUCCESS = 'INTERVIEW_REQUEST_SUCCESS';
export const INTERVIEW_REQUEST_ERROR = 'INTERVIEW_REQUEST_ERROR';

export const INTERVIEW_PUBLISH = 'INTERVIEW_PUBLISH';
export const INTERVIEW_PUBLISH_SUCCESS = 'INTERVIEW_PUBLISH_SUCCESS';
export const INTERVIEW_PUBLISH_ERROR = 'INTERVIEW_PUBLISH_ERROR';

