import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {FormArray, FormGroup, FormControl} from '@angular/forms';


import {
    ActivatedRoute,
    Router
} from '@angular/router';

import {PhotosActions, photos} from '../../store/photos';


@Component({
    selector: 'photosYear',
    templateUrl: './photos.year.component.html',
    styleUrls: ['./photos.year.component.scss']
})
export class PhotosYearComponent implements OnInit {
    @select(photos) photos: Observable<any>;

    active: string;
    viewLocalType: string;
    url = '';
    year = (new Date()).getFullYear();

    formSearch = new FormGroup({
        year: new FormControl(),
        search: new FormControl(),
        music: new FormControl(),
    });

    years = ['all', 2019, 2018, 2017, 2016, 2015, 2014, 2013];


    constructor(public redux: NgRedux<any>,
                public photosActions: PhotosActions,
                public route: ActivatedRoute,
                public router: Router) {
        this.router.events.subscribe(par => {
            this.url = router.url;
        });
        this.route.params.subscribe(params => {
            this.active = (params.id ?  params.id : '');
        });
    }

    ngOnInit() {
        // this.viewLocalType = this.photoService.getViewType();

       this.formSearch.setValue({
            year:  window.localStorage['year'] !== '' ?  window.localStorage['year'] : this.year,
            search:  window.localStorage['search'] !== '' ? window.localStorage['search'] : '',
            music: window.localStorage['music'] !== '' ? window.localStorage['music'] : false
        });
    }

    counter(i: number) {
        if (i > 10) {
            i = 10;
        }
        return new Array(i + 1);
    }

    setViewType(viewType: string) {
        this.redux.dispatch(<any>this.photosActions.setViewType(viewType));
        this.viewLocalType = viewType;
    }

    checkSubmit() {
        if (this.formSearch.value.search.length > 3) {
            this.submitForm();
        }
    }

    submitForm() {
        this.redux.dispatch(<any>this.photosActions.setFilter({
            year: this.formSearch.value.year,
            music: this.formSearch.value.music,
            search: this.formSearch.value.search
        }));

        const queryString = '?year=' + (this.formSearch.value.year ? this.formSearch.value.year : '') +
                    '&music=' + (this.formSearch.value.music ? this.formSearch.value.music : '') +
                    '&search=' +  (this.formSearch.value.search ? this.formSearch.value.search : '');

        window.localStorage.setItem('queryString',queryString);
        window.localStorage.setItem('year',this.formSearch.value.year);
        window.localStorage.setItem('music',this.formSearch.value.music);
        window.localStorage.setItem('search',this.formSearch.value.search);


        this.redux.dispatch(<any>this.photosActions.getPhotos({
            url: '/en/photos/all/',
            fromCache: false,
            query: queryString
        }));
    }
}
