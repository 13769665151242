import {interactions} from '../initials';

import {
    PHOTO_VOTE,
    PHOTO_VOTE_SUCCESS,
    PHOTO_VOTE_ERROR,
    PHOTO_ADD_TO_FAV,
    PHOTO_ADD_TO_FAV_ERROR,
    PHOTO_ADD_TO_FAV_SUCCESS,
    PHOTO_GET_FAVS_AND_VOTES,
    PHOTO_GET_FAVS_AND_VOTES_ERROR,
    PHOTO_GET_FAVS_AND_VOTES_SUCCESS,
    PHOTO_REMOVE_FROM_FAV_SUCCESS,
    PHOTO_RECOMMEND,
    PHOTO_RECOMMEND_ERROR,
    PHOTO_RECOMMEND_SUCCESS,
    PHOTO_SEND_TO_GALLERY,
    PHOTO_SEND_TO_GALLERY_SUCCESS,
    PHOTO_SEND_TO_GALLERY_ERROR,
    PHOTOGRAPHER_RECOMMEND,
    PHOTOGRAPHER_RECOMMEND_ERROR,
    PHOTOGRAPHER_RECOMMEND_SUCCESS,
    INTERVIEW_VOTE,
    INTERVIEW_VOTE_ERROR,
    INTERVIEW_VOTE_SUCCESS,
    INTERVIW_RECOMMEND,
    INTERVIW_RECOMMEND_ERROR,
    INTERVIW_RECOMMEND_SUCCESS,
    INTERVIEW_REQUEST,
    INTERVIEW_REQUEST_ERROR,
    INTERVIEW_REQUEST_SUCCESS,
    INTERVIEW_PUBLISH,
    INTERVIEW_PUBLISH_ERROR,
    INTERVIEW_PUBLISH_SUCCESS, PHOTO_VOTE_STAR, PHOTO_VOTE_STAR_SUCCESS, PHOTO_VOTE_STAR_ERROR, PHOTO_VOTE_STAR_DELETE,
    PHOTO_VOTE_STAR_DELETE_SUCCESS, PHOTO_VOTE_STAR_DELETE_ERROR
} from './constants';


export default (state = interactions, action: any) => {
    switch (action.type) {

        case INTERVIEW_VOTE:
            return Object.assign({}, state, {
                ...action.data
            });

        case INTERVIW_RECOMMEND:
            return Object.assign({}, state, {
                ...action.data
            });

        case INTERVIEW_REQUEST:
            return Object.assign({}, state, {
                ...action.data
            });

        case INTERVIEW_PUBLISH:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTOGRAPHER_RECOMMEND:
            if (action.recommendPhotographer && action.payload.recommendPhotographer) {
                state.recommendPhotographer = state.recommendPhotographer.concat(action.payload.recommendPhotographer);
            }

            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case PHOTOGRAPHER_RECOMMEND_SUCCESS:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTOGRAPHER_RECOMMEND_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTO_SEND_TO_GALLERY:
            if (action.galleryVoted && action.payload.galleryVoted) {
                state.galleryVoted = state.galleryVoted.concat(action.payload.galleryVoted);
            }

            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case PHOTO_SEND_TO_GALLERY_SUCCESS:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTO_SEND_TO_GALLERY_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTO_VOTE:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTO_GET_FAVS_AND_VOTES:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case PHOTO_GET_FAVS_AND_VOTES_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTO_GET_FAVS_AND_VOTES_SUCCESS:
            if (action.payload && action.payload.stars) {
                state.stars = state.stars.concat(action.payload.stars);
            }

            if (action.payload && action.payload.faved) {
                state.faved = state.faved.concat(action.payload.faved);
            }
            if (action.payload && action.payload.voted) {
                state.voted = state.voted.concat(action.payload.voted);
            }
            return Object.assign({}, state, {
                error: false,
                loading: false
            });


        case PHOTO_VOTE_STAR:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTO_VOTE_STAR_SUCCESS:
            if (action.payload) {
                state.stars = state.stars.concat(action.payload);
            }
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case PHOTO_VOTE_STAR_ERROR:
            return Object.assign({}, state, {
                ...action.data
            });
            

        case PHOTO_VOTE_STAR_DELETE:
            return Object.assign({}, state, {
                ...action.data
            });

        case PHOTO_VOTE_STAR_DELETE_SUCCESS:
            if (action.payload) {
                state.stars = state.stars.concat(action.payload);
            }
            return Object.assign({}, state, {
                error: false,
                loading: false
            });

        case PHOTO_VOTE_STAR_DELETE_ERROR:
            return Object.assign({}, state, {
                ...action.data
            });


        case PHOTO_VOTE_SUCCESS:
            if (state && state.voted && action.payload.voted) {
                let votes = state.voted;
                votes.push(parseInt(action.payload.voted, 10));
                state.voted = votes;
            }
            return Object.assign({}, state, {});

        case PHOTO_ADD_TO_FAV_SUCCESS:
            let favourites = state.faved;
            favourites.push(parseInt(action.payload.faved, 10));
            state.faved = favourites;

            return Object.assign({}, state, {});

        case PHOTO_REMOVE_FROM_FAV_SUCCESS:
            let favs = state.faved;

            const image_id = parseInt(action.payload.faved, 10);
            const index = favs.indexOf(image_id, 0);
            if (index > -1) {
                favs.splice(index, 1);
            }
            state.faved = favs;

            return Object.assign({}, state, {});


        case PHOTO_RECOMMEND:
            return Object.assign({}, state, {
                error: false,
                loading: true
            });

        case PHOTO_RECOMMEND_ERROR:
            return Object.assign({}, state, {
                error: true,
                loading: false
            });

        case PHOTO_RECOMMEND_SUCCESS:

            return Object.assign({}, state, {
                error: false,
                loading: false
            });

            
        default:
            return state;
    }
};
