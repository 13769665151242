import {Injectable} from '@angular/core';
import {BaseService} from '../../shared/base/services';
import {
    URL_COMMENT_DELETE,
    URL_COMMENT_ADD,
    URL_COMMENTS_GET,
    URL_GET_COMPETITION_DISCUSSION,
    URL_PROFILE,
    URL_COMMENT_BOOK_ADD,
    URL_COMMENT_LIKE
} from '../../config/constants';

@Injectable()
export class CommentService {
    constructor(private base: BaseService) {
    }

    getComments(data) {
        if (data.imageId) {
            return this.base.post(URL_COMMENTS_GET + '/' + data.imageId, data, data);
        } else if (data.profile) {
            return this.base.post(URL_PROFILE + '/' + data.profile + '/comments', data, data);
        } else {
            return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION, data, data);
        }
    }

    getMoreComments(data) {
        if (data.imageId) {
            return this.base.post(URL_COMMENTS_GET + '/' + data.imageId, data, data);
        } else if (data.profile) {
            return this.base.post(URL_PROFILE + '/' + data.profile + '/comments', data, data);
        } else {
            return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION, data, data);
        }
    }

    getCommentsWithToken(data) {
        if (data.imageId) {
            return this.base.postWithToken(URL_COMMENTS_GET + 'Tokened/' + data.imageId, data, data);
        } else if (data.profile) {
            return this.base.postWithToken(URL_PROFILE + 'Tokened/' + data.profile + '/comments', data, data);
        } else {
            return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION , data, data);
        }
    }

    getMoreCommentsWithToken(data) {
        if (data.imageId) {
            return this.base.postWithToken(URL_COMMENTS_GET + 'Tokened/' + data.imageId, data, data);
        } else if (data.profile) {
            return this.base.postWithToken(URL_PROFILE + 'Tokened/' + data.profile + '/comments', data, data);
        } else {
            return this.base.postWithToken(URL_GET_COMPETITION_DISCUSSION, data, data);
        }
    }

    postComment(data) {

        if (data.image_id) {
            return this.base.postWithToken(URL_COMMENT_ADD, data, data);
        } else if (data.profile) {
            // return this.base.post(URL_PROFILE + '/' + data.profile + '/comments', data, data);
        } else {
            return this.base.postWithToken(URL_COMMENT_BOOK_ADD, data, data);
        }

    }

    deleteComment(data) {
        return this.base.postWithToken(URL_COMMENT_DELETE, data, data);
    }

    likeComment(data) {
        return this.base.postWithToken(URL_COMMENT_LIKE, data, data);
    }
}
