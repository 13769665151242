import {Injectable} from '@angular/core';

import {
    PHOTO_VOTE,
    PHOTO_VOTE_STAR,
    PHOTO_VOTE_STAR_DELETE,
    PHOTO_ADD_TO_FAV,
    PHOTO_REMOVE_FROM_FAV,
    PHOTO_GET_FAVS_AND_VOTES,
    PHOTO_NOMINATE,
    PHOTO_SEND_TO_GALLERY,
    PHOTO_SELECT_PHOTOGRAPHER,
    PHOTO_RECOMMEND,
    PHOTOGRAPHER_RECOMMEND,
    PHOTO_UNSELECT_PHOTOGRAPHER,
    PHOTO_SELECT_FIRST_PHOTOGRAPHER,
    INTERVIEW_VOTE,
    INTERVIW_RECOMMEND,
    INTERVIEW_REQUEST,
    INTERVIEW_PUBLISH
} from './constants';

import {InterActionService} from './service';

@Injectable()
export class InterActions {
    constructor(private interAction: InterActionService) {
    }

    publishInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: INTERVIEW_PUBLISH,
                isAsync: true,
                promise: this.interAction.publishInterview(data)
            });
        };
    }

    requestInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: INTERVIEW_REQUEST,
                isAsync: true,
                promise: this.interAction.requestInterview(data)
            });
        };
    }

    voteInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: INTERVIEW_VOTE,
                isAsync: true,
                promise: this.interAction.voteInterview(data)
            });
        };
    }

    recommendInterview(data) {
        return (dispatch) => {
            return dispatch({
                type: INTERVIW_RECOMMEND,
                isAsync: true,
                promise: this.interAction.recommendInterview(data)
            });
        };
    }

    recommendPhotographer(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTOGRAPHER_RECOMMEND,
                isAsync: true,
                promise: this.interAction.selectedphotographer(data)
            });
        };
    }

    selectedphotographer(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_SELECT_PHOTOGRAPHER,
                isAsync: true,
                promise: this.interAction.selectedphotographer(data)
            });
        };
    }

    setFirstImage(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_SELECT_FIRST_PHOTOGRAPHER,
                isAsync: true,
                promise: this.interAction.setFirstImage(data)
            });
        };
    }

    deselectPhoto(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_UNSELECT_PHOTOGRAPHER,
                isAsync: true,
                promise: this.interAction.deselectPhoto(data)
            });
        };
    }

    starphoto(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_VOTE_STAR_DELETE,
                isAsync: true,
                promise: this.interAction.starvote(data)
            });
        };
    }

    starphotodelete(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_VOTE_STAR,
                isAsync: true,
                promise: this.interAction.starvote(data)
            });
        };
    }

    vote(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_VOTE,
                isAsync: true,
                promise: this.interAction.vote(data)
            });
        };
    }

    nominate(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_NOMINATE,
                isAsync: true,
                promise: this.interAction.nominate(data)
            });
        };
    }

    sendToGallery(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_SEND_TO_GALLERY,
                isAsync: true,
                promise: this.interAction.sendToGallery(data)
            });
        };
    }

    recommend(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_RECOMMEND,
                isAsync: true,
                promise: this.interAction.recommend(data)
            });
        };
    }

    addtofavourites(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_ADD_TO_FAV,
                isAsync: true,
                promise: this.interAction.addtofavourites(data)
            });
        };
    }

    removeFromfavourites(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_REMOVE_FROM_FAV,
                isAsync: true,
                promise: this.interAction.removeFromfavourites(data)
            });
        };
    }

    getFavesAndVotes(data) {
        return (dispatch) => {
            return dispatch({
                type: PHOTO_GET_FAVS_AND_VOTES,
                isAsync: true,
                promise: this.interAction.getFavesAndVotes(data)
            });
        };
    }
}
