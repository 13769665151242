export const PHOTO_VOTE = 'PHOTO_VOTE';
export const PHOTO_VOTE_SUCCESS = 'PHOTO_VOTE_SUCCESS';
export const PHOTO_VOTE_ERROR = 'PHOTO_VOTE_ERROR';

export const PHOTO_ADD_TO_FAV = 'PHOTO_ADD_TO_FAV';
export const PHOTO_ADD_TO_FAV_SUCCESS = 'PHOTO_ADD_TO_FAV_SUCCESS';
export const PHOTO_ADD_TO_FAV_ERROR = 'PHOTO_ADD_TO_FAV_ERROR';

export const PHOTO_REMOVE_FROM_FAV = 'PHOTO_REMOVE_FROM_FAV';
export const PHOTO_REMOVE_FROM_FAV_SUCCESS = 'PHOTO_REMOVE_FROM_FAV_SUCCESS';
export const PHOTO_REMOVE_FROM_FAV_ERROR = 'PHOTO_REMOVE_FROM_FAV_ERROR';

export const PHOTO_GET_FAVS_AND_VOTES = 'PHOTO_GET_FAVS_AND_VOTES';
export const PHOTO_GET_FAVS_AND_VOTES_SUCCESS = 'PHOTO_GET_FAVS_AND_VOTES_SUCCESS';
export const PHOTO_GET_FAVS_AND_VOTES_ERROR = 'PHOTO_GET_FAVS_AND_VOTES_ERROR';


export const PHOTOS_GET = 'PHOTOS_GET';
export const PHOTOS_GET_SUCCESS = 'PHOTOS_GET_SUCCESS';
export const PHOTOS_GET_ERROR = 'PHOTOS_GET_ERROR';


export const PHOTOS_COUNT_VISITS = 'PHOTOS_COUNT_VISITS';
export const PHOTOS_COUNT_VISITS_SUCCESS = 'PHOTOS_COUNT_VISITS_SUCCESS';
export const PHOTOS_COUNT_VISITS_ERROR = 'PHOTOS_COUNT_VISITS_ERROR';

export const PHOTOS_SET = 'PHOTOS_SET';

export const PHOTOS_PROFILE_GET = 'PHOTOS_PROFILE_GET';
export const PHOTOS_PROFILE_GET_SUCCESS = 'PHOTOS_PROFILE_GET_SUCCESS';
export const PHOTOS_PROFILE_GET_ERROR = 'PHOTOS_PROFILE_GET_ERROR';

export const PHOTO_GET_INFOS = 'PHOTO_GET_INFOS';
export const PHOTO_GET_INFOS_SUCCESS = 'PHOTO_GET_INFOS_SUCCESS';
export const PHOTO_GET_INFOS_ERROR = 'PHOTO_GET_INFOS_ERROR';


export const PHOTOS_COMPETITION_GET = 'PHOTOS_COMPETITION_GET';
export const PHOTOS_COMPETITION_GET_SUCCESS = 'PHOTOS_COMPETITION_GET_SUCCESS';
export const PHOTOS_COMPETITION_GET_ERROR = 'PHOTOS_COMPETITION_GET_ERROR';


export const PHOTOS_WINNER_COMPETITION_GET = 'PHOTOS_WINNER_COMPETITION_GET';
export const PHOTOS_WINNER_COMPETITION_GET_SUCCESS = 'PHOTOS_WINNER_COMPETITION_GET_SUCCESS';
export const PHOTOS_WINNER_COMPETITION_GET_ERROR = 'PHOTOS_WINNER_COMPETITION_GET_ERROR';

export const PHOTO_SET_CATEGORY_TYPE = 'PHOTO_SET_CATEGORY_TYPE';
export const PHOTO_SET_CATEGORY_TYPE_SUCCESS = 'PHOTO_SET_CATEGORY_TYPE_SUCCESS';
export const PHOTO_SET_CATEGORY_TYPE_ERROR = 'PHOTO_SET_CATEGORY_TYPE_ERROR';

export const PHOTO_SET_FILTER = 'PHOTO_SET_FILTER';

export const PHOTO_SET_VIEW_TYPE = 'PHOTO_SET_VIEW_TYPE';
export const PHOTO_SET_VIEW_TYPE_SUCCESS = 'PHOTO_SET_VIEW_TYPE_SUCCESS';
export const PHOTO_SET_VIEW_TYPE_ERROR = 'PHOTO_SET_VIEW_TYPE_ERROR';

export const REMOVE_PHOTOS = 'REMOVE_PHOTOS';