import { ensureState } from 'redux-optimistic-ui';

export const application = s => ensureState(s).application;

export const alerts = s => ensureState(s).application.alerts;
export const debug = s => ensureState(s).application.debug;

export const loading = s => ensureState(s).application.get('loading');

export const rawError = s => ensureState(s).application.get('rawError');
export const shownPhoto = s => ensureState(s).application.get('shownPhoto');
