import {Injectable} from '@angular/core';

import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import { photos } from '../store/photos';
import { interactions, InterActions } from '../store/inter';

@Injectable()
export class VotesService {
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    private stopLoading = false;
    private subscriptionPhotos: any;
    constructor(
        public redux: NgRedux<any>,
        private interActions: InterActions
    ) {}

    getVotes(photos){
        const {user} = ensureState(this.redux.getState());
        if (user.isLoggedIn) { //  && !this.noMoreFavs) {
            // this.subscriptionPhotos = this.interactions.subscribe(item => {
                // this.loading = item.loadding;
               // if (!item.loading && !this.stopLoading) {


                    let photosToCheck = [];
                    photos.filter(img => {
                        photosToCheck.push(img.image_id);
                    });
                    // console.log('test');
                    // this.redux.dispatch(<any>this.interActions.getFavesAndVotes({images: photosToCheck}));
                    // this.subscriptionPhotos.unsubscribe();


                //}// else {
                   // this.stopLoading = false;
                //}
           //  });
        }
        return;
    }
}
