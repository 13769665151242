import {Injectable} from '@angular/core';
// import {Http} from '@angular/http';

import {URLLOGIN, URLREGISTER, URLGETUSERDATA} from '../config/constants';
import { Subject } from 'rxjs/Subject';
import {Observer} from 'rxjs/Observer';
import 'rxjs/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import {Data} from './data';
// import {HttpModule} from '@angular/http';
import 'rxjs/add/operator/shareReplay';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {catchError} from 'rxjs/operators';
import {HttpClientModule} from '@angular/common/http';
import 'rxjs/add/observable/of';

import {
    AppInterface
} from './app.interface';


@Injectable()
export class AppService implements AppInterface {

    public id: string;
    public loggedIn: boolean;
    public login: boolean;
    public _register: boolean;
    public photos: object;

    private observable: Observable<any>;

    constructor() {
        this._register = false;
    }

    public get register() {
        return this._register;
    }

    public setRegister(value: boolean) {
        this._register = value;
    }

    showLoginPopup(): Observable<boolean> {
        if (!this.register) {
          return Observable.of(false);
        } else {
            return Observable.of(true);
        }
    }
/*
    set(name, value) {
        this.app[name] = value;
    }

    get(name) {
        return this.app[name];
    }
    */
}
