import { Injectable } from '@angular/core';

import {
    GET_PAGE
} from './constants';

import { PageService } from './service';

@Injectable()
export class PageActions {
  constructor(private page: PageService) {}

    getPage(data) {
    return (dispatch) => {
      return dispatch({
        type: GET_PAGE,
        isAsync: true,
        promise: this.page.getPage(data)
      });
    };
  }

}
