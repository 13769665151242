import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
    ChangeDetectionStrategy,
    OnChanges
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable} from 'rxjs/Observable';
import {ensureState} from 'redux-optimistic-ui';

import {user} from '../../../store/user';
import {PhotosActions} from '../../../store/photos';

import {application, ApplicationActions} from '../../../store/application';
import {photos} from '../../../store/photos';
import {interactions, InterActions} from '../../../store/inter';

@Component({
    selector: 'galleryButton',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryButtonComponent implements OnDestroy {
    @select(application) application: Observable<any>;
    @select(user) user: Observable<any>;
    @select(photos) photos: Observable<any>;
    @select(interactions) interactions: Observable<any>;

    @Input() imageId: number;
    @Input() galleryVoted = false;


    constructor(public redux: NgRedux<any>,
                public interActions: InterActions,
                public app: ApplicationActions) {
    }


    ngOnDestroy() {
        /* if (this.subscription) {
            this.subscription.unsubscribe();
        } */
    }

    sendToGallery() {
        this.redux.dispatch(<any>this.interActions.sendToGallery({image_id: this.imageId}));
    }

}
